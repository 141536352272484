<script setup lang="ts">
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  label: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}>();
const model = defineModel();
</script>

<template>
  <div>
    <label :class="{ 'text-text-disabled': disabled }">
      <TextHeader3 class="block">
        {{ label }}{{ !required ? ` ${t("input.optionalLabel")}` : "" }}
      </TextHeader3>
      <input
      :class="`w-full rounded-none border-2 ${ error ? 'border-error' : 'border-input-border' } text-base p-5 focus:outline-hidden focus:border-2 focus:border-input-focus-outline ${disabled ? 'border-input-disabled placeholder:text-input-disabled cursor-not-allowed' : ''}`"
      v-model="model"
      type="email"
      spellcheck="false"
      autocomplete="email"
      :aria-required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      :aria-invalid="Boolean(error)"
      :aria-errormessage="error"
      />
    </label>
    <TextBodyError v-if="error" class="block mt-1">
      {{ error }}
    </TextBodyError>
  </div>
</template>
