<script setup lang="ts">
import TextHeader2 from "@/src/components/TextHeader2.vue";

const { disabled } = defineProps<{
  disabled?: boolean;
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button
  :class="`block w-full p-5 ${disabled ? 'bg-button-disabled cursor-not-allowed' : 'bg-button-secondary cursor-pointer hover:bg-button-secondary-hover'}`"
  @click.stop="handleClick"
  role="button"
  :disabled="disabled"
  >
    <TextHeader2 :class="`${disabled ? 'text-button-text-disabled' : ''}`">
      <slot />
    </TextHeader2>
  </button>
</template>
