<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Icon from "@/src/components/Icon.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputPassword from "@/src/components/InputPassword.vue";
import InputText from "@/src/components/InputText.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { validateEmail, validatePassword } from "@/src/lib/formValidationHelpers";
import {
  type Email,
  type Password,
  TownhouseApiResetPasswordAccountLockedError,
  TownhouseApiResetPasswordInvalidVerificationCodeError,
  type ZenotiVerificationCode,
} from "@/src/lib/townhouseApiClient";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

type ResetPasswordState = "email" | "verify" | "success";

const props = defineProps<{
  state: ResetPasswordState;
}>();
const emits = defineEmits<{
  resetPasswordComplete: [];
}>();

const userStore = useUserStore();
const { isResettingPassword, isCompletingResettingPassword } = storeToRefs(userStore);
const { t } = useI18n();
const state = ref<ResetPasswordState>(props.state);
const email = ref<string>();
const verificationCode = ref<string | null>(null);
const newPassword = ref<string | null>(null);

const emailError = ref<string>();
const verificationCodeError = ref<string>();
const newPasswordError = ref<string>();
const formError = ref<string>();

const disableUpdatePassword = ref<boolean>(false);

const validateVerificationCode = () => {
  if (!verificationCode.value) {
    verificationCodeError.value = t("resetPassword.verify.invalid");
    return;
  }

  verificationCodeError.value = undefined;
};

const handleSendVerificationCode = async () => {
  if (isResettingPassword.value) {
    return;
  }

  formError.value = undefined;

  validateEmail({
    email: email.value ?? "",
    errorRef: emailError,
    errorMessage: t("resetPassword.email.invalid"),
  });

  if (!emailError.value && email.value) {
    await userStore.resetPassword(email.value as Email);
    state.value = "verify";
  }
};

const handleUpdatePassword = async () => {
  // Only allow any of the reset password forms to run this handler if an API call is being made
  if (isResettingPassword.value || isCompletingResettingPassword.value) {
    return;
  }

  disableUpdatePassword.value = true;
  formError.value = undefined;

  validateVerificationCode();

  validatePassword({
    password: newPassword.value,
    errorRef: newPasswordError,
    errorMessage: t("resetPassword.password.invalid"),
  });

  if (!(newPasswordError.value || verificationCodeError.value) && newPassword.value && verificationCode.value) {
    try {
      await userStore.completeResetPassword(
        verificationCode.value as ZenotiVerificationCode,
        newPassword.value as Password,
      );
      state.value = "success";
    } catch (e) {
      if (e instanceof TownhouseApiResetPasswordInvalidVerificationCodeError) {
        formError.value = t("resetPassword.error.invalidVerificationCode");
      } else if (e instanceof TownhouseApiResetPasswordAccountLockedError) {
        formError.value = t("resetPassword.error.accountLocked");
      } else {
        formError.value = t("resetPassword.error.all");
      }
    }
  }

  disableUpdatePassword.value = false;
};
</script>

<template>
  <form novalidate @submit.prevent="handleUpdatePassword" class="p-10 flex flex-col gap-5 w-full">
    <TextHeader1 class="mb-10 text-center">{{ t('resetPassword.header') }}</TextHeader1>
    <div class="flex flex-col items-center gap-10" v-if="state === 'email'">
      <TextBody1 :class="{ 'text-text-disabled': isResettingPassword }">{{ t("resetPassword.email.description") }}</TextBody1>
      <InputEmail class="w-full" :disabled="isResettingPassword" :error="emailError" v-model="email" :label="t('resetPassword.email.label')" required/>
      <ButtonPrimary button-type="submit" class="w-96" :submitting="isResettingPassword" @click="handleSendVerificationCode">
        {{ t('resetPassword.button.verificationCode') }}
      </ButtonPrimary>
    </div>
    <div class="flex flex-col items-center gap-10" v-else-if="state === 'verify'">
      <TextBody1 :class="{ 'text-text-disabled': isCompletingResettingPassword }">
        <i18n-t keypath="resetPassword.verify.description" tag="span">
          <template v-slot:email>
            <TextBody1 class="underline">{{ email }}</TextBody1>
          </template>
        </i18n-t>
      </TextBody1>
      <TextBodyError class="w-full" v-if="formError" role="alert">
        <TextBody1>{{ formError }}</TextBody1>
      </TextBodyError>
      <InputText class="w-full" :disabled="isCompletingResettingPassword" :error="verificationCodeError" v-model="verificationCode" :label="t('resetPassword.verify.label')" required/>
      <InputPassword class="w-full" :disabled="isCompletingResettingPassword" :error="newPasswordError" v-model="newPassword" :label="t('resetPassword.password.label')" autocomplete="new-password" required/>
      <ButtonPrimary class="w-96" button-type="submit" :submitting="isCompletingResettingPassword">
        {{ t("resetPassword.button.updatePassword") }}
      </ButtonPrimary>
    </div>
    <div class="flex flex-col items-center gap-10" v-else-if="state === 'success'">
      <Icon class="text-complete-tick" :icon="faCircleCheck" size="6x"/>
      <TextBody1 class="text-center">
        {{ t("resetPassword.success.description") }}
      </TextBody1>
      <ButtonPrimary class="w-96" button-type="submit" @click="emits('resetPasswordComplete')">{{ t("resetPassword.button.login") }}</ButtonPrimary>
    </div>
  </form>
</template>
