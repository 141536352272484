<script setup lang="ts">
import ButtonPlaintextSecondary from "@/src/components/ButtonPlaintextSecondary.vue";
import Icon from "@/src/components/Icon.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { disabled } = defineProps<{
  disabled?: boolean;
}>();

const emits = defineEmits<{
  back: [];
}>();

const handleBackClick = () => {
  if (!disabled) {
    emits("back");
  }
};

const { t } = useI18n();
</script>

<template>
  <div class="w-full bg-button-disabled p-5">
    <!-- FIXME: Remove this wrapper once button allows width-auto -->
    <div class="inline-block">
      <ButtonPlaintextSecondary :disabled="disabled" @click="handleBackClick" class="flex justify-start items-center cursor-default">
        <TextHeader2  class="flex items-center gap-x-2">
          <Icon :icon="faAngleLeft" size="sm" />
          <span>{{ t("button.back") }}</span>
        </TextHeader2>
      </ButtonPlaintextSecondary>
    </div>
  </div>
</template>
