<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { PaymentAccount } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { useUserStore } from "@/src/stores/userStore";
import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { type IconDefinition, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();
const userStore = useUserStore();

const { paymentAccountChosen, paymentAccounts } = storeToRefs(userStore);

const chosenPaymentAccount = ref<PaymentAccount | null>(paymentAccountChosen.value);

const emit = defineEmits<{
  paymentAccountChosenNextClick: [];
  cancelled: [];
  paymentAccountAddClicked: [];
}>();

const handlePaymentAccountSelected = (paymentAccount: PaymentAccount) => {
  chosenPaymentAccount.value = paymentAccount;
};

const handlePaymentAccountChosenNextClick = () => {
  if (chosenPaymentAccount.value) {
    userStore.setChosenPaymentAccount(chosenPaymentAccount.value);
  }
  emit("paymentAccountChosenNextClick");

  authStore.analytics.track("Payment Card Changed", {});
};

const paymentCardIconByPaymentAccountCardType = (cardType: PaymentAccount["cardType"]): IconDefinition => {
  switch (cardType) {
    case "amex":
      return faCcAmex;
    case "visa":
      return faCcVisa;
    case "mastercard":
      return faCcMastercard;
    case "other":
      return faCreditCard;
  }
};
</script>
<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
      <ClosePopup @click="$emit('cancelled')"/>
      <div class="w-full flex flex-col justify-center items-center">
        <TextHeader1 class="p-10 text-center">{{ t("popup.selectPaymentAccount.header") }}</TextHeader1>
        <SectionDivider />
      </div>
      <div class="flex flex-col gap-10 p-10 items-center">
        <div class="flex flex-col gap-5 justify-between min-w-[300px] max-w-[600px]">
          <ButtonInScreenTertiary class="mx-auto max-w-[600px] mb-5" @click="$emit('paymentAccountAddClicked');">
            <div class="flex justify-between items-center">
              <div>
                <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
                <TextBody1>{{ t("popup.selectPaymentAccount.addCard") }}</TextBody1>
              </div>
              <Icon class="mr-5" :icon="faAngleRight" size="lg"/>
            </div>
          </ButtonInScreenTertiary>
          <ButtonInScreenPrimary v-for="paymentAccount in paymentAccounts" :selected="paymentAccount.id === chosenPaymentAccount?.id" @click="() => handlePaymentAccountSelected(paymentAccount)" class="flex justify-start items-center w-full max-w-[600px]">
            <Icon class="mr-5" :icon="paymentCardIconByPaymentAccountCardType(paymentAccount.cardType)" size="lg"/>
            <TextBody1>{{ t("popup.selectPaymentAccount.card") }} {{ paymentAccount.lastFour }}</TextBody1>
          </ButtonInScreenPrimary>
        </div>
        <ButtonPrimary @click="handlePaymentAccountChosenNextClick" class="basis-0 grow-0 max-w-[125px] mt-10 whitespace-nowrap">
          {{ t("popup.selectPaymentAccount.next") }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>
