<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.jpg";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionError from "@/src/components/SectionError.vue";
import ServiceCard from "@/src/components/ServiceCard.vue";
import ServiceCardSkeleton from "@/src/components/ServiceCardSkeleton.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader1Skeleton from "@/src/components/TextHeader1Skeleton.vue";
import type { LocationId, Removal, RemovalId, ServiceId } from "@/src/lib/townhouseApiClient";
import PopupServiceInfo from "@/src/popups/PopupServiceInfo.vue";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
  press: boolean | undefined;
}>();

const { servicesByLocation, servicesByGuest, hasMultipleGuests, fetchServicesFailure } = storeToRefs(bookingStore);
const removalForPopupServiceInfo = ref<Pick<
  Removal,
  "name" | "longDescription" | "priceCents" | "typicalDurationMins"
> | null>(null);
const proceedToNextScreen = ref<boolean>(false);
const serviceType = computed(() => servicesByLocation.value?.get(props.serviceId)?.serviceType);

const removals = ref<Removal[]>([]);
watch(
  servicesByLocation,
  () => {
    // Need to wait for services to be fetched before we can get the removals
    if (!servicesByLocation.value) {
      return;
    }

    removals.value = bookingStore.getRemovalsFromServiceId(props.serviceId);
  },
  { immediate: true },
);

const handleRemovalToggled = (removalId: RemovalId, newValue: boolean | undefined) => {
  if (newValue === undefined) {
    return;
  }

  if (!newValue) {
    bookingStore.removeRemovalFromGuest(props.guestNumber, props.serviceId, removalId);
    return;
  }

  bookingStore.clearRemovalsFromGuest(props.guestNumber, props.serviceId);
  bookingStore.addRemovalToGuest(props.guestNumber, props.serviceId, removalId);
  proceedToNextScreen.value = true;
};

// We have to do this in a watch, not directly in the handler above, as the next won't be clickable until the page has
// rerendered the button in the non-disabled state
watch(proceedToNextScreen, (value) => {
  if (value) {
    proceedToNextScreen.value = false;
    // FIXME: Swap it for a proper broken out navigation state machine in TH-1747
    document.getElementById("navigation-next")?.click();
  }
});

const guestHasRemoval = (removalId: RemovalId): boolean => {
  return Boolean(servicesByGuest.value[props.guestNumber - 1]?.get(props.serviceId)?.removalIds.has(removalId));
};

const handleLearnMoreClicked = (removal: Removal) => {
  removalForPopupServiceInfo.value = {
    name: removal.name,
    longDescription: removal.longDescription,
    priceCents: removal.priceCents,
    typicalDurationMins: removal.typicalDurationMins,
  };
};

const handleTryAgain = () => {
  window.location.reload();
};
</script>

<template>
  <LocationBanner />
  <h2 v-if="serviceType" class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}: </TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('removals.headerManicure') : t('removals.headerPedicure') }}</TextHeader1>
  </h2>
  <h2 v-else-if="!fetchServicesFailure" class="flex justify-center my-5">
    <div class="w-3/5 justify-center">
      <TextHeader1Skeleton />
    </div>
  </h2>
  <div v-if="servicesByLocation !== null" class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
    <PopupServiceInfo v-if="removalForPopupServiceInfo" @cancelled="removalForPopupServiceInfo = null" :serviceName="removalForPopupServiceInfo.name" :description="removalForPopupServiceInfo.longDescription" :priceCents="removalForPopupServiceInfo.priceCents" :durationMins="removalForPopupServiceInfo.typicalDurationMins" />
    <div v-for="removal in removals" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
      <ServiceCard @learnMore="() => handleLearnMoreClicked(removal)" :modelValue="guestHasRemoval(removal.id)"
        @update:modelValue="(newValue: boolean | undefined) => handleRemovalToggled(removal.id, newValue)"
        :image="ServiceCardImage"
        :header="removal.name"
        :bodyText="removal.shortDescription"
        :duration="removal.typicalDurationMins"
        :priceCents="removal.priceCents" />
    </div>
  </div>
  <div v-else-if="fetchServicesFailure" class="w-full flex justify-center">
    <div class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />
    </div>
  </div>
  <div v-else>
    <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
      <div v-for="_ in _.times(10)" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCardSkeleton />
      </div>
    </div>
  </div>
</template>
