<script setup lang="ts">
import ButtonBinary from "@/src/components/ButtonBinary.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader1Skeleton from "@/src/components/TextHeader1Skeleton.vue";
import type { LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  serviceId: ServiceId;
  press: boolean | undefined;
}>();

const { servicesByLocation, hasMultipleGuests, fetchServicesFailure, servicesByGuest } = storeToRefs(bookingStore);
const serviceType = computed(() => servicesByLocation.value?.get(props.serviceId)?.serviceType);

const modelValue = ref<boolean | null>(null);
watch(
  servicesByGuest,
  () => {
    modelValue.value = bookingStore.getNeedsRemovalForGuest(props.guestNumber, props.serviceId);
  },
  { immediate: true, deep: true },
);

const handleNeedsRemovalToggled = (newValue: boolean | null | undefined) => {
  if (newValue === null || newValue === undefined) {
    return;
  }

  bookingStore.setNeedsRemovalForGuest(props.guestNumber, props.serviceId, newValue);
};

// We have to do this in a watch, not directly in the handler above, as the next won't be clickable until the page has
// rerendered the button in the non-disabled state
watch(modelValue, () => {
  // FIXME: Swap it for a proper broken out navigation state machine in TH-1747
  document.getElementById("navigation-next")?.click();
});

const handleTryAgain = () => {
  window.location.reload();
};
</script>

<template>
  <LocationBanner />
  <h2 v-if="serviceType" class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}: </TextHeader1>
    <TextHeader1 class="text-center">{{ ['manicure', 'maniPediManicure'].includes(serviceType) ? t('needsRemoval.headerManicure') : t('needsRemoval.headerPedicure') }}</TextHeader1>
  </h2>
  <h2 v-else-if="!fetchServicesFailure" class="flex justify-center my-5">
    <div class="w-3/5 justify-center">
      <TextHeader1Skeleton />
    </div>
  </h2>

  <div v-if="!fetchServicesFailure" class="px-5 max-w-[600px] mx-auto">
    <ButtonBinary
      :disabled="servicesByLocation === null"
      labelOne="Yes"
      :valueOne="true"
      labelTwo="No"
      :valueTwo="false"
      :modelValue="modelValue"
      @update:modelValue="handleNeedsRemovalToggled">
    </ButtonBinary>
  </div>
  <div v-else class="w-full flex justify-center">
    <div class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />
    </div>
  </div>
</template>
