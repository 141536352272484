<script setup lang="ts">
import AuthResetPassword from "@/src/components/AuthResetPassword.vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const handleResetComplete = () => {
  router.push({
    path: "/auth/login",
    query: route.query,
  });
};
</script>

<template>
  <div class="flex flex-col items-center gap-10">
    <AuthResetPassword @reset-password-complete="handleResetComplete" state="email"/>
  </div>
</template>
