<script setup lang="ts">
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Icon from "./Icon.vue";

defineProps<{
  hasError: boolean;
  disabled?: boolean;
  required?: boolean;
}>();

const isChecked = defineModel<boolean | null>();

const handleClick = () => {
  isChecked.value = !isChecked.value;
};
</script>

<template>
  <label class="flex gap-5" :class="{ 'text-text-disabled': disabled }">
    <div class="flex flex-col w-[20px] h-[20px] shrink-0 grow-0 justify-center align-middle items-center">
      <input
      @click="handleClick"
      type="checkbox"
      :aria-required="required"
      :class="`appearance-none w-full h-full border-2 ${ hasError ? 'border-error' : 'border-input-border' } ${isChecked && !disabled ? 'bg-input-focus-outline border-none' : ''} ${isChecked && disabled ? 'bg-input-disabled border-none' : ''} ${disabled ? 'border-input-disabled cursor-not-allowed' : 'cursor-pointer'}`"
      :disabled="disabled"
      :aria-checked="Boolean(isChecked)"
      :aria-invalid="hasError"
      role="checkbox"
      />
      <Icon v-if="isChecked" :icon="faCheck" size="xl" class=" w-[16px] h-[16px] -mt-[18px] pointer-events-none text-white"/>
    </div>
    <slot />
  </label>
</template>
