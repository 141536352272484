<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextBody2 from "@/src/components/TextBody2.vue";
import TextBody5 from "@/src/components/TextBody5.vue";
import TextLink from "@/src/components/TextLink.vue";
import { type BookingProgressStage, progressBookingStages } from "@/src/config/stages";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { currentStage, disabled } = defineProps<{
  currentStage: BookingProgressStage;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  stageEditClicked: [stage: BookingProgressStage];
}>();

const currentStageIndex = computed(() => {
  return progressBookingStages.indexOf(currentStage);
});

const indexForStage = (stage: BookingProgressStage) => {
  return progressBookingStages.indexOf(stage);
};

const emitStageClickedEvent = (stage: BookingProgressStage) => {
  if (!disabled && indexForStage(stage) < currentStageIndex.value) {
    emit("stageEditClicked", stage);
  }
};
</script>

<template>
  <div class="w-full relative">
    <!-- The line behind the bar -->
    <div class="absolute top-[12.5px] flex w-[calc(100%-30px)] mx-[15px] bg-booking-progress-incomplete">
      <!-- The segment of the bar that represents stuff 'done' -->
      <div class="h-[4px]" :class="{
        'w-0': currentStage === 'location',
        'w-1/4': currentStage === 'guests',
        'w-1/2': currentStage === 'services',
        'w-3/4': currentStage === 'dateTime',
        'w-full': ['confirm', 'complete'].includes(currentStage),
        'bg-booking-progress-background-disabled': disabled,
        'bg-booking-progress-completed': !disabled,
      }"></div>
    </div>

    <!-- The dots and labels -->
    <div class="w-full flex justify-between">
      <div v-for="(bookingStage, index) in progressBookingStages.slice(0, -1)" class="flex flex-col items-center z-10"  @click="emitStageClickedEvent(bookingStage)" :class="{
        'cursor-not-allowed': disabled,
        'cursor-pointer': !disabled && indexForStage(bookingStage) < currentStageIndex,
      }">
        <!-- The dot -->
        <div class="flex items-center justify-center w-[25px] h-[25px] box-content rounded-full border-[2px]" :class="{
          'bg-booking-progress-current border-booking-progress-completed': indexForStage(bookingStage) === currentStageIndex,
          'border-booking-progress-completed-disabled': disabled && indexForStage(bookingStage) === currentStageIndex,
          'bg-booking-progress-incomplete border-booking-progress-incomplete': indexForStage(bookingStage) > currentStageIndex,
          'bg-booking-progress-incomplete-disabled border-booking-progress-incomplete-disabled': disabled && indexForStage(bookingStage) > currentStageIndex,
          'bg-booking-progress-completed border-booking-progress-completed': indexForStage(bookingStage) < currentStageIndex,
          'bg-booking-progress-completed-disabled border-booking-progress-completed-disabled': disabled && indexForStage(bookingStage) < currentStageIndex,
        }">
          <Icon class="text-white" :class="{ 'cursor-not-allowed': disabled }" :icon="faCheck" size="2x" v-if="indexForStage(bookingStage) < currentStageIndex" />
          <TextBody2 :class="{ 'text-text-disabled cursor-not-allowed': disabled }" v-else>{{ index + 1 }}</TextBody2>
        </div>

        <!-- The label -->
        <div class="flex flex-col items-center">
          <TextBody5 :class="{ 'text-text-disabled cursor-not-allowed': disabled }">{{ t(`progress.stages.${bookingStage}`) }}</TextBody5>
          <TextLink v-if="currentStage !== 'complete' && indexForStage(bookingStage) < currentStageIndex" href="#" :disabled="disabled">
            <TextBody5>{{ t('progress.edit') }}</TextBody5>
          </TextLink>
        </div>
      </div>
    </div>
  </div>
</template>
