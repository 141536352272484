<script setup lang="ts">
import Checkbox from "@/src/components/Checkbox.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextLink from "@/src/components/TextLink.vue";
import { useI18n } from "vue-i18n";

defineProps<{
  termsAndConditionsLinkClickHandler?: () => void;
  disabled?: boolean;
}>();

const model = defineModel<boolean>();

const { t } = useI18n();
</script>

<template>
  <Checkbox v-model="model" :has-error="false" :disabled="disabled">
    <TextBody3 :class="{ 'text-text-disabled': disabled }">
      <i18n-t keypath="confirm.terms.mainText">
        <TextLink :disabled="disabled">
          <a :href="disabled ? undefined : t('general.termsAndConditions')" target="_blank" @click="termsAndConditionsLinkClickHandler">{{ t("confirm.terms.linkText") }}</a>
        </TextLink>
      </i18n-t>
    </TextBody3>
  </Checkbox>
</template>
