<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import BookingSummarySkeleton from "@/src/components/BookingSummarySkeleton.vue";
import CheckboxTermsAndConditions from "@/src/components/CheckboxTermsAndConditions.vue";
import DatePicker from "@/src/components/DatePicker.vue";
import GridAvailableDateTimes from "@/src/components/GridAvailableDateTimes.vue";
import Icon from "@/src/components/Icon.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { timeZoneFromOrigin } from "@/src/config/env";
import { bookingToBookingSummary } from "@/src/lib/summaryHelpers";
import type { BookingId, LocalIsoDate } from "@/src/lib/townhouseApiClient";
import PopupAlert from "@/src/popups/PopupAlert.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingRescheduleStore } from "@/src/stores/bookingRescheduleStore";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

// Flip this flag if in an emergency you need to disable rescheduling and push customers to customer care instead
const { bookingId, reschedulingEnabled = true } = defineProps<{
  bookingId: BookingId;
  reschedulingEnabled: boolean;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const bookingRescheduleStore = useBookingRescheduleStore();
const userStore = useUserStore();
const authStore = useAuthStore();

const {
  rescheduledDateLocal,
  rescheduledDateTimeUtc,
  availableDateTimesUtc,
  fetchAvailableTimesFailure,
  confirmBookingRescheduleFailure,
  termsAndConditionsAccepted,
  isRescheduling,
} = storeToRefs(bookingRescheduleStore);
const { userBooking, fetchUserBookingFailure } = storeToRefs(userStore);

const chosenBookingDateTimeUtc = ref<DateTime | null>(null);

const rescheduleConfirmationErrorSection = useTemplateRef("resecheduleConfirmationErrorSection");

const minDate = DateTime.local({ zone: timeZoneFromOrigin() }).toISODate() as LocalIsoDate;
const maxDate = DateTime.local({ zone: timeZoneFromOrigin() }).plus({ months: 3 }).toISODate() as LocalIsoDate;

const bookingSummary = computed(() => (userBooking.value ? bookingToBookingSummary(userBooking.value) : null));

const handleBack = () => {
  router.push({
    path: "/account/bookings",
    query: route.query,
  });
};

const handleAvailableTimesTryAgain = () => {
  window.location.reload();
};

watch(rescheduleConfirmationErrorSection, (newRescheduleConfirmationErrorSection) => {
  newRescheduleConfirmationErrorSection?.scrollIntoView({ behavior: "smooth" });
});

onMounted(() => {
  authStore.analytics.track("Booking Reschedule Started", {
    bookingId,
  });

  if (!bookingRescheduleStore.bookingId) {
    bookingRescheduleStore.setBookingId(bookingId);
  }

  if (!bookingRescheduleStore.rescheduledDateLocal) {
    bookingRescheduleStore.setRescheduledDateLocal(minDate);
  }
});

watch(chosenBookingDateTimeUtc, (newChosenBookingDateTimeUtc: DateTime | null) => {
  if (!newChosenBookingDateTimeUtc) {
    return;
  }

  bookingRescheduleStore.setRescheduledDateTimeUtc(newChosenBookingDateTimeUtc);
});

watch(rescheduledDateLocal, async (newRescheduledDateUtc) => {
  if (!newRescheduledDateUtc) {
    return;
  }

  rescheduledDateTimeUtc.value = null;
  availableDateTimesUtc.value = null;
  await bookingRescheduleStore.fetchAvailableTimes();
});
</script>

<template>
  <div v-if="reschedulingEnabled" class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10" :class="{ 'text-text-disabled': isRescheduling }">{{ t("account.bookings.reschedule.label") }}</TextHeader1>
    <NavigationTop :disabled="isRescheduling" @back="handleBack" />
    <div class="w-full flex flex-col gap-5 px-5">
      <div v-if="fetchAvailableTimesFailure || fetchUserBookingFailure" class="flex justify-center" >
        <SectionError :error-message="t('account.bookings.reschedule.availableTimesError')" :try-again-handler="handleAvailableTimesTryAgain" class="max-w-[600px]"/>
      </div>
      <div v-else>
        <div v-if="confirmBookingRescheduleFailure" class="flex justify-center" ref="resecheduleConfirmationErrorSection">
          <SectionError :error-message="t('account.bookings.reschedule.confirmBookingRescheduleError')" class="max-w-[600px]"/>
        </div>
        <div class="w-full flex flex-col mt-10">
          <div class="w-full">
            <div v-if="bookingSummary" class="w-full mb-5 border-2 p-10 border-bookings-border">
              <BookingSummary
                hide-services
                :disabled="isRescheduling"
                :edit-mode="false"
                :location-name="bookingSummary.locationName"
                :address-one="bookingSummary.addressOne"
                :address-two="bookingSummary.addressTwo || undefined"
                :city="bookingSummary.city"
                :state="bookingSummary.state || undefined"
                :postal-code="bookingSummary.postalCode"
                :services="bookingSummary.serviceSummariesByGuest"
                :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
              />
            </div>
            <BookingSummarySkeleton v-else class="w-full mb-5 border-2 p-10 border-bookings-border"/>
          </div>
          <div class="flex flex-col p-[1px] max-h-[410px] md:border-available-times-border md:border">
            <div class="sticky top-0 bg-white md:border-available-times-border md:border-b">
              <DatePicker
                class="w-full"
                v-model="rescheduledDateLocal"
                :startDate="minDate"
                :endDate="maxDate"
                :disabled="isRescheduling"
              />
            </div>
            <div class="w-full flex-1 overflow-y-auto">
              <div class="flex flex-col md:p-5">
                <GridAvailableDateTimes
                  v-if="bookingSummary && availableDateTimesUtc && availableDateTimesUtc.length > 0"
                  :available-date-times-utc="availableDateTimesUtc"
                  v-model="chosenBookingDateTimeUtc"
                  :disabled="isRescheduling"
                  :date-time-format="t('dateTime.timeFormat')"
                />
                <div v-else-if="availableDateTimesUtc && availableDateTimesUtc.length === 0" class="w-full h-full flex items-center justify-center">
                  <TextBody1>{{ t('dateTime.noTimesLeft') }}</TextBody1>
                </div>
                <div v-else-if="fetchAvailableTimesFailure" class="w-full h-full flex justify-center">
                  <SectionError hide-border :error-message="t('dateTime.availableTimesError')" :try-again-handler="handleAvailableTimesTryAgain"/>
                </div>
                <div v-else :class="`w-full h-full flex flex-col gap-4 items-center justify-center ${isRescheduling ? 'text-text-disabled' : ''}`">
                  <Icon :icon="faCircleNotch" size="2x" class="animate-spin"/>
                  <TextBody1>{{t('dateTime.fetchingAvailableTimes')}}</TextBody1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CheckboxTermsAndConditions :disabled="isRescheduling" class="mt-10" v-model="termsAndConditionsAccepted" :terms-and-conditions-link-click-handler="() => authStore.analytics.track('Terms and Conditions Link Clicked', {})" />
      </div>
    </div>
  </div>
  <div v-else>
    <PopupAlert
      @cancelled="handleBack"
      :title="t('account.bookings.reschedule.disabled.title')"
      :message="t('account.bookings.reschedule.disabled.message')"
      :confirm="t('account.bookings.reschedule.disabled.confirm')" />
  </div>
</template>
