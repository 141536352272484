<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const { disabled } = defineProps<{
  disabled?: boolean;
  submitting?: boolean;
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button
  :class="`relative block w-full ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-button-in-screen-tertiary-hover' } p-5 border bg-button-in-screen-tertiary border-button-in-screen-tertiary-border`"
  @click.stop="handleClick"
  role="button"
  :disabled="disabled"
  >
    <TextBody1 :class="`${disabled ? 'text-button-text-disabled cursor-not-allowed' : ''}`">
      <slot />
    </TextBody1>
    <div v-if="submitting" class="absolute flex items-center justify-center inset-0 w-full bg-inherit">
        <Icon :icon="faCircleNotch" size="xl" class="animate-spin"/>
    </div>
  </button>
</template>
