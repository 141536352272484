<script setup lang="ts">
import AuthCreateAccount from "@/src/components/AuthCreateAccount.vue";
import AuthLogin from "@/src/components/AuthLogin.vue";
import AuthResetPassword from "@/src/components/AuthResetPassword.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import PopupConfirm from "@/src/popups/PopupConfirm.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  locationId: LocationId | null;
}>();

const emits = defineEmits<{
  cancelled: [];
}>();

const state = ref<"login" | "createAccount" | "resetPassword">("createAccount");
const showPopupConfirm = ref(false);
const clickedLoginFromCreateAccount = ref(false);
const enteredDetailsFromCreateAccount = ref(false);

const handleClosePopup = () => {
  // Reset so that the close button can never navigate to the login screen
  clickedLoginFromCreateAccount.value = false;

  // Only show confirm pop up when users are creating an account and have entered details
  if (state.value === "createAccount" && enteredDetailsFromCreateAccount.value) {
    showPopupConfirm.value = true;
    return;
  }

  // Otherwise close the whole popup
  emits("cancelled");
  showPopupConfirm.value = false;
  enteredDetailsFromCreateAccount.value = false;
};

const handleConfirmPopupCancelled = () => {
  showPopupConfirm.value = false;
};

const handleConfirmPopupConfirmed = () => {
  // Send users to login if they are creating an account and clicked the login button
  if (state.value === "createAccount" && clickedLoginFromCreateAccount.value) {
    state.value = "login";
    showPopupConfirm.value = false;
    clickedLoginFromCreateAccount.value = false;
    enteredDetailsFromCreateAccount.value = false;
    return;
  }

  // Otherwise close the whole popup
  emits("cancelled");
  showPopupConfirm.value = false;
  clickedLoginFromCreateAccount.value = false;
  enteredDetailsFromCreateAccount.value = false;
};

const handleAuthCreateAccountClickedLogin = () => {
  if (enteredDetailsFromCreateAccount.value) {
    showPopupConfirm.value = true;
    clickedLoginFromCreateAccount.value = true;
    return;
  }

  state.value = "login";
  showPopupConfirm.value = false;
  clickedLoginFromCreateAccount.value = false;
  enteredDetailsFromCreateAccount.value = false;
};

const handleAuthCreateAccountEnteredDetails = (hasEnteredDetails: boolean) => {
  enteredDetailsFromCreateAccount.value = hasEnteredDetails;
};
</script>

<template>
  <div>
    <div role="dialog" aria-modal="true" @click="handleClosePopup" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
      <div role="form" @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
        <ClosePopup @click="handleClosePopup" />
        <AuthCreateAccount v-if="state === 'createAccount'" :location-id="locationId" @clicked-login="handleAuthCreateAccountClickedLogin" @entered-details="handleAuthCreateAccountEnteredDetails"/>
        <AuthLogin v-else-if="state === 'login'" @clicked-forgotten-password="state = 'resetPassword'" @clicked-create-account="state = 'createAccount'" />
        <AuthResetPassword v-else-if="state === 'resetPassword'" @reset-password-complete="state = 'login'" state="email"/>
      </div>
    </div>
    <div v-if="showPopupConfirm" class="z-30">
      <PopupConfirm :prompt="t('popup.auth.confirmStop.prompt')" @cancelled="handleConfirmPopupCancelled" @confirmed="handleConfirmPopupConfirmed"/>
    </div>
  </div>
</template>
