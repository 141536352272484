import { apiOriginFromOrigin, envNameFromOrigin, mixpanelTokenFromOrigin } from "@/src/config/env";
import { type MixpanelClient, MixpanelClientDisabled, MixpanelClientEnabled } from "@/src/lib/mixpanelClient";
import type { PhoneNumber } from "@/src/lib/phoneNumber";
import {
  type AuthJwt,
  type CreateSessionFromZenotiLoginRequest,
  type Email,
  type LocationId,
  type Password,
  type SessionCreateFromZenotiLoginResponse,
  TownhouseApiClient,
  TownhouseApiError,
  type UserCreateResponse,
  type UserId,
} from "@/src/lib/townhouseApiClient";
import { useBookingRescheduleStore } from "@/src/stores/bookingRescheduleStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import type { DateTime } from "luxon";
import { defineStore } from "pinia";

export type AuthState = {
  browserId: string;
  townhouseApiClient: TownhouseApiClient;
  authJwt: AuthJwt | null;
  userId: UserId | null;
  userFirstName: string | null;
  userLastName: string | null;
  isLoggingIn: boolean;
  isCreatingAccount: boolean;
  analytics: MixpanelClient;
};

export type CreateAccountParams = {
  email: Email;
  firstName: string;
  lastName: string;
  locationId: LocationId | null;
  phone: PhoneNumber;
  dateOfBirthDateUtc: DateTime | null;
  password: Password;
  addressPostalCode: string | null;
  marketingEmailOptIn: boolean;
  marketingSmsOptIn: boolean;
  tags: string[] | null;
};

export const isAuthState = (state: unknown): state is AuthState => {
  return typeof state === "object" && state !== null && "authJwt" in state;
};

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => {
    return {
      browserId: Array.from(crypto.getRandomValues(new Uint8Array(10)))
        .map((n) => (n % 36).toString(36))
        .join(""),
      townhouseApiClient: new TownhouseApiClient(new URL(apiOriginFromOrigin())),
      authJwt: null,
      userId: null,
      userFirstName: null,
      userLastName: null,
      isLoggingIn: false,
      isCreatingAccount: false,
      analytics:
        envNameFromOrigin() === "development"
          ? new MixpanelClientDisabled()
          : new MixpanelClientEnabled(mixpanelTokenFromOrigin()),
    };
  },
  persist: {
    storage: localStorage,
    pick: ["authJwt", "userFirstName", "userLastName"],
    afterHydrate: () => {
      const authStore = useAuthStore();
      authStore.townhouseApiClient.setAuthJwt(authStore.authJwt);
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.authJwt,
  },
  actions: {
    // We can't use hydrate since we have to use async, so this has to be called when the app is first mounted
    async verifySession(): Promise<void> {
      if (!this.authJwt) {
        return;
      }
      try {
        const res = await this.townhouseApiClient.sessionGetCurrent();
        this.$patch((state) => {
          state.userId = res.sub;
          state.townhouseApiClient.setAuthJwt(state.authJwt);
        });

        Sentry.setUser(this.userId ? { id: this.userId } : null);
      } catch (e) {
        if (e instanceof TownhouseApiError) {
          return this.logout();
        }

        throw e;
      }
    },

    async createAccount(params: CreateAccountParams): Promise<void> {
      this.$patch({
        isCreatingAccount: true,
      });

      let createAccountRes: UserCreateResponse;
      try {
        createAccountRes = await this.townhouseApiClient.userCreate({
          ...params,
          phone: {
            countryCode: params.phone.countryCode,
            nationalNumber: params.phone.nationalNumber,
          },
        });
      } catch (e) {
        this.$patch({
          isCreatingAccount: false,
        });

        this.analytics.track("Account Creation Failed", {});

        throw e;
      }

      this.$patch((state) => {
        state.userId = createAccountRes.user.id;
        state.authJwt = createAccountRes.token;
        state.userFirstName = createAccountRes.user.firstName;
        state.userLastName = createAccountRes.user.lastName;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
        this.isCreatingAccount = false;
      });

      this.analytics.track("Account Created", {});

      try {
        this.analytics.identify(createAccountRes.user.id);
      } catch (e) {
        Sentry.captureException(e);
      }

      Sentry.setUser(this.userId ? { id: this.userId } : null);
    },

    async login(params: CreateSessionFromZenotiLoginRequest): Promise<void> {
      this.$patch({
        isLoggingIn: true,
      });

      let loginRes: SessionCreateFromZenotiLoginResponse;
      try {
        loginRes = await this.townhouseApiClient.sessionCreateFromZenotiLogin(params);
      } catch (e) {
        this.$patch({
          isLoggingIn: false,
        });

        this.analytics.track("Log In Failed", {});

        throw e;
      }

      this.analytics.track("Log In Success", {});

      this.$patch((state) => {
        state.userId = loginRes.user.id;
        state.authJwt = loginRes.token;
        state.userFirstName = loginRes.user.firstName;
        state.userLastName = loginRes.user.lastName;
        state.townhouseApiClient.setAuthJwt(state.authJwt);
        state.isLoggingIn = false;
      });

      try {
        this.analytics.identify(loginRes.user.id);
      } catch (e) {
        Sentry.captureException(e);
      }

      Sentry.setUser(this.userId ? { id: this.userId } : null);
    },

    setUserName(firstName?: string, lastName?: string) {
      this.$patch((state) => {
        if (firstName !== undefined) {
          state.userFirstName = firstName;
        }
        if (lastName !== undefined) {
          state.userLastName = lastName;
        }
      });
    },

    logout() {
      const userStore = useUserStore();
      const bookingStore = useBookingStore();
      const bookingRescheduleStore = useBookingRescheduleStore();

      this.analytics.track("Logged Out", {});

      try {
        this.analytics.reset();
      } catch (e) {
        Sentry.captureException(e);
      }

      this.$reset();
      userStore.$reset();
      bookingStore.$reset();
      bookingRescheduleStore.$reset();

      Sentry.setUser(null);
    },

    async fetchExperimentNewBookingFlow(): Promise<string | null> {
      const res = await this.townhouseApiClient.experimentNewBookingFlow(window.location.href);
      return res.targetUrl;
    },
  },
});
