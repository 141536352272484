<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const props = defineProps<{
  icon: IconDefinition;
  size:
    | "2xs"
    | "xs"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
  disabled?: boolean;
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!props.disabled) {
    emits("click");
  }
};
</script>

<template>
  <button 
    @click.stop="handleClick"
    role="button"
    :disabled="disabled"
    :class="`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`"
  >
    <Icon :icon="icon" :size="size" />
  </button>
</template>
