<script setup lang="ts">
import ButtonIcon from "@/src/components/ButtonIcon.vue";
import TextBody2 from "@/src/components/TextBody2.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { timeZoneFromOrigin } from "@/src/config/env";
import type { LocalIsoDate } from "@/src/lib/townhouseApiClient";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import { onMounted, ref, watch } from "vue";

const {
  startDate = DateTime.local({ zone: timeZoneFromOrigin() }).toISODate() as LocalIsoDate,
  endDate = DateTime.local({ zone: timeZoneFromOrigin() }).plus({ months: 3 }).toISODate() as LocalIsoDate,
  availableDates = [],
  disabled = false,
} = defineProps<{
  startDate?: LocalIsoDate;
  endDate?: LocalIsoDate;
  availableDates?: LocalIsoDate[];
  disabled?: boolean;
}>();

const model = defineModel<LocalIsoDate | null>();

const visibleDates = ref<DateTime[]>([]);
const allDates = ref<DateTime[]>([]);
const scrollContainer = ref<HTMLElement | null>(null);
const displayedMonth = ref("");
const leftScrollDisabled = ref(true);
const rightScrollDisabled = ref(false);

const initialiseDates = () => {
  const dates: DateTime[] = [];
  let currentDate = DateTime.fromISO(startDate, { zone: timeZoneFromOrigin() }).startOf("day");
  const endDateTime = DateTime.fromISO(endDate, { zone: timeZoneFromOrigin() }).endOf("day");

  while (currentDate <= endDateTime) {
    dates.push(currentDate);
    currentDate = currentDate.plus({ days: 1 });
  }

  allDates.value = dates;
  visibleDates.value = dates.slice(0, 7);
};

const scrollDatesByArrow = (direction: "forward" | "backward") => {
  if (!scrollContainer.value || allDates.value.length === 0) {
    return;
  }

  const dateElements = scrollContainer.value.querySelectorAll(".date-item");
  if (!dateElements[0]) {
    return;
  }
  const visibleDateIndex = Math.floor(scrollContainer.value.scrollLeft / dateElements[0].clientWidth);

  const targetIndex =
    direction === "forward"
      ? Math.min(visibleDateIndex + 7, dateElements.length - 1)
      : Math.max(visibleDateIndex - 7, 0);

  dateElements[targetIndex]?.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

const scrollDatesByScroll = () => {
  if (!scrollContainer.value || allDates.value.length === 0) {
    return;
  }

  const dateElements = scrollContainer.value.querySelectorAll(".date-item");
  const firstVisibleDateIndex = Math.floor(scrollContainer.value.scrollLeft / (dateElements[0]?.clientWidth ?? 0));

  leftScrollDisabled.value = firstVisibleDateIndex === 0;
  rightScrollDisabled.value = firstVisibleDateIndex + 7 >= dateElements.length - 1;

  visibleDates.value = allDates.value.slice(firstVisibleDateIndex, firstVisibleDateIndex + 7);
};

const isDateSelected = (date: DateTime) => {
  return model.value === date.toISODate();
};

const isDateAvailable = (date: DateTime) => {
  if (availableDates.length === 0) {
    return true;
  }

  return availableDates.some((availableDate) => availableDate === (date.toISODate() as LocalIsoDate));
};

const selectDate = (date: DateTime) => {
  if (!disabled && isDateAvailable(date)) {
    model.value = date.toISODate() as LocalIsoDate;
  }
};

const updateDisplayedMonth = () => {
  if (visibleDates.value.length === 0) {
    return;
  }

  const middleDate = visibleDates.value[3];
  displayedMonth.value = middleDate.toFormat("MMMM");
};

watch(visibleDates, updateDisplayedMonth);

onMounted(() => {
  initialiseDates();
  scrollContainer.value?.addEventListener("scroll", () => {
    scrollDatesByScroll();
  });
});
</script>

<template>
  <div class="w-full">
    <!-- Month Header with scroll buttons -->
    <div class="grid grid-cols-3 p-6">
      <TextHeader1 class="col-start-2 text-center uppercase" :class="{ 'text-text-disabled': disabled }">
        {{ displayedMonth }}
      </TextHeader1>

      <div class="flex items-center gap-12 ml-auto md:mr-26">
        <ButtonIcon
          @click="scrollDatesByArrow('backward')"
          :class="`${(disabled || leftScrollDisabled) ? 'text-input-icon-disabled' : 'hover:text-input-icon-hover'}`"
          :disabled="disabled || leftScrollDisabled"
          :icon="faArrowLeftLong"
          size="2xl"
          />
        <ButtonIcon
          @click="scrollDatesByArrow('forward')"
          :class="`${(disabled || rightScrollDisabled) ? 'text-input-icon-disabled' : 'hover:text-input-icon-hover'}`"
          :disabled="disabled || rightScrollDisabled"
          :icon="faArrowRightLong"
          size="2xl"
          />
      </div>
    </div>

    <!-- Scrollable container for dates -->
    <div
      ref="scrollContainer"
      class="overflow-x-auto scrollbar-hide scroll-smooth md:mx-8"
    >
      <div class="flex min-w-full px-2 md:px-4 pb-4 gap-1">
        <div
          v-for="date in allDates"
          :key="date.toFormat('yyyy-MM-dd')"
          @click="selectDate(date)"
          class="date-item w-[calc((100%-1rem)/7)] flex-shrink-0 flex-grow-0 flex flex-col items-center gap-2"
          :class="{
            'cursor-pointer': isDateAvailable(date) && !disabled,
            'opacity-50': disabled,
            'cursor-not-allowed': disabled || !isDateAvailable(date)
          }"
        >
          <TextBody2 class="uppercase">{{ date.toFormat('ccc') }}</TextBody2>
          <div
            class="aspect-square w-full max-w-[40px] flex items-center justify-center rounded-full transition-colors relative"
            :class="{
              'bg-button-primary': isDateSelected(date),
              'hover:bg-gray-100': !disabled && isDateAvailable(date) && !isDateSelected(date),
            }"
          >
            <TextBody2
              :class="{
                'opacity-50': !isDateAvailable(date),
                'line-through': !isDateAvailable(date)
                 }"
            >
              {{ date.toFormat('d') }}
            </TextBody2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
