<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const handleClick = () => {
  router.push({
    path: "/",
    query: route.query,
  });
};
</script>

<template>
  <div class="flex flex-col items-center pt-40 gap-10">
    <TextHeader1>{{ t("notFound.header") }}</TextHeader1>
    <TextBody1 class="text-center">{{ t("notFound.descriptionOne") }}</TextBody1>
    <TextBody1 class="text-center">{{ t("notFound.descriptionTwo") }}</TextBody1>
    <ButtonPrimary :onclick="handleClick" class="max-w-[300px]">{{ t("notFound.backButton") }}</ButtonPrimary>
  </div>
</template>
