<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { useI18n } from "vue-i18n";

const { n } = useI18n();

defineProps<{
  serviceName: string;
  description: string;
  priceCents: number;
  durationMins: number;
}>();

defineEmits<{
  cancelled: [];
}>();
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
      <ClosePopup @click="$emit('cancelled')" />
      <div class="w-full flex flex-col items-center">
        <TextHeader1 class="p-10 mx-10 text-center">{{ serviceName }}</TextHeader1>
        <SectionDivider />
      </div>
      <div class="p-10 flex flex-col gap-10 items-center">
        <TextBody1 class="text-center">{{ description }}</TextBody1>
        <div class="flex flex-col items-center">
          <div class="flex gap-5">
            <TextHeader3>Price</TextHeader3>
            <TextBody1>{{ n(priceCents/100, 'currency') }}</TextBody1>
          </div>
          <div class="flex gap-5">
            <TextHeader3>Duration</TextHeader3>
            <TextBody1>{{ durationMins }} Mins</TextBody1>
          </div>
        </div>
        <div class="w-[300px] mt-10">
          <ButtonPrimary @click="$emit('cancelled')">Continue Booking</ButtonPrimary>
        </div>
      </div>
    </div>
  </div>
</template>
