<script setup lang="ts">
import type { ButtonHTMLAttributes } from "vue";

const { disabled, buttonType = "button" } = defineProps<{
  disabled?: boolean;
  buttonType?: ButtonHTMLAttributes["type"];
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button @click.stop="handleClick" class="block w-full underline underline-offset-2 decoration-button-plaintext-underline" :type="buttonType" :class="`${disabled ? 'text-button-text-disabled cursor-not-allowed' : 'cursor-pointer hover:decoration-button-plaintext-underline-hover'}`">
    <slot class="font-bold" />
  </button>
</template>
