<script setup lang="ts">
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import DatePicker from "@/src/components/DatePicker.vue";
import DatePickerSkeleton from "@/src/components/DatePickerSkeleton.vue";
import GridAvailableDateTimes from "@/src/components/GridAvailableDateTimes.vue";
import Icon from "@/src/components/Icon.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionError from "@/src/components/SectionError.vue";
import SectionWarning from "@/src/components/SectionWarning.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { timeZoneFromOrigin } from "@/src/config/env";
import { type LocationId, TownhouseApiReservationFailedError } from "@/src/lib/townhouseApiClient";
import PopupAlert from "@/src/popups/PopupAlert.vue";
import PopupSelectEmployee from "@/src/popups/PopupSelectEmployee.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();
const userStore = useUserStore();

defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const {
  hasMultipleGuests,
  hasParallelService,
  bookingDateLocal,
  bookingDateTimeUtc,
  availableDates,
  availableDateTimesUtc,
  employeeChosen,
  reserveBookingFailure,
  fetchAvailableDatesFailure,
  fetchAvailableTimesFailure,
  isReservationExpired,
  isReservingBooking,
} = storeToRefs(bookingStore);
const { fetchPaymentAccountsFailure, isFetchingPaymentAccounts } = storeToRefs(userStore);
const chosenBookingDateTimeUtc = ref<DateTime | null>(null);

const selectingEmployee = ref<boolean>(false);
const showMoreTimesClicked = ref<boolean>(false);

const isPreparingForConfirmStage = computed(
  () =>
    (isReservingBooking.value || isFetchingPaymentAccounts.value) &&
    !fetchPaymentAccountsFailure.value &&
    !reserveBookingFailure.value,
);

const formattedFirstAvailableDate = computed(() => {
  if (!availableDates.value?.availableDates[0]) {
    return null;
  }

  return DateTime.fromISO(availableDates.value.availableDates[0], { zone: timeZoneFromOrigin() }).toFormat(
    "EEEE, d MMMM",
  );
});

const pageErrorSection = useTemplateRef("pageErrorSection");

onMounted(() => {
  bookingStore.fetchAvailableDates();

  if (bookingStore.isReservationExpired) {
    bookingStore.refetchAvailableTimes();
  }
});

watch(pageErrorSection, (newPageErrorSection) => {
  newPageErrorSection?.scrollIntoView({ behavior: "smooth" });
});

watch(reserveBookingFailure, (newReserveBookingFailure) => {
  if (newReserveBookingFailure instanceof TownhouseApiReservationFailedError) {
    bookingStore.refetchAvailableTimes();
  }
});

const handleSelectEmployeeClicked = () => {
  bookingStore.fetchAvailableEmployees();

  selectingEmployee.value = true;
};

const handleSelectEmployeeCancelled = () => {
  selectingEmployee.value = false;
};

const handleReservationExpiredCancelled = () => {
  bookingStore.setReservationExpired(false);
};

const handleAvailableDatesTryAgain = () => {
  bookingStore.refetchAvailableDates();
};

const handleAvailableTimesTryAgain = () => {
  bookingStore.refetchAvailableTimes();
};

watch(bookingDateLocal, (newBookingDateLocal) => {
  if (!newBookingDateLocal) {
    return;
  }

  showMoreTimesClicked.value = false;
  bookingDateTimeUtc.value = null;
  availableDateTimesUtc.value = null;
  bookingStore.fetchAvailableTimes();
});

watch(chosenBookingDateTimeUtc, (newChosenBookingDateTimeUtc: DateTime | null) => {
  if (!newChosenBookingDateTimeUtc) {
    return;
  }

  bookingStore.setBookingDateTimeUtc(newChosenBookingDateTimeUtc);
});
</script>

<template>
  <PopupAlert
    v-if="isReservationExpired"
    :title="t('dateTime.reservationExpired.title')"
    :message="t('dateTime.reservationExpired.message')"
    :confirm="t('dateTime.reservationExpired.confirm')"
    @cancelled="handleReservationExpiredCancelled"
     />

  <PopupSelectEmployee v-if="selectingEmployee" @cancelled="handleSelectEmployeeCancelled" @employee-chosen-or-next-click="handleSelectEmployeeCancelled" />
  <LocationBanner />
  <div class="flex flex-col gap-5 px-5">
    <h2 class="my-5 flex justify-center text-center">
      <TextHeader1 class="text-center" :class="isPreparingForConfirmStage ? 'text-text-disabled' : ''">{{ t('dateTime.header') }}</TextHeader1>
    </h2>
    <div v-if="fetchPaymentAccountsFailure || reserveBookingFailure" ref="pageErrorSection" class="flex justify-center">
      <div v-if="reserveBookingFailure" class="max-w-[600px]">
        <SectionError :error-message="reserveBookingFailure instanceof TownhouseApiReservationFailedError
        ? t('dateTime.reserveBookingTimeNoLongerAvailableError')
        : t('dateTime.reserveBookingError')"
        :hide-customer-care-message="reserveBookingFailure instanceof TownhouseApiReservationFailedError"/>
      </div>
      <div v-else class="max-w-[600px]">
        <SectionError :error-message="t('dateTime.paymentDetailsError')"/>
      </div>
    </div>
    <div v-if="!hasMultipleGuests && !hasParallelService" class="flex justify-center md:mb-10">
      <ButtonInScreenTertiary class="w-full max-w-[600px]" @click="handleSelectEmployeeClicked" :disabled="isPreparingForConfirmStage">
        <div class="flex gap-x-5 justify-between items-center" :class="isPreparingForConfirmStage ? 'text-text-disabled' : ''">
          <Icon :icon="faUser" size="1x" />
          <TextHeader3 class="w-full text-left">
            {{ employeeChosen ? t('dateTime.nailArtist.chosen', { name: employeeChosen.firstName }) : t('dateTime.nailArtist.any') }}
          </TextHeader3>
          <Icon :icon="faAngleRight" size="lg" />
        </div>
      </ButtonInScreenTertiary>
    </div>
    <SectionWarning v-if="availableDates && availableDates.from !== availableDates.availableDates[0]" class="w-full">
      <i18n-t keypath="dateTime.firstDateNotToday" tag="span">
        <template #date>
          {{ formattedFirstAvailableDate }}
        </template>
      </i18n-t>
    </SectionWarning>
    <div v-if="availableDates" class="flex flex-col p-[1px] md:border-available-times-border md:border">
      <div class="sticky top-0 bg-white md:border-available-times-border md:border-b">
        <DatePicker
          class="w-full"
          v-model="bookingDateLocal"
          :startDate="availableDates.from"
          :endDate="availableDates.to"
          :availableDates="availableDates.availableDates"
          :disabled="isPreparingForConfirmStage"
        />
      </div>
      <div class="w-full flex-1 overflow-y-auto">
        <div class="flex flex-col md:p-5">
          <GridAvailableDateTimes
            v-if="availableDateTimesUtc && availableDateTimesUtc.length > 0"
            :available-date-times-utc="availableDateTimesUtc"
            v-model="chosenBookingDateTimeUtc"
            :disabled="isPreparingForConfirmStage"
            :date-time-format="t('dateTime.timeFormat')"
          />
          <div v-else-if="availableDateTimesUtc && availableDateTimesUtc.length === 0" class="w-full h-full flex items-center justify-center">
            <TextBody1>{{ t('dateTime.noTimesLeft') }}</TextBody1>
          </div>
          <div v-else-if="fetchAvailableTimesFailure" class="w-full h-full flex justify-center">
            <SectionError hide-border :error-message="t('dateTime.availableTimesError')" :try-again-handler="handleAvailableTimesTryAgain"/>
          </div>
          <div v-else :class="`w-full h-full flex flex-col gap-4 items-center justify-center ${isPreparingForConfirmStage ? 'text-text-disabled' : ''}`">
            <Icon :icon="faCircleNotch" size="2x" class="animate-spin"/>
            <TextBody1>{{t('dateTime.fetchingAvailableTimes')}}</TextBody1>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="fetchAvailableDatesFailure" class="w-full h-full flex justify-center">
      <SectionError :error-message="t('dateTime.availableDatesError')" :try-again-handler="handleAvailableDatesTryAgain"/>
    </div>
    <div v-else class="flex flex-col p-[1px] md:border-available-times-border md:border">
      <DatePickerSkeleton />
    </div>
  </div>
</template>
