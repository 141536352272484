<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Checkbox from "@/src/components/Checkbox.vue";
import CheckboxSkeleton from "@/src/components/CheckboxSkeleton.vue";
import DropdownPhoneCountryWithCallingCode from "@/src/components/DropdownPhoneCountryWithCallingCode.vue";
import Icon from "@/src/components/Icon.vue";
import InputDate from "@/src/components/InputDate.vue";
import InputDateSkeleton from "@/src/components/InputDateSkeleton.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputText from "@/src/components/InputText.vue";
import InputTextSkeleton from "@/src/components/InputTextSkeleton.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody1Skeleton from "@/src/components/TextBody1Skeleton.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { regionFromOrigin } from "@/src/config/env";
import type { CountryCode } from "@/src/lib/countryCode";
import { validateAndFormatDateOfBirth, validateAndFormatPhone, validateName } from "@/src/lib/formValidationHelpers";
import type { PhoneNumberNational } from "@/src/lib/phoneNumber";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const userStore = useUserStore();
const route = useRoute();
const { user, isUpdatingUserDetails, fetchUserFailure } = storeToRefs(userStore);
const router = useRouter();
const hasUpdated = ref(false);

// State variables
const firstName = ref("");
const lastName = ref("");
const email = ref("");
const phoneNationalNumber = ref<PhoneNumberNational>("" as PhoneNumberNational);
// Default country code to region of website if user has no number
const phoneCountryCode = ref<CountryCode>(regionFromOrigin());
const dateOfBirthDay = ref("");
const dateOfBirthMonth = ref("");
const dateOfBirthYear = ref("");
const marketingEmailOptIn = ref<boolean | null>(null);
const marketingSmsOptIn = ref<boolean | null>(null);

watch(
  user,
  () => {
    if (!user.value) {
      return;
    }

    firstName.value = user.value.firstName;
    lastName.value = user.value.lastName;
    email.value = user.value.email;
    phoneNationalNumber.value = user.value.phoneNumber?.nationalNumber ?? ("" as PhoneNumberNational);
    phoneCountryCode.value = user.value.phoneNumber?.countryCode ?? regionFromOrigin();
    dateOfBirthDay.value = user.value.dateOfBirthUtc?.day.toString() ?? "";
    dateOfBirthMonth.value = user.value.dateOfBirthUtc?.month.toString() ?? "";
    dateOfBirthYear.value = user.value.dateOfBirthUtc?.year.toString() ?? "";
    marketingEmailOptIn.value = user.value.marketingEmailOptIn;
    marketingSmsOptIn.value = user.value.marketingSmsOptIn;
  },
  { immediate: true },
);

// Update component refs to what is stored in state - it is possible for refs to be
// initialised with null values before

// Error state variables
const formError = ref<string | undefined>();
const firstNameError = ref<string | undefined>();
const lastNameError = ref<string | undefined>();
const phoneError = ref<string | undefined>();
const dateOfBirthError = ref<string | undefined>();
const dateOfBirthErrorInDay = ref(false);
const dateOfBirthErrorInMonth = ref(false);
const dateOfBirthErrorInYear = ref(false);

const handleBack = () => {
  router.push({
    path: "/account",
    query: route.query,
  });
};

const handleTryAgain = () => {
  window.location.reload();
};

const updateAccount = async () => {
  if (isUpdatingUserDetails.value) {
    return;
  }

  hasUpdated.value = false;

  // Field validations
  validateName({
    name: firstName.value,
    errorRef: firstNameError,
    errorMessage: t("createAccount.error.firstNameInvalid"),
  });
  validateName({
    name: lastName.value,
    errorRef: lastNameError,
    errorMessage: t("createAccount.error.lastNameInvalid"),
  });
  const phoneParsed = validateAndFormatPhone({
    phoneNumber: phoneNationalNumber.value,
    countryCode: phoneCountryCode.value,
    errorRef: phoneError,
    errorMessage: t("createAccount.error.phoneInvalid"),
  });
  const dateOfBirthParsed = validateAndFormatDateOfBirth({
    dateOfBirth: {
      day: dateOfBirthDay.value,
      month: dateOfBirthMonth.value,
      year: dateOfBirthYear.value,
    },
    errorRefs: {
      dateErrorRef: dateOfBirthError,
      errorInDayRef: dateOfBirthErrorInDay,
      errorInMonthRef: dateOfBirthErrorInMonth,
      errorInYearRef: dateOfBirthErrorInYear,
    },
    errorMessages: {
      invalidError: t("createAccount.error.dateOfBirthInvalid"),
      belowAgeError: t("createAccount.error.dateOfBirthUnder16"),
    },
  });

  if (
    firstNameError.value ||
    lastNameError.value ||
    phoneError.value ||
    !phoneParsed ||
    dateOfBirthError.value ||
    !dateOfBirthParsed
  ) {
    return;
  }

  try {
    await userStore.updateUser({
      firstName: firstName.value,
      lastName: lastName.value,
      phoneNumber: phoneParsed,
      dateOfBirthUtc: dateOfBirthParsed,
      marketingEmailOptIn: marketingEmailOptIn.value ?? false,
      marketingSmsOptIn: marketingSmsOptIn.value ?? false,
    });
  } catch (_) {
    formError.value = t("account.details.formError");
    return;
  }

  formError.value = undefined;
  hasUpdated.value = true;
};
</script>

<template>
  <form novalidate @submit.prevent="updateAccount" class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.details.header") }}</TextHeader1>
    <NavigationTop :disabled="isUpdatingUserDetails" @back="handleBack" />
    <div v-if="user !== null" class="w-full max-w-[600px] flex flex-col gap-10 p-10">
      <InputText :disabled="isUpdatingUserDetails" v-model="firstName" :error="firstNameError" :label="t('account.details.firstName')" autocomplete="given-name" required />
      <InputText :disabled="isUpdatingUserDetails" v-model="lastName" :error="lastNameError" :label="t('account.details.lastName')" autocomplete="family-name" required/>
      <InputEmail v-model="email" :label="t('account.details.email')" disabled required/>
      <div class="flex items-start gap-5">
        <DropdownPhoneCountryWithCallingCode class="shrink-0" :disabled="isUpdatingUserDetails" :label="t('account.details.country')" v-model="phoneCountryCode" />
        <InputText class="w-full" :disabled="isUpdatingUserDetails" v-model="phoneNationalNumber" :label="t('account.details.phone')" required :error="phoneError" />
      </div>
      <InputDate :disabled="isUpdatingUserDetails" v-model:day="dateOfBirthDay"
        v-model:month="dateOfBirthMonth"
        v-model:year="dateOfBirthYear"
        is-date-of-birth
        :label="t('account.details.dateOfBirth')"
        required
        :error="dateOfBirthError"
        :error-in-day="dateOfBirthErrorInDay"
        :error-in-month="dateOfBirthErrorInMonth"
        :error-in-year="dateOfBirthErrorInYear" />
      <TextBody1 :class="`w-full ${isUpdatingUserDetails ? 'text-text-disabled' : ''}`">{{ t("account.details.marketing.description") }}</TextBody1>
      <div class="flex gap-10">
        <Checkbox :disabled="isUpdatingUserDetails" v-model="marketingEmailOptIn" :has-error="false">
          <TextBody1>
            {{ t("account.details.marketing.email") }}
          </TextBody1>
        </Checkbox>
        <Checkbox :disabled="isUpdatingUserDetails" v-model="marketingSmsOptIn" :has-error="false">
          <TextBody1>
            {{ t("account.details.marketing.sms") }}
          </TextBody1>
        </Checkbox>
      </div>
      <div class="flex flex-col gap-10 items-center justify-center">
        <ButtonPrimary button-type="submit" :submitting="isUpdatingUserDetails" class="w-[200px]">{{ t("account.details.update") }}</ButtonPrimary>
        <div v-if="hasUpdated && !formError" class="flex items-center gap-5">
          <Icon class="text-complete-tick" :icon="faCircleCheck" size="2xl"/>
          <TextBody1>{{ t("account.details.success") }}</TextBody1>
        </div>
        <div v-else-if="formError">
          <TextBodyError>
            <TextBody1>{{ formError }}</TextBody1>
          </TextBodyError>
        </div>
      </div>
    </div>
    <div v-else-if="fetchUserFailure" class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />
    </div>
    <div v-else class="w-full max-w-[600px] flex flex-col gap-10 p-10">
      <InputTextSkeleton />
      <InputTextSkeleton />
      <InputTextSkeleton />
      <InputTextSkeleton />
      <InputDateSkeleton />
      <div>
        <TextBody1Skeleton />
        <TextBody1Skeleton />
      </div>
      <div class="flex gap-10">
        <CheckboxSkeleton class="basis-1/2"/>
        <CheckboxSkeleton class="basis-1/2"/>
      </div>
      <ButtonPrimary disabled>{{ t("account.details.update") }}</ButtonPrimary>
    </div>
  </form>
</template>
