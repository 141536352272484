<script setup lang="ts">
import AuthCreateAccount from "@/src/components/AuthCreateAccount.vue";
import PopupConfirm from "@/src/popups/PopupConfirm.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

const showPopupConfirm = ref(false);
const enteredDetailsFromCreateAccount = ref(false);

watch(
  isLoggedIn,
  () => {
    if (isLoggedIn.value) {
      if (route.query && typeof route.query["returnTo"] === "string") {
        return router.push({
          path: route.query["returnTo"],
          query: {
            ...route.query,
            returnTo: undefined,
          },
        });
      }

      router.push({
        path: "/",
        query: route.query,
      });
    }
  },
  {
    immediate: true,
  },
);

const handleLoginClicked = () => {
  if (enteredDetailsFromCreateAccount.value) {
    showPopupConfirm.value = true;
    return;
  }

  router.push({
    path: "/auth/login",
    query: route.query,
  });
};

const handleAuthCreateAccountEnteredDetails = (hasEnteredDetails: boolean) => {
  enteredDetailsFromCreateAccount.value = hasEnteredDetails;
};

const handleConfirmPopupCancelled = () => {
  showPopupConfirm.value = false;
};

const handleConfirmPopupConfirmed = () => {
  router.push({
    path: "/auth/login",
    query: route.query,
  });
};
</script>

<template>
  <div class="flex flex-col items-center gap-10">
    <AuthCreateAccount :location-id="null" @clicked-login="handleLoginClicked" @entered-details="handleAuthCreateAccountEnteredDetails"/>
    <div v-if="showPopupConfirm" class="z-30">
      <PopupConfirm :prompt="t('popup.auth.confirmStop.prompt')" @cancelled="handleConfirmPopupCancelled" @confirmed="handleConfirmPopupConfirmed"/>
    </div>
  </div>
</template>
