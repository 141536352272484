<script setup lang="ts">
import TextHeader2 from "@/src/components/TextHeader2.vue";

const model = defineModel<string | null>();

defineProps<{
  labelsAndValues: { label: string; value: string | null }[];
}>();

const handleClick = (value: string | null) => {
  model.value = value;
};
</script>

<template>
<div role="radiogroup" class="flex gap-5 items-center bg-button-toggle-group-background p-5">
  <button v-for="({label, value}) in labelsAndValues" role="radio" :aria-checked="model === value" @click.stop="handleClick(value)" :class="`display w-full whitespace-nowrap px-5 py-2 cursor-pointer ${model === value ? 'bg-button-primary' : 'bg-button-toggle-group-foreground hover:bg-button-toggle-group-hover' }`">
    <TextHeader2>{{ label }}</TextHeader2>
  </button>
</div>
</template>
