import "@/src/assets/main.css";

import App from "@/src/App.vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";

import { type EnvName, apiOriginFromOrigin, envNameFromOrigin } from "@/src/config/env";
import { localEnGb } from "@/src/config/locales/en-GB";
import { localEnUs } from "@/src/config/locales/en-US";
import { generateRouter } from "@/src/router";
import { isAuthState } from "@/src/stores/authStore";
import { isBookingRescheduleState } from "@/src/stores/bookingRescheduleStore";
import { isBookingState } from "@/src/stores/bookingStore";
import { isUserState } from "@/src/stores/userStore";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import _ from "lodash";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";

// We treat the GB i18n locale as the source of truth – all other locales must
// have every key this one has.
type I18nSchema = typeof localEnGb;

const env = envNameFromOrigin();

const gtmIdMap: Record<EnvName, string> = {
  "gb-staging": "GTM-NX5L2SDW",
  "gb-production": "GTM-TGW5SVHV",
  "us-staging": "GTM-NK2SNL9R",
  "us-production": "GTM-MZWH3TBL",
  development: "GTM-NX5L2SDW",
};

const gtmId = gtmIdMap[env];

let locale: "en-GB" | "en-US";
if (["us-production", "us-staging"].includes(env)) {
  locale = "en-US";
} else {
  locale = "en-GB";
}

const i18n = createI18n<[I18nSchema], "en-GB" | "en-US">({
  legacy: false,
  locale,
  fallbackLocale: "en-GB",
  numberFormats: {
    "en-GB": {
      currency: {
        style: "currency",
        currency: "GBP",
        notation: "standard",
        maximumFractionDigits: 0,
      },
    },
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
        notation: "standard",
        maximumFractionDigits: 0,
      },
    },
  },
  messages: {
    "en-GB": localEnGb,
    "en-US": localEnUs,
  },
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
pinia.use(
  Sentry.createSentryPiniaPlugin({
    attachPiniaState: true,
    addBreadcrumbs: true,
    // Sentry's breadcrumbs don't support Maps and Sets so we need to normalise them
    stateTransformer: (state) => {
      if (isBookingState(state)) {
        return {
          ...state,
          servicesByGuest: state.servicesByGuest.map((services) => {
            return _.mapValues(Object.fromEntries(services.entries()), (service) => {
              return {
                ...service,
                addOnIds: Array.from(service.addOnIds),
                removalIds: Array.from(service.removalIds),
              };
            });
          }),
          servicesByLocation: _.mapValues(Object.fromEntries(state.servicesByLocation?.entries() ?? []), (service) => {
            return {
              ...service,
              removals: Object.fromEntries(service.removals.entries()),
              addOns: Object.fromEntries(service.addOns.entries()),
            };
          }),
          // Omit these errors because they will be in the Sentry issue's stack trace
          confirmBookingFailure: null,
          startCreatePaymentAccountFailure: null,
          deletePaymentAccountFailure: null,
          fetchAvailableEmployeesFailure: null,
          fetchAvailableTimesFailure: null,
          fetchPaymentAccountsFailure: null,
          fetchServicesFailure: null,
          reserveBookingFailure: null,
        };
      }

      if (isBookingRescheduleState(state)) {
        return {
          ...state,
          // Omit these errors because they will be in the Sentry issue's stack trace
          fetchAvailableTimesFailure: null,
          confirmBookingRescheduleFailure: null,
        };
      }

      if (isUserState(state)) {
        return {
          ...state,
          // Omit these errors because they will be in the Sentry issue's stack trace
          cancelBookingFailure: null,
          fetchUserBookingFailure: null,
          fetchUserFailure: null,
          fetchLoyaltyPointsFailure: null,
        };
      }

      if (isAuthState(state)) {
        return {
          ...state,
          // Omit JWT for security reasons
          authJwt: null,
          // Omit the client because there's nothing useful we can do with it
          townhouseApiClient: null,
        };
      }

      return state;
    },
  }),
);

const app = createApp(App);
const router = generateRouter();

// Set the page title on every navigation using the stage and i18n
router.beforeEach((to) => {
  document.title = i18n.global.t(`title.${to.meta["stage"] || "default"}`);
});

if (env !== "development") {
  Sentry.init({
    app,
    dsn: "https://b3927b03af77c78f756a3929637b636b@o4505952816726016.ingest.us.sentry.io/4508143714893824",
    normalizeDepth: 10,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    environment: env,
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [apiOriginFromOrigin()],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.use(i18n).use(pinia).use(router).mount("body");

app.use(
  createGtm({
    id: gtmId,
    vueRouter: router,
    enabled: true,
    debug: false,
    loadScript: true,
  }),
);
