<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import { timeZoneFromOrigin } from "@/src/config/env";
import type { DateTime } from "luxon";

const props = defineProps<{
  availableDateTimesUtc: DateTime[];
  dateTimeFormat: string;
  disabled?: boolean;
}>();

const model = defineModel<DateTime | null>();

const handleClick = (availableDateTimeUtc: DateTime) => {
  if (!props.disabled) {
    model.value = availableDateTimeUtc;
  }
};
</script>

<template>
  <div role="radiogroup" class="w-full flex flex-wrap gap-5">
    <div v-for="availableDateTimeUtc in availableDateTimesUtc" class="w-[calc(33.333%-0.833rem)] lg:w-[calc(20%-1rem)] shrink-0 grow-0">
      <ButtonInScreenPrimary
      class="block w-full p-5"
      :selected="model?.equals(availableDateTimeUtc)"
      @click="handleClick(availableDateTimeUtc)"
      role="radio"
      :disabled="disabled"
      :disabled-styles="model?.equals(availableDateTimeUtc) ? 'bg-available-time-selected-disabled cursor-not-allowed' : 'bg-available-time-unselected-disabled cursor-not-allowed'"
      >
        <TextBody1 class="whitespace-nowrap">{{ availableDateTimeUtc.setZone(timeZoneFromOrigin()).toFormat(dateTimeFormat) }}</TextBody1>
      </ButtonInScreenPrimary>
    </div>
  </div>
</template>
