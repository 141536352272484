<script setup lang="ts">
import ClosePopup from "@/src/components/ClosePopup.vue";
import Icon from "@/src/components/Icon.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { apiOriginFromOrigin } from "@/src/config/env";
import { TownhouseApiCreatePaymentAccountRateLimitedError } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { useUserStore } from "@/src/stores/userStore";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";

type CreatePaymentAccountCallbackEvent = {
  isSuccess: boolean;
  errorType: "addressOrNameInvalid" | "cardDetailsInvalid" | "cardAlreadyAdded" | "unknown" | null;
};

const isCreatePaymentAccountCallbackEvent = (
  event: MessageEvent,
): event is MessageEvent<CreatePaymentAccountCallbackEvent> => {
  return (
    typeof event.data === "object" &&
    "isSuccess" in event.data &&
    typeof event.data.isSuccess === "boolean" &&
    "errorType" in event.data &&
    [null, "addressOrNameInvalid", "cardDetailsInvalid", "cardAlreadyAdded", "unknown"].includes(event.data.errorType)
  );
};

const { t } = useI18n();
const authStore = useAuthStore();
const userStore = useUserStore();
const { startCreatePaymentAccountUrl, startCreatePaymentAccountFailure } = storeToRefs(userStore);

const createPaymentAccountFailure = ref<CreatePaymentAccountCallbackEvent["errorType"] | null>(null);

const emit = defineEmits<{
  success: [];
  cancelled: [];
}>();

// The API will send us a postMessage event when the card process finishes
const handleIframeMessage = (event: MessageEvent) => {
  if (
    !(
      [apiOriginFromOrigin(), window.location.origin].includes(event.origin) &&
      isCreatePaymentAccountCallbackEvent(event)
    )
  ) {
    return;
  }

  userStore.clearPaymentAccountUrl();

  if (event.data.isSuccess) {
    emit("success");
    authStore.analytics.track("Payment Account Added Successfully", {});
    return;
  }

  createPaymentAccountFailure.value = event.data.errorType;
  authStore.analytics.track("Payment Account Addition Failed", {});
};

const handleRetryStartCreatePaymentAccount = async () => {
  createPaymentAccountFailure.value = null;
  await userStore.startCreatePaymentAccount();
};

onMounted(() => {
  // Listen for postMessage from the iframe
  window.addEventListener("message", handleIframeMessage);

  authStore.analytics.track("Add Payment Account Shown", {});

  // Do not await this promise, it can fail but we don't want `onMounted` to fail
  userStore.startCreatePaymentAccount();
});

onUnmounted(() => {
  // Clean up the event listener when the component is destroyed
  window.removeEventListener("message", handleIframeMessage);

  userStore.clearPaymentAccountUrl();
});
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div role="form" @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
      <div class="p-5 pt-10 w-full flex flex-col">
        <ClosePopup @click="$emit('cancelled')" />
        <TextHeader1 class="text-center">{{ t('createPaymentAccount.header') }}</TextHeader1>
        <div v-if="startCreatePaymentAccountFailure" class="flex flex-col items-center">
          <SectionError v-if="startCreatePaymentAccountFailure instanceof TownhouseApiCreatePaymentAccountRateLimitedError" :error-message="t('createPaymentAccount.error.unableToStartRateLimited')" :try-again-handler="handleRetryStartCreatePaymentAccount" hide-border />
          <SectionError v-else :error-message="t('createPaymentAccount.error.unableToStartUnknown')" :try-again-handler="handleRetryStartCreatePaymentAccount" hide-border />
        </div>
        <div v-else-if="createPaymentAccountFailure">
          <SectionError :error-message="t(`createPaymentAccount.error.${createPaymentAccountFailure}`)" :try-again-handler="handleRetryStartCreatePaymentAccount" hide-border />
        </div>
        <div v-else-if="startCreatePaymentAccountUrl">
          <div class="p-10">
            <TextBody1 class="text-center">{{ t('createPaymentAccount.message') }}</TextBody1>
          </div>
          <iframe class="w-full h-[600px]" :src="startCreatePaymentAccountUrl"></iframe>
        </div>
        <!-- Make the div the same height of the iframe whils we're loading it so the UI doesn't shift around -->
        <div v-else class="mt-10 w-full h-[600px] flex items-center justify-center">
          <Icon :icon="faCircleNotch" size="2x" class="animate-spin"/>
        </div>
      </div>
    </div>
  </div>
</template>
