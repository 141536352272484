<script setup lang="ts">
import AuthLogin from "@/src/components/AuthLogin.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);

watch(
  isLoggedIn,
  () => {
    if (isLoggedIn.value) {
      if (route.query && typeof route.query["returnTo"] === "string") {
        return router.push({
          path: route.query["returnTo"],
          query: {
            ...route.query,
            returnTo: undefined,
          },
        });
      }

      router.push({
        path: "/",
        query: route.query,
      });
    }
  },
  {
    immediate: true,
  },
);

const handleForgottenPasswordClicked = () => {
  router.push({
    path: "/auth/reset-password",
    query: route.query,
  });
};

const handleCreateAccountClicked = () => {
  router.push({
    path: "/auth/create-account",
    query: route.query,
  });
};
</script>

<template>
  <div class="flex flex-col items-center gap-10">
    <AuthLogin @clicked-forgotten-password="handleForgottenPasswordClicked" @clicked-create-account="handleCreateAccountClicked" />
  </div>
</template>
