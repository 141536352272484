<script setup lang="ts">
import SectionWarning from "@/src/components/SectionWarning.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextLink from "@/src/components/TextLink.vue";
import { envNameFromOrigin, storeLocatorUidFromOrigin } from "@/src/config/env";
import { onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const props = defineProps<{
  press: boolean | undefined;
  bookingConfirmationInterrupted: boolean | undefined;
}>();

// We need to use the prop non-reactively so that prop changes don't cause this page to re-render
// and make the booking confirmation interrupted message disappear
const showBookingConfirmationInterruptedMessage = props.bookingConfirmationInterrupted;

const addClickHandlerForLocation = (location: Element) => {
  const locationId = location.querySelector(".storelocator-CENTERCODE")?.textContent || null;
  const bookNowButton = location.querySelector(".storelocator-THBOOKNOW .storelocator-btn") as HTMLAnchorElement | null;

  if (!(bookNowButton && locationId)) {
    return;
  }

  const currentQuery = new URLSearchParams(window.location.search).toString();
  bookNowButton.setAttribute("href", `/locations/${locationId}${currentQuery ? `?${currentQuery}` : ""}`);

  bookNowButton.onclick = (e) => {
    e.preventDefault();
    router.push({
      path: `/locations/${locationId}`,
      query: route.query,
    });
  };
};

onMounted(() => {
  // Filter the list of shown locations down to just what we want to be visible in staging and development
  // @ts-expect-error
  window.storeLocatorFilterLocationsHandler = (locations: { data: Record<string, string | number> }[]) => {
    const currentEnv = envNameFromOrigin();
    if (currentEnv === "gb-staging" || currentEnv === "development") {
      return locations.filter((location) => Object.values(location.data).includes("GB-LON-GPS"));
    }

    return locations;
  };

  // Re-route the book now link to this booking flow when a map marker is clicked
  // @ts-expect-error
  window.storeLocatorShowMapPopupInfoEvent = (
    _store: { data: Record<string, string | number> },
    popup: { _content: HTMLElement },
  ) => {
    addClickHandlerForLocation(popup._content);
  };

  // Re-route the book now link under every location shown in the locations list
  // @ts-expect-error
  window.storeLocatorListLocationsHandler = () => {
    const locations = document.querySelectorAll(".storelocator-store");

    for (const location of Array.from(locations)) {
      addClickHandlerForLocation(location);
    }
  };
});
</script>

<template>
  <div class="flex flex-col gap-5 p-5">
    <SectionWarning v-if="showBookingConfirmationInterruptedMessage">
      <i18n-t keypath="locations.warning" tag="span">
        <template #contactSupportLink>
          <TextLink>
            <a :href="t('general.pageError.contactSupportLink')" target="_blank" rel="noopener">{{ t('general.pageError.contactSupportLinkText') }}</a>
          </TextLink>
        </template>
      </i18n-t>
    </SectionWarning>
    <h2 class="flex justify-center">
      <TextHeader1 class="text-center">{{ t('locations.header') }}</TextHeader1>
    </h2>
    <div class="storelocatorwidget">
      <div id="storelocatorwidget"></div>
    </div>
    <component
      is="script"
      type="text/javascript"
      id="mapboxscript"
      src="https://cdn.storelocatorwidgets.com/widget/mapbox-gl.js"
    ></component>
    <component
      is="script"
      type="text/javascript"
      id="storelocatorscript"
      :data-uid="storeLocatorUidFromOrigin()"
      data-platform="MapTiler"
      src="https://cdn.storelocatorwidgets.com/widget/widget.js"
    ></component>
  </div>
</template>
