<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import Icon from "@/src/components/Icon.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const route = useRoute();
const router = useRouter();

// Reference non-reactively so that the navigating away from this page, causing the
// booking store to be cleared, doesn't throw any errors in an attempt to re-render
const bookingSummary = bookingStore.createBookingSummary();

onUnmounted(() => {
  bookingStore.$reset();
});

const handleBookAgain = () => {
  router.push({
    path: "/",
    query: route.query,
  });
};

const handleSeeProducts = () => {
  window.location.href = t("general.products");
};
</script>

<template>
  <h2 class="flex justify-center text-center my-5">
    <TextHeader1 class="text-center">{{ t('complete.header') }}</TextHeader1>
  </h2>
  <p class="flex justify-center text-center mb-10">
    <TextBody1 class="text-center">{{ t('complete.subHeader') }}</TextBody1>
  </p>
  <div class="flex justify-center mb-10">
    <Icon class="text-complete-tick" :icon="faCircleCheck" size="6x" />
  </div>
  <SectionDivider />
  <div class="m-5">
    <BookingSummary
      :locationName="bookingSummary.locationName"
      :address-one="bookingSummary.addressOne"
      :address-two="bookingSummary.addressTwo || undefined"
      :city="bookingSummary.city"
      :state="bookingSummary.state || undefined"
      :postal-code="bookingSummary.postalCode"
      :services="bookingSummary.serviceSummariesByGuest"
      :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
    />
  </div>
  <SectionDivider />
  <div class="flex flex-col sm:flex-row justify-center mt-10 gap-5">
    <div class="w-full px-10 lg:w-1/3">
      <ButtonPrimary class="w-full" @click="handleBookAgain">
        {{ t('complete.bookAgain') }}
      </ButtonPrimary>
    </div>
    <div class="w-full px-10 lg:w-1/3">
      <ButtonPrimary class="w-full" @click="handleSeeProducts">
        {{ t('complete.seeProducts') }}
      </ButtonPrimary>
    </div>
  </div>
</template>
