<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineEmits<{
  cancelled: [];
}>();

const handleSevenPlusClicked = () => {
  window.location.href = t("selectNumberOfGuests.sevenPlus.groupPackagesPage");
};
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
      <div class="w-full flex flex-col justify-center items-center">
        <ClosePopup @click="$emit('cancelled')" />
        <div class="p-10 w-full flex flex-col gap-5">
          <TextHeader1 class="mx-10 text-center">{{ t('selectNumberOfGuests.sevenPlus.title') }}</TextHeader1>
          <TextBody1 class="text-center mt-5">{{ t('selectNumberOfGuests.sevenPlus.body') }}</TextBody1>
          <div class="mt-10">
            <ButtonPrimary @click="handleSevenPlusClicked">{{ t('selectNumberOfGuests.sevenPlus.findOutMore') }}</ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
