<script setup lang="ts">
import ServiceCardImage from "@/src/assets/service-card-placeholder.jpg";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Icon from "@/src/components/Icon.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionError from "@/src/components/SectionError.vue";
import ServiceCard from "@/src/components/ServiceCard.vue";
import ServiceCardSkeleton from "@/src/components/ServiceCardSkeleton.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId, ServiceId, ServicesGetByLocationResponseItem } from "@/src/lib/townhouseApiClient";
import PopupServiceInfo from "@/src/popups/PopupServiceInfo.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();
const bookingStore = useBookingStore();
const { servicesByLocationGroupedByType, locationId, servicesByGuest, servicesByLocation, fetchServicesFailure } =
  storeToRefs(bookingStore);
const serviceForPopupServiceInfo = ref<Pick<
  ServicesGetByLocationResponseItem,
  "name" | "longDescription" | "priceCents" | "typicalDurationMins"
> | null>(null);

const route = useRoute();
const router = useRouter();

defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const services = computed(() => {
  return servicesByLocationGroupedByType.value["groupPackage"];
});

const guestsHaveService = (serviceId: ServiceId): boolean => {
  return Boolean(servicesByGuest.value[0].has(serviceId));
};

const handleServiceToggled = (serviceId: ServiceId, newValue: boolean | undefined) => {
  if (newValue === undefined) {
    return;
  }

  bookingStore.removeAllServicesFromAllGuests();

  if (!newValue) {
    return;
  }

  bookingStore.addGroupPackageToAllGuests(serviceId);
};

const handleChooseIndividuallyClicked = () => {
  if (bookingStore.isGroupPackageChosen) {
    bookingStore.removeAllServicesFromAllGuests();
  }
  return router.push({
    path: `/locations/${locationId.value}/guests`,
    query: route.query,
  });
};

const handleLearnMoreClicked = (service: ServicesGetByLocationResponseItem) => {
  serviceForPopupServiceInfo.value = {
    name: service.name,
    longDescription: service.longDescription,
    priceCents: service.priceCents,
    typicalDurationMins: service.typicalDurationMins,
  };
};

const handleTryAgain = () => {
  window.location.reload();
};
</script>

<template>
  <LocationBanner />
  <h2 class="flex flex-col justify-center my-5">
    <TextHeader1 class="text-center">{{ t('groupPackages.header') }}</TextHeader1>
  </h2>
  <div v-if="!fetchServicesFailure">
    <div class="flex justify-center mb-10">
      <ButtonInScreenTertiary class="max-w-[350px] mt-5" @click="handleChooseIndividuallyClicked">
        <div class="flex justify-between items-center px-5">
          <div>
            <TextBody1>{{ t("groupPackages.individualServices") }}</TextBody1>
          </div>
          <Icon :icon="faAngleRight" size="lg"/>
        </div>
      </ButtonInScreenTertiary>
    </div>
    <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
      <PopupServiceInfo v-if="serviceForPopupServiceInfo" @cancelled="serviceForPopupServiceInfo = null" :serviceName="serviceForPopupServiceInfo.name" :description="serviceForPopupServiceInfo.longDescription" :priceCents="serviceForPopupServiceInfo.priceCents" :durationMins="serviceForPopupServiceInfo.typicalDurationMins" />
      <div v-if="servicesByLocation" v-for="service in services" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCard @learnMore="() => handleLearnMoreClicked(service)" :modelValue="guestsHaveService(service.id)"
          @update:modelValue="(newValue: boolean | undefined) => handleServiceToggled(service.id, newValue)"
          :image="ServiceCardImage"
          :header="service.name"
          :bodyText="service.shortDescription"
          :duration="service.typicalDurationMins"
          :priceCents="service.priceCents" />
      </div>
      <div v-else v-for="_ in _.times(10)" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCardSkeleton />
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center">
    <SectionError :errorMessage="t('general.pageError.error')" :tryAgainHandler="handleTryAgain" class="max-w-[600px]" />
  </div>
</template>

