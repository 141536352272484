import type { Branded } from "@/src/lib/branded";

export const countryCodes = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CX",
  "CY",
  "CW",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TA",
  "TC",
  "TD",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
] as const;

export type CountryCode = (typeof countryCodes)[number];

export const countryCodesToCountryCallingCodeMap = {
  AD: "376",
  AE: "971",
  AF: "93",
  AG: "1-268",
  AI: "1-264",
  AL: "355",
  AM: "374",
  AO: "244",
  AR: "54",
  AS: "1-684",
  AT: "43",
  AU: "61",
  AW: "297",
  AX: "358",
  AZ: "994",
  BA: "387",
  BB: "1-246",
  BD: "880",
  BE: "32",
  BF: "226",
  BG: "359",
  BH: "973",
  BI: "257",
  BJ: "229",
  BL: "590",
  BM: "1-441",
  BN: "673",
  BO: "591",
  BQ: "599",
  BR: "55",
  BS: "1-242",
  BT: "975",
  BW: "267",
  BY: "375",
  BZ: "501",
  CA: "1",
  CC: "61",
  CD: "243",
  CF: "236",
  CG: "242",
  CH: "41",
  CI: "225",
  CK: "682",
  CL: "56",
  CM: "237",
  CN: "86",
  CO: "57",
  CR: "506",
  CU: "53",
  CV: "238",
  CX: "61",
  CY: "357",
  CW: "599",
  CZ: "420",
  DE: "49",
  DJ: "253",
  DK: "45",
  DM: "1-767",
  DO: "1-809",
  DZ: "213",
  EC: "593",
  EE: "372",
  EG: "20",
  EH: "212",
  ER: "291",
  ES: "34",
  ET: "251",
  FI: "358",
  FJ: "679",
  FK: "500",
  FM: "691",
  FO: "298",
  FR: "33",
  GA: "241",
  GB: "44",
  GD: "1-473",
  GE: "995",
  GG: "44",
  GH: "233",
  GI: "350",
  GL: "299",
  GM: "220",
  GN: "224",
  GP: "590",
  GQ: "240",
  GR: "30",
  GT: "502",
  GU: "1-671",
  GW: "245",
  GY: "592",
  HK: "852",
  HN: "504",
  HR: "385",
  HT: "509",
  HU: "36",
  ID: "62",
  IE: "353",
  IL: "972",
  IM: "44",
  IN: "91",
  IO: "246",
  IQ: "964",
  IR: "98",
  IS: "354",
  IT: "39",
  JE: "44",
  JM: "1-876",
  JO: "962",
  JP: "81",
  KE: "254",
  KG: "996",
  KH: "855",
  KI: "686",
  KM: "269",
  KN: "1-869",
  KP: "850",
  KR: "82",
  KW: "965",
  KY: "1-345",
  KZ: "7",
  LA: "856",
  LB: "961",
  LC: "1-758",
  LI: "423",
  LK: "94",
  LR: "231",
  LS: "266",
  LT: "370",
  LU: "352",
  LV: "371",
  LY: "218",
  MA: "212",
  MC: "377",
  MD: "373",
  ME: "382",
  MF: "590",
  MG: "261",
  MH: "692",
  MK: "389",
  ML: "223",
  MM: "95",
  MN: "976",
  MO: "853",
  MP: "1-670",
  MR: "222",
  MS: "1-664",
  MT: "356",
  MU: "230",
  MV: "960",
  MW: "265",
  MX: "52",
  MY: "60",
  MZ: "258",
  NA: "264",
  NC: "687",
  NE: "227",
  NF: "672",
  NG: "234",
  NI: "505",
  NL: "31",
  NO: "47",
  NP: "977",
  NR: "674",
  NU: "683",
  NZ: "64",
  OM: "968",
  PA: "507",
  PE: "51",
  PF: "689",
  PG: "675",
  PH: "63",
  PK: "92",
  PL: "48",
  PM: "508",
  PR: "1-787",
  PS: "970",
  PT: "351",
  PW: "680",
  PY: "595",
  QA: "974",
  RE: "262",
  RO: "40",
  RS: "381",
  RU: "7",
  RW: "250",
  SA: "966",
  SB: "677",
  SC: "248",
  SD: "249",
  SE: "46",
  SG: "65",
  SH: "290",
  SI: "386",
  SJ: "47",
  SK: "421",
  SL: "232",
  SM: "378",
  SN: "221",
  SO: "252",
  SR: "597",
  ST: "239",
  SV: "503",
  SX: "1-721",
  SY: "963",
  SZ: "268",
  TA: "290",
  TC: "1-649",
  TD: "235",
  TG: "228",
  TH: "66",
  TJ: "992",
  TK: "690",
  TL: "670",
  TM: "993",
  TN: "216",
  TO: "676",
  TR: "90",
  TT: "1-868",
  TV: "688",
  TW: "886",
  TZ: "255",
  UA: "380",
  UG: "256",
  US: "1",
  UY: "598",
  UZ: "998",
  VA: "379",
  VC: "1-784",
  VE: "58",
  VG: "1-284",
  VI: "1-340",
  VN: "84",
  VU: "678",
  WF: "681",
  WS: "685",
  XK: "383",
  YE: "967",
  YT: "262",
  ZA: "27",
  ZM: "260",
  ZW: "263",
} as const;

export type CountryCallingCode = (typeof countryCodesToCountryCallingCodeMap)[CountryCode];
export type PhoneNumberLengthMinimum = Branded<number, "PhoneNumberLengthMinimum">;
export type PhoneNumberLengthMaximum = Branded<number, "PhoneNumberLengthMaximum">;

export const countryCodeToZenotiPhoneNumberLengthRange: Record<
  CountryCode,
  [PhoneNumberLengthMinimum, PhoneNumberLengthMaximum] | null
> = {
  AD: [6 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  AE: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  AF: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  AG: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  AI: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  AL: [3 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  AM: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  AO: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  AR: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  AS: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  AT: [4 as PhoneNumberLengthMinimum, 13 as PhoneNumberLengthMaximum],
  AU: [4 as PhoneNumberLengthMinimum, 15 as PhoneNumberLengthMaximum],
  AW: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  AX: [5 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  AZ: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  BA: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BB: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  BD: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  BE: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  BF: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BG: [7 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  BH: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BI: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BJ: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BL: null,
  BM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  BN: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  BO: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BQ: null,
  BR: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  BS: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  BT: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BW: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  BY: [9 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  BZ: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  CA: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  CC: null,
  CD: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  CF: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  CG: [6 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  CH: [4 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  CI: null,
  CK: [5 as PhoneNumberLengthMinimum, 5 as PhoneNumberLengthMaximum],
  CL: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  CM: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  CN: [5 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  CO: [8 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  CR: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  CU: [6 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  CV: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  CX: null,
  CY: [8 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  CW: null,
  CZ: [4 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  DE: [6 as PhoneNumberLengthMinimum, 13 as PhoneNumberLengthMaximum],
  DJ: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  DK: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  DM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  DO: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  DZ: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  EC: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  EE: [7 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  EG: [7 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  EH: null,
  ER: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  ES: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  ET: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  FI: [5 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  FJ: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  FK: [5 as PhoneNumberLengthMinimum, 5 as PhoneNumberLengthMaximum],
  FM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  FO: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  FR: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  GA: [6 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  GB: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  GD: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  GE: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  GG: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  GH: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  GI: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  GL: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  GM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  GN: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  GP: null,
  GQ: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  GR: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  GT: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  GU: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  GW: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  GY: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  HK: [4 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  HN: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  HR: [8 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  HT: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  HU: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  ID: [5 as PhoneNumberLengthMinimum, 13 as PhoneNumberLengthMaximum],
  IE: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  IL: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  IM: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  IN: [1 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  IO: null,
  IQ: [8 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  IR: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  IS: [7 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  IT: [0 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  JE: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  JM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  JO: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  JP: [9 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  KE: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  KG: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  KH: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  KI: [5 as PhoneNumberLengthMinimum, 5 as PhoneNumberLengthMaximum],
  KM: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  KN: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  KP: [3 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  KR: [8 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  KW: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  KY: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  KZ: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  LA: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  LB: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  LC: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  LI: [7 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  LK: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  LR: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  LS: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  LT: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  LU: [4 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  LV: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  LY: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  MA: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  MC: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  MD: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  ME: [4 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  MF: null,
  MG: [9 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  MH: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  MK: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  ML: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MM: [7 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  MN: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MO: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MP: null,
  MR: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  MS: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  MT: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MU: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MV: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  MW: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  MX: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  MY: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  MZ: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  NA: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  NC: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  NE: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  NF: null,
  NG: [7 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  NI: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  NL: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  NO: [5 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  NP: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  NR: [4 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  NU: [4 as PhoneNumberLengthMinimum, 4 as PhoneNumberLengthMaximum],
  NZ: [3 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  OM: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  PA: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  PE: [8 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  PF: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  PG: [4 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  PH: [8 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  PK: [8 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  PL: [6 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  PM: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  PR: [7 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  PS: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  PT: [9 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  PW: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  PY: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  QA: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  RE: null,
  RO: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  RS: [4 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  RU: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  RW: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SA: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SB: [5 as PhoneNumberLengthMinimum, 5 as PhoneNumberLengthMaximum],
  SC: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  SD: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SE: [7 as PhoneNumberLengthMinimum, 13 as PhoneNumberLengthMaximum],
  SG: [8 as PhoneNumberLengthMinimum, 12 as PhoneNumberLengthMaximum],
  SH: [4 as PhoneNumberLengthMinimum, 4 as PhoneNumberLengthMaximum],
  SI: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  SJ: null,
  SK: [4 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SL: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  SM: [6 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  SN: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SO: [6 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  SR: [6 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  ST: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  SV: [7 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  SX: null,
  SY: [8 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  SZ: [7 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  TA: null,
  TC: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TD: [6 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TG: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TH: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  TJ: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  TK: [4 as PhoneNumberLengthMinimum, 4 as PhoneNumberLengthMaximum],
  TL: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TM: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  TN: [8 as PhoneNumberLengthMinimum, 8 as PhoneNumberLengthMaximum],
  TO: [5 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TR: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  TT: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  TV: [5 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  TW: [8 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  TZ: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  UA: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  UG: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  US: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  UY: [4 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  UZ: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  VA: [6 as PhoneNumberLengthMinimum, 11 as PhoneNumberLengthMaximum],
  VC: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  VE: [10 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  VG: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  VI: [7 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  VN: [7 as PhoneNumberLengthMinimum, 10 as PhoneNumberLengthMaximum],
  VU: [5 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  WF: [6 as PhoneNumberLengthMinimum, 6 as PhoneNumberLengthMaximum],
  WS: [3 as PhoneNumberLengthMinimum, 7 as PhoneNumberLengthMaximum],
  XK: null,
  YE: [6 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  YT: null,
  ZA: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  ZM: [9 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
  ZW: [5 as PhoneNumberLengthMinimum, 9 as PhoneNumberLengthMaximum],
};

export type ZenotiCountryId = Branded<number, "ZenotiCountryId">;

export const countryCodesToZenotiCountryIdMap: Record<CountryCode, ZenotiCountryId | null> = {
  AD: 5 as ZenotiCountryId,
  AE: 223 as ZenotiCountryId,
  AF: 1 as ZenotiCountryId,
  AG: 9 as ZenotiCountryId,
  AI: 7 as ZenotiCountryId,
  AL: 2 as ZenotiCountryId,
  AM: 11 as ZenotiCountryId,
  AO: 6 as ZenotiCountryId,
  AR: 10 as ZenotiCountryId,
  AS: 4 as ZenotiCountryId,
  AT: 14 as ZenotiCountryId,
  AU: 13 as ZenotiCountryId,
  AW: 12 as ZenotiCountryId,
  AX: 71 as ZenotiCountryId,
  AZ: 15 as ZenotiCountryId,
  BA: 27 as ZenotiCountryId,
  BB: 19 as ZenotiCountryId,
  BD: 18 as ZenotiCountryId,
  BE: 21 as ZenotiCountryId,
  BF: 34 as ZenotiCountryId,
  BG: 33 as ZenotiCountryId,
  BH: 17 as ZenotiCountryId,
  BI: 36 as ZenotiCountryId,
  BJ: 23 as ZenotiCountryId,
  BL: 176 as ZenotiCountryId,
  BM: 24 as ZenotiCountryId,
  BN: 32 as ZenotiCountryId,
  BO: 26 as ZenotiCountryId,
  BQ: 148 as ZenotiCountryId,
  BR: 29 as ZenotiCountryId,
  BS: 16 as ZenotiCountryId,
  BT: 25 as ZenotiCountryId,
  BW: 28 as ZenotiCountryId,
  BY: 20 as ZenotiCountryId,
  BZ: 22 as ZenotiCountryId,
  CA: 39 as ZenotiCountryId,
  CC: 47 as ZenotiCountryId,
  CD: 56 as ZenotiCountryId,
  CF: 42 as ZenotiCountryId,
  CG: 172 as ZenotiCountryId,
  CH: 205 as ZenotiCountryId,
  CI: 103 as ZenotiCountryId,
  CK: 50 as ZenotiCountryId,
  CL: 44 as ZenotiCountryId,
  CM: 38 as ZenotiCountryId,
  CN: 45 as ZenotiCountryId,
  CO: 48 as ZenotiCountryId,
  CR: 51 as ZenotiCountryId,
  CU: 53 as ZenotiCountryId,
  CV: 40 as ZenotiCountryId,
  CX: 46 as ZenotiCountryId,
  CY: 54 as ZenotiCountryId,
  CW: 148 as ZenotiCountryId,
  CZ: 55 as ZenotiCountryId,
  DE: 78 as ZenotiCountryId,
  DJ: 58 as ZenotiCountryId,
  DK: 57 as ZenotiCountryId,
  DM: 59 as ZenotiCountryId,
  DO: 60 as ZenotiCountryId,
  DZ: 3 as ZenotiCountryId,
  EC: 61 as ZenotiCountryId,
  EE: 66 as ZenotiCountryId,
  EG: 62 as ZenotiCountryId,
  EH: 234 as ZenotiCountryId,
  ER: 65 as ZenotiCountryId,
  ES: 198 as ZenotiCountryId,
  ET: 67 as ZenotiCountryId,
  FI: 71 as ZenotiCountryId,
  FJ: 70 as ZenotiCountryId,
  FK: 68 as ZenotiCountryId,
  FM: 136 as ZenotiCountryId,
  FO: 69 as ZenotiCountryId,
  FR: 72 as ZenotiCountryId,
  GA: 74 as ZenotiCountryId,
  GB: 224 as ZenotiCountryId,
  GD: 83 as ZenotiCountryId,
  GE: 77 as ZenotiCountryId,
  GG: 224 as ZenotiCountryId,
  GH: 79 as ZenotiCountryId,
  GI: 80 as ZenotiCountryId,
  GL: 82 as ZenotiCountryId,
  GM: 75 as ZenotiCountryId,
  GN: 86 as ZenotiCountryId,
  GP: 176 as ZenotiCountryId,
  GQ: 64 as ZenotiCountryId,
  GR: 81 as ZenotiCountryId,
  GT: 85 as ZenotiCountryId,
  GU: 84 as ZenotiCountryId,
  GW: 87 as ZenotiCountryId,
  GY: 88 as ZenotiCountryId,
  HK: 92 as ZenotiCountryId,
  HN: 91 as ZenotiCountryId,
  HR: 52 as ZenotiCountryId,
  HT: 89 as ZenotiCountryId,
  HU: 93 as ZenotiCountryId,
  ID: 96 as ZenotiCountryId,
  IE: 99 as ZenotiCountryId,
  IL: 101 as ZenotiCountryId,
  IM: 100 as ZenotiCountryId,
  IN: 95 as ZenotiCountryId,
  IO: 30 as ZenotiCountryId,
  IQ: 98 as ZenotiCountryId,
  IR: 97 as ZenotiCountryId,
  IS: 94 as ZenotiCountryId,
  IT: 102 as ZenotiCountryId,
  JE: 106 as ZenotiCountryId,
  JM: 104 as ZenotiCountryId,
  JO: 107 as ZenotiCountryId,
  JP: 105 as ZenotiCountryId,
  KE: 109 as ZenotiCountryId,
  KG: 113 as ZenotiCountryId,
  KH: 37 as ZenotiCountryId,
  KI: 110 as ZenotiCountryId,
  KM: 49 as ZenotiCountryId,
  KN: 178 as ZenotiCountryId,
  KP: 156 as ZenotiCountryId,
  KR: 197 as ZenotiCountryId,
  KW: 112 as ZenotiCountryId,
  KY: 41 as ZenotiCountryId,
  KZ: 108 as ZenotiCountryId,
  LA: 114 as ZenotiCountryId,
  LB: 116 as ZenotiCountryId,
  LC: 179 as ZenotiCountryId,
  LI: 120 as ZenotiCountryId,
  LK: 199 as ZenotiCountryId,
  LR: 118 as ZenotiCountryId,
  LS: 117 as ZenotiCountryId,
  LT: 121 as ZenotiCountryId,
  LU: 122 as ZenotiCountryId,
  LV: 115 as ZenotiCountryId,
  LY: 119 as ZenotiCountryId,
  MA: 142 as ZenotiCountryId,
  MC: 138 as ZenotiCountryId,
  MD: 137 as ZenotiCountryId,
  ME: 140 as ZenotiCountryId,
  MF: 180 as ZenotiCountryId,
  MG: 125 as ZenotiCountryId,
  MH: 131 as ZenotiCountryId,
  MK: 124 as ZenotiCountryId,
  ML: 129 as ZenotiCountryId,
  MM: 35 as ZenotiCountryId,
  MN: 139 as ZenotiCountryId,
  MO: 123 as ZenotiCountryId,
  MP: 157 as ZenotiCountryId,
  MR: 132 as ZenotiCountryId,
  MS: 141 as ZenotiCountryId,
  MT: 130 as ZenotiCountryId,
  MU: 133 as ZenotiCountryId,
  MV: 128 as ZenotiCountryId,
  MW: 126 as ZenotiCountryId,
  MX: 135 as ZenotiCountryId,
  MY: 127 as ZenotiCountryId,
  MZ: 143 as ZenotiCountryId,
  NA: 144 as ZenotiCountryId,
  NC: 149 as ZenotiCountryId,
  NE: 152 as ZenotiCountryId,
  NF: 155 as ZenotiCountryId,
  NG: 153 as ZenotiCountryId,
  NI: 151 as ZenotiCountryId,
  NL: 147 as ZenotiCountryId,
  NO: 158 as ZenotiCountryId,
  NP: 146 as ZenotiCountryId,
  NR: 145 as ZenotiCountryId,
  NU: 154 as ZenotiCountryId,
  NZ: 150 as ZenotiCountryId,
  OM: 159 as ZenotiCountryId,
  PA: 162 as ZenotiCountryId,
  PE: 165 as ZenotiCountryId,
  PF: 73 as ZenotiCountryId,
  PG: 163 as ZenotiCountryId,
  PH: 166 as ZenotiCountryId,
  PK: 160 as ZenotiCountryId,
  PL: 168 as ZenotiCountryId,
  PM: 181 as ZenotiCountryId,
  PR: 170 as ZenotiCountryId,
  PS: 238 as ZenotiCountryId,
  PT: 169 as ZenotiCountryId,
  PW: 161 as ZenotiCountryId,
  PY: 164 as ZenotiCountryId,
  QA: 171 as ZenotiCountryId,
  RE: 134 as ZenotiCountryId,
  RO: 173 as ZenotiCountryId,
  RS: 188 as ZenotiCountryId,
  RU: 174 as ZenotiCountryId,
  RW: 175 as ZenotiCountryId,
  SA: 186 as ZenotiCountryId,
  SB: 194 as ZenotiCountryId,
  SC: 189 as ZenotiCountryId,
  SD: 200 as ZenotiCountryId,
  SE: 204 as ZenotiCountryId,
  SG: 191 as ZenotiCountryId,
  SH: 177 as ZenotiCountryId,
  SI: 193 as ZenotiCountryId,
  SJ: 158 as ZenotiCountryId,
  SK: 192 as ZenotiCountryId,
  SL: 190 as ZenotiCountryId,
  SM: 184 as ZenotiCountryId,
  SN: 187 as ZenotiCountryId,
  SO: 195 as ZenotiCountryId,
  SR: 201 as ZenotiCountryId,
  ST: 185 as ZenotiCountryId,
  SV: 63 as ZenotiCountryId,
  SX: 148 as ZenotiCountryId,
  SY: 206 as ZenotiCountryId,
  SZ: 203 as ZenotiCountryId,
  TA: 177 as ZenotiCountryId,
  TC: 219 as ZenotiCountryId,
  TD: 43 as ZenotiCountryId,
  TG: 212 as ZenotiCountryId,
  TH: 210 as ZenotiCountryId,
  TJ: 208 as ZenotiCountryId,
  TK: 213 as ZenotiCountryId,
  TL: 211 as ZenotiCountryId,
  TM: 218 as ZenotiCountryId,
  TN: 216 as ZenotiCountryId,
  TO: 214 as ZenotiCountryId,
  TR: 217 as ZenotiCountryId,
  TT: 215 as ZenotiCountryId,
  TV: 220 as ZenotiCountryId,
  TW: 207 as ZenotiCountryId,
  TZ: 209 as ZenotiCountryId,
  UA: 222 as ZenotiCountryId,
  UG: 221 as ZenotiCountryId,
  US: 225 as ZenotiCountryId,
  UY: 226 as ZenotiCountryId,
  UZ: 228 as ZenotiCountryId,
  VA: 90 as ZenotiCountryId,
  VC: 182 as ZenotiCountryId,
  VE: 230 as ZenotiCountryId,
  VG: 31 as ZenotiCountryId,
  VI: 227 as ZenotiCountryId,
  VN: 231 as ZenotiCountryId,
  VU: 229 as ZenotiCountryId,
  WF: 232 as ZenotiCountryId,
  WS: 183 as ZenotiCountryId,
  XK: 111 as ZenotiCountryId,
  YE: 235 as ZenotiCountryId,
  YT: 134 as ZenotiCountryId,
  ZA: 196 as ZenotiCountryId,
  ZM: 236 as ZenotiCountryId,
  ZW: 237 as ZenotiCountryId,
};
