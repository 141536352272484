
<script setup lang="ts">
import TextBody1 from "@/src/components/TextBody1.vue";
import TextListItem1 from "@/src/components/TextListItem1.vue";
import TextListItem2 from "@/src/components/TextListItem2.vue";
import { type ServiceSummary, isParallelServiceSummary, isSingleServiceSummary } from "@/src/stores/bookingStore";
import { useI18n } from "vue-i18n";

defineProps<{
  services: ServiceSummary[][];
  disabled?: boolean;
}>();

const { t } = useI18n();
</script>

<template>
  <template v-for="(serviceSummary, guestIndex) in services">
    <div v-if="services.length > 1" :class="{ 'mt-5': guestIndex > 0 }">
      <TextBody1 :class="{ 'text-text-disabled': disabled }">{{ t('general.guestLabel', { guestNumber: guestIndex + 1 }) }}</TextBody1>
    </div>
    <template v-for="(service) in serviceSummary">
      <template v-if="isSingleServiceSummary(service)">
        <TextBody1 class="block" :class="{ 'text-text-disabled': disabled }">{{ service.serviceName }}</TextBody1>
        <template v-for="removal in service.removalNames">
          <TextListItem2 class="block" :disabled="disabled">{{ removal }}</TextListItem2>
        </template>
        <template v-for="addOn in service.addOnNames">
          <TextListItem2 class="block" :disabled="disabled">{{ addOn }}</TextListItem2>
        </template>
      </template>
      <template v-if="isParallelServiceSummary(service)">
        <TextBody1 class="block" :class="{ 'text-text-disabled': disabled }">{{ t("bookingSummary.headers.maniPedi") }}</TextBody1>
        <TextListItem1 class="block" :class="{ 'text-text-disabled': disabled }">{{ service.serviceOne.serviceName }}</TextListItem1>
        <template v-for="removal in service.serviceOne.removalNames">
          <TextListItem2 class="block" :disabled="disabled">{{ removal }}</TextListItem2>
        </template>
        <template v-for="addOn in service.serviceOne.addOnNames">
          <TextListItem2 class="block" :disabled="disabled">{{ addOn }}</TextListItem2>
        </template>
        <TextListItem1 class="block" :class="{ 'text-text-disabled': disabled }">{{ service.serviceTwo.serviceName }}</TextListItem1>
        <template v-for="removal in service.serviceTwo.removalNames">
          <TextListItem2 class="block" :disabled="disabled">{{ removal }}</TextListItem2>
        </template>
        <template v-for="addOn in service.serviceTwo.addOnNames">
          <TextListItem2 class="block" :disabled="disabled">{{ addOn }}</TextListItem2>
        </template>
      </template>
    </template>
  </template>
</template>
