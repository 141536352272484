<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import type { CountryCode } from "@/src/lib/countryCode";
import { countryCodes, countryCodesToCountryCallingCodeMap } from "@/src/lib/countryCode";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const model = defineModel<CountryCode>();

defineProps<{
  label: string;
  disabled?: boolean;
}>();
</script>

<template>
  <div class="h-fit">
    <label :class="{ 'text-text-disabled': disabled }">
      <TextHeader3 class="block">
        {{ label }}
      </TextHeader3>
      <div class="relative">
        <select aria-required :disabled="disabled" :class="`w-full appearance-none rounded-none border-2 border-input-border text-base p-5 pr-10 focus:outline-hidden focus:border-2 focus:border-input-focus-outline ${disabled ? 'border-input-disabled cursor-not-allowed' : 'cursor-pointer'}`" v-model="model">
          <option v-for="countryCode in countryCodes" :key="countryCode" :value="countryCode" >
            {{ `${countryCode} +${countryCodesToCountryCallingCodeMap[countryCode]}` }}
          </option>
        </select>
        <Icon :icon="faChevronDown" class="absolute right-5 top-[calc(50%-0.75rem)] pointer-events-none" size="xl" />
      </div>
    </label>
  </div>
</template>
