<script setup lang="ts">
import logo from "@/src/assets/logo.svg";
import ButtonIcon from "@/src/components/ButtonIcon.vue";
import Icon from "@/src/components/Icon.vue";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  userFirstName: string | null;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  mainMenu: [];
  account: [];
}>();

const handleMainMenu = () => {
  if (!props.disabled) {
    emits("mainMenu");
  }
};

const handleAccount = () => {
  if (!props.disabled) {
    emits("account");
  }
};
</script>

<template>
  <div class="flex justify-between items-center p-5">
    <ButtonIcon 
      aria-haspopup="menu" 
      @click="handleMainMenu" 
      :icon="faBars" 
      size="3x"
      :disabled="disabled"
      :class="`${disabled ? 'text-header-icon-disabled' : 'text-header-icon hover:text-header-icon-hover'}`"
    />
    <div class="w-[300px] grow-0 shrink-0">
      <a :href="disabled ? undefined : t('general.mainWebsite')" :class="`${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`">
        <img :src="logo" alt="Company Logo" />
      </a>
    </div>
    <div class="w-12">
      <div role="button" @click="handleAccount" :class="`${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`">
        <template v-if="userFirstName">
          <div class="w-[30px] h-[30px] rounded-full bg-account-logged-in hover:bg-account-logged-in-hover flex items-center justify-center">
            <span class="text-white text-lg uppercase">{{ userFirstName[0] }}</span>
          </div>
        </template>
        <Icon v-else :icon="faCircleUser" size="3x" class="text-gray-300"></Icon>
      </div>
    </div>
  </div>
</template>
