<script setup lang="ts">
import ButtonPlaintextPrimary from "@/src/components/ButtonPlaintextPrimary.vue";
import ButtonPlaintextSecondary from "@/src/components/ButtonPlaintextSecondary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import InputEmail from "@/src/components/InputEmail.vue";
import InputPassword from "@/src/components/InputPassword.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { validateEmail, validatePassword } from "@/src/lib/formValidationHelpers";
import type { Email, Password } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const authStore = useAuthStore();

const { isLoggingIn } = storeToRefs(authStore);

const emits = defineEmits<{
  clickedCreateAccount: [];
  clickedForgottenPassword: [];
}>();

const email = ref<string>("");
const password = ref<string>("");

const formError = ref<string | undefined>();
const emailError = ref<string | undefined>();
const passwordError = ref<string | undefined>();

const login = async () => {
  if (isLoggingIn.value) {
    return;
  }

  formError.value = undefined;
  emailError.value = undefined;
  passwordError.value = undefined;

  validateEmail({ email: email.value, errorRef: emailError, errorMessage: t("login.emailInvalid") });
  validatePassword({
    password: password.value,
    errorRef: passwordError,
    errorMessage: t("login.passwordInvalid"),
  });

  if (emailError.value || passwordError.value) {
    return;
  }

  try {
    await authStore.login({
      email: email.value as Email,
      password: password.value as Password,
    });
  } catch (e) {
    formError.value = t("login.errored");
  }
};

const handleForgottenPasswordClick = () => {
  if (!isLoggingIn.value) {
    emits("clickedForgottenPassword");

    authStore.analytics.track("Reset Password Shown", {});
  }
};

const handleCreateAccountClick = () => {
  if (!isLoggingIn.value) {
    emits("clickedCreateAccount");

    authStore.analytics.track("Create Account Shown", {});
  }
};
</script>

<template>
  <form novalidate @submit.prevent="login" class="p-10 flex flex-col gap-5 w-full">
    <TextHeader1 class="mb-10 text-center" :class="{ 'text-text-disabled': isLoggingIn }">{{ t('login.header') }}</TextHeader1>
    <TextBodyError v-if="formError" role="alert">
      <TextBody1>{{ formError }}</TextBody1>
    </TextBodyError>
    <InputEmail :disabled="isLoggingIn" v-model="email" label="Email" required :error="emailError"></InputEmail>
    <InputPassword :disabled="isLoggingIn" v-model="password" label="Password" required :error="passwordError" autocomplete="current-password"></InputPassword>
    <ButtonPrimary :submitting="isLoggingIn"  button-type="submit" class="mt-10">
      {{ t('login.submit') }}
    </ButtonPrimary>
    <ButtonPlaintextSecondary :disabled="isLoggingIn" @click="handleForgottenPasswordClick">
      <TextBody1>
        {{ t('login.forgottenPassword') }}
      </TextBody1>
    </ButtonPlaintextSecondary>
    <ButtonPlaintextPrimary :disabled="isLoggingIn" @click="handleCreateAccountClick">
      <TextHeader3>{{ t('login.createAccount') }}</TextHeader3>
    </ButtonPlaintextPrimary>
  </form>
</template>

