import type {
  BookingId,
  BookingWithFullInfo,
  Email,
  Password,
  PaymentAccount,
  UserGetLoyaltyPointsResponse,
  UserGetPaymentAccountsByLocationResponse,
  UserGetResponse,
  ZenotiGuestId,
  ZenotiPaymentAccountId,
  ZenotiVerificationCode,
  ZenotiVerificationId,
} from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import { defineStore } from "pinia";

export type UserData = UserGetResponse;

export type UserState = {
  user: UserData | null;
  isUpdatingUserDetails: boolean;
  passwordReset: {
    verificationId: ZenotiVerificationId | null;
    guestId: ZenotiGuestId | null;
    email: Email | null;
  };
  isResettingPassword: boolean;
  isCompletingResettingPassword: boolean;
  isFetchingPaymentAccounts: boolean;
  userFutureBookings: BookingWithFullInfo[] | null;
  userBooking: BookingWithFullInfo | null;
  userLoyaltyPoints: UserGetLoyaltyPointsResponse | null;
  startCreatePaymentAccountUrl: string | null;
  paymentAccounts: UserGetPaymentAccountsByLocationResponse | null;
  paymentAccountChosen: PaymentAccount | null;
  // We have to omit 'cause: unknown' because Pinia's _DeepPartial can't recursively use
  // Partial<T> on 'unknown' types
  cancelBookingFailure: Omit<Error, "cause"> | null;
  fetchUserBookingFailure: Omit<Error, "cause"> | null;
  fetchUserFailure: Omit<Error, "cause"> | null;
  fetchLoyaltyPointsFailure: Omit<Error, "cause"> | null;
  startCreatePaymentAccountFailure: Omit<Error, "cause"> | null;
  fetchPaymentAccountsFailure: Omit<Error, "cause"> | null;
  deletePaymentAccountFailure: Omit<Error, "cause"> | null;
};

export const isUserState = (state: unknown): state is UserState => {
  return typeof state === "object" && state !== null && "user" in state;
};

export class UserStoreInvalidUserAuthError extends Error {}

export const useUserStore = defineStore("user", {
  state: (): UserState => {
    return {
      user: null,
      isUpdatingUserDetails: false,
      passwordReset: {
        verificationId: null,
        guestId: null,
        email: null,
      },
      isResettingPassword: false,
      isCompletingResettingPassword: false,
      isFetchingPaymentAccounts: false,
      userFutureBookings: null,
      userBooking: null,
      userLoyaltyPoints: null,
      startCreatePaymentAccountUrl: null,
      paymentAccounts: null,
      paymentAccountChosen: null,
      cancelBookingFailure: null,
      fetchUserBookingFailure: null,
      fetchUserFailure: null,
      fetchLoyaltyPointsFailure: null,
      startCreatePaymentAccountFailure: null,
      fetchPaymentAccountsFailure: null,
      deletePaymentAccountFailure: null,
    };
  },
  actions: {
    async fetchUser(): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        user: null,
        fetchUserFailure: null,
      });

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError("Fetching user details requires the user to be authenticated");
        this.$patch({
          fetchUserFailure: error,
        });

        throw error;
      }

      try {
        const user = await authStore.townhouseApiClient.userGet(authStore.userId);

        this.$patch({
          user,
          fetchUserFailure: null,
        });
      } catch (e) {
        if (e instanceof Error) {
          this.$patch({
            fetchUserFailure: e,
          });
        }

        throw e;
      }
    },

    async fetchUserFutureBookings(): Promise<void> {
      this.$patch({
        userFutureBookings: null,
      });

      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new UserStoreInvalidUserAuthError("Fetching future user bookings requires the user to be authenticated");
      }

      const userFutureBookings = await authStore.townhouseApiClient.userGetFutureBookings(authStore.userId);

      this.$patch({
        userFutureBookings,
      });
    },

    async fetchUserBooking(bookingId: BookingId): Promise<void> {
      this.$patch({
        userBooking: null,
        fetchUserBookingFailure: null,
      });

      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError(
          "Fetching a user's booking requires the user to be authenticated",
        );

        this.$patch({
          fetchUserBookingFailure: error,
        });

        throw error;
      }

      try {
        const userBooking = await authStore.townhouseApiClient.userGetBooking(authStore.userId, bookingId);

        this.$patch({
          userBooking,
        });
      } catch (e) {
        this.$patch({
          fetchUserBookingFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    async cancelBooking(bookingId: BookingId): Promise<void> {
      this.$patch({
        cancelBookingFailure: null,
      });

      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError(
          "Cancelling a user's booking requires the user to be authenticated",
        );

        this.$patch({
          cancelBookingFailure: error,
        });

        throw error;
      }

      try {
        await authStore.townhouseApiClient.cancelBooking({
          bookingId,
          userId: authStore.userId,
        });

        authStore.analytics.track("Booking Cancelled Successfully", {
          bookingId,
        });
      } catch (e) {
        this.$patch({
          cancelBookingFailure: e instanceof Error ? e : null,
        });

        authStore.analytics.track("Booking Cancellation Failed", {
          bookingId,
        });

        throw e;
      }
    },

    async fetchUserLoyaltyPoints(): Promise<void> {
      this.$patch({
        userLoyaltyPoints: null,
        fetchLoyaltyPointsFailure: null,
      });

      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError(
          "Fetching user loyalty points requires the user to be authenticated",
        );

        this.$patch({
          fetchLoyaltyPointsFailure: error,
        });

        throw error;
      }

      try {
        const userLoyaltyPoints = await authStore.townhouseApiClient.userGetLoyaltyPoints(authStore.userId);

        this.$patch({
          userLoyaltyPoints,
        });
      } catch (e) {
        if (e instanceof Error) {
          this.$patch({
            fetchLoyaltyPointsFailure: e,
          });
        }

        throw e;
      }
    },

    async updateUser(details: Partial<Omit<UserData, "id">>): Promise<void> {
      this.$patch({
        isUpdatingUserDetails: true,
      });

      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        this.$patch({
          isUpdatingUserDetails: false,
        });
        throw new UserStoreInvalidUserAuthError("Updating user details requires the user to be authenticated");
      }

      try {
        await authStore.townhouseApiClient.userUpdate(authStore.userId, {
          firstName: details.firstName || undefined,
          lastName: details.lastName || undefined,
          dateOfBirthDateUtc: details.dateOfBirthUtc || undefined,
          phone: details.phoneNumber
            ? {
                countryCode: details.phoneNumber.countryCode,
                nationalNumber: details.phoneNumber.nationalNumber,
              }
            : undefined,
          marketingEmailOptIn: details.marketingEmailOptIn ?? undefined,
          marketingSmsOptIn: details.marketingSmsOptIn ?? undefined,
        });

        authStore.analytics.track("Account Details Updated Successfully", {});
      } catch (e) {
        this.$patch({
          isUpdatingUserDetails: false,
        });
        authStore.analytics.track("Account Details Update Failed", {});
        throw e;
      }

      authStore.setUserName(details.firstName, details.lastName);

      this.$patch({
        user: details,
        isUpdatingUserDetails: false,
      });
    },

    async resetPassword(email: Email): Promise<void> {
      const authStore = useAuthStore();

      authStore.analytics.track("Password Reset Started", {});

      this.$patch({
        passwordReset: {
          verificationId: null,
          email: null,
          guestId: null,
        },
        isResettingPassword: true,
      });

      try {
        const { verificationId, guestId } = await authStore.townhouseApiClient.userResetPassword(email);

        this.$patch({
          passwordReset: {
            verificationId,
            guestId,
            email,
          },
          isResettingPassword: false,
        });
      } catch (e) {
        this.$patch({
          isResettingPassword: false,
        });

        authStore.analytics.track("Password Reset Failed", {});

        throw e;
      }
    },

    async completeResetPassword(verificationCode: ZenotiVerificationCode, newPassword: Password): Promise<void> {
      this.$patch({
        isCompletingResettingPassword: true,
      });

      const authStore = useAuthStore();

      try {
        await authStore.townhouseApiClient.userCompleteResetPassword(
          this.passwordReset.guestId as ZenotiGuestId,
          this.passwordReset.verificationId as ZenotiVerificationId,
          verificationCode,
          newPassword,
        );
      } catch (e) {
        this.$patch({
          isCompletingResettingPassword: false,
        });

        throw e;
      }

      this.$patch({
        passwordReset: {
          verificationId: null,
          email: null,
          guestId: null,
        },
        isCompletingResettingPassword: false,
      });

      authStore.analytics.track("Password Reset Completed", {});
    },

    async fetchPaymentAccounts(): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        fetchPaymentAccountsFailure: null,
        isFetchingPaymentAccounts: true,
      });

      if (this.paymentAccounts && this.paymentAccounts.length > 0) {
        this.$patch({
          isFetchingPaymentAccounts: false,
        });

        return;
      }

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError("Unable to fetch payment accounts as there is no user set");

        this.$patch({
          fetchPaymentAccountsFailure: error,
          isFetchingPaymentAccounts: false,
        });

        throw error;
      }

      try {
        const paymentAccounts = await authStore.townhouseApiClient.userGetPaymentAccounts(authStore.userId);

        this.$patch({
          paymentAccounts,
          paymentAccountChosen: paymentAccounts.length > 0 ? paymentAccounts[0] : null,
          isFetchingPaymentAccounts: false,
        });
      } catch (e) {
        this.$patch({
          fetchPaymentAccountsFailure: e instanceof Error ? e : null,
          isFetchingPaymentAccounts: false,
        });

        throw e;
      }
    },

    async refetchPaymentAccounts(): Promise<void> {
      this.$patch({
        paymentAccounts: null,
        paymentAccountChosen: null,
      });

      await this.fetchPaymentAccounts();
    },

    setChosenPaymentAccount(chosenPaymentAccount: PaymentAccount) {
      const authStore = useAuthStore();

      if (!(authStore.isLoggedIn && authStore.userId)) {
        throw new UserStoreInvalidUserAuthError("Unable to set chosen payment account as there is no user set");
      }

      this.$patch({
        paymentAccountChosen: chosenPaymentAccount,
      });
    },

    async startCreatePaymentAccount(): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        startCreatePaymentAccountFailure: null,
        startCreatePaymentAccountUrl: null,
      });

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError("Unable to create payment account as there is no user set");

        this.$patch({
          startCreatePaymentAccountFailure: error,
        });

        throw error;
      }

      try {
        const createPaymentAccountResponse = await authStore.townhouseApiClient.userCreatePaymentAccount(
          authStore.userId,
        );

        this.$patch({
          startCreatePaymentAccountUrl: createPaymentAccountResponse.url.toString(),
        });
      } catch (e) {
        this.$patch({
          startCreatePaymentAccountFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    clearPaymentAccountUrl() {
      this.$patch({
        startCreatePaymentAccountFailure: null,
        startCreatePaymentAccountUrl: null,
      });
    },

    async deletePaymentAccount(paymentAccountId: ZenotiPaymentAccountId): Promise<void> {
      const authStore = useAuthStore();

      this.$patch({
        deletePaymentAccountFailure: null,
      });

      if (!(authStore.isLoggedIn && authStore.userId)) {
        const error = new UserStoreInvalidUserAuthError("Unable to delete payment account as there is no user set");

        this.$patch({
          deletePaymentAccountFailure: error,
        });

        throw error;
      }

      try {
        await authStore.townhouseApiClient.userDeletePaymentAccount(authStore.userId, paymentAccountId);
      } catch (e) {
        this.$patch({
          deletePaymentAccountFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },
  },
});
