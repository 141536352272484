<script setup lang="ts">
import TextBody1 from "@/src/components/TextBody1.vue";

const { disabledStyles = "bg-button-disabled cursor-not-allowed", disabled } = defineProps<{
  selected?: boolean;
  disabled?: boolean;
  disabledStyles?: string;
}>();
const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button
  :class="`block w-full p-5 ${disabled ? disabledStyles : `cursor-pointer ${selected ? 'bg-button-primary' : 'bg-button-in-screen-primary hover:bg-button-in-screen-primary-hover'}`}`"
  @click.stop="handleClick"
  role="button"
  :disabled="disabled"
  >
    <TextBody1 :class="`${disabled ? 'text-button-text-disabled' : ''}`">
      <slot />
    </TextBody1>
  </button>
</template>
