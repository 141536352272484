<script setup lang="ts">
import BookingProgress from "@/src/components/BookingProgress.vue";
import Header from "@/src/components/Header.vue";
import MainMenu from "@/src/components/MainMenu.vue";
import NavigationBar from "@/src/components/NavigationBar.vue";
import type { BookingProgressStage, BookingStage } from "@/src/config/stages";
import type { ServiceId } from "@/src/lib/townhouseApiClient";
import PopupAuth from "@/src/popups/PopupAuth.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingRescheduleStore } from "@/src/stores/bookingRescheduleStore";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
import { DateTime } from "luxon";
import { MutationType, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const bookingStore = useBookingStore();
const userStore = useUserStore();
const bookingRescheduleStore = useBookingRescheduleStore();

const { isLoggedIn } = storeToRefs(authStore);
const { isRescheduling } = storeToRefs(bookingRescheduleStore);
const {
  locationId,
  isReservingBooking,
  isConfirmingBooking,
  reserveBookingFailure,
  servicesByLocation,
  isPreparingForCompleteStage,
} = storeToRefs(bookingStore);
const { isFetchingPaymentAccounts, fetchPaymentAccountsFailure } = storeToRefs(userStore);

const mainMenuVisible = ref<boolean>(false);
const currentGuestNumber = ref<GuestNumber>();
const currentServiceId = ref<ServiceId>();
const showAuthPopup = ref<boolean>(false);

const isNotFound = computed<boolean>(() => Boolean(route.meta["notFound"]));
const currentStage = ref<BookingStage>("location");
const previousStage = ref<BookingStage>("location");
const currentProgressStage = ref<BookingProgressStage>("location");

bookingStore.$subscribe((mutation) => {
  if (
    mutation.type === MutationType.direct &&
    mutation.events &&
    "key" in mutation.events &&
    mutation.events.key === "updatedAt"
  ) {
    return;
  }

  if (mutation.type === MutationType.patchObject && "updatedAt" in mutation.payload) {
    return;
  }

  bookingStore.setUpdatedAt();
});

const isPreparingForConfirmStage = computed(
  () =>
    (isReservingBooking.value || isFetchingPaymentAccounts.value) &&
    !fetchPaymentAccountsFailure.value &&
    !reserveBookingFailure.value,
);

const sectionTransitionName = computed(() => {
  if (currentStage.value === "dateTime" && !authStore.isLoggedIn) {
    return "section-popup-transition";
  }

  return "section-transition";
});

const mainMenuItems = [
  {
    label: t("popup.mainMenu.faq"),
    href: t("general.faq"),
  },
  {
    label: t("popup.mainMenu.help"),
    href: t("general.help"),
  },
  {
    label: t("popup.mainMenu.termsAndConditions"),
    href: t("general.termsAndConditions"),
  },
  {
    label: t("popup.mainMenu.privacyPolicy"),
    href: t("general.privacyPolicy"),
  },
];

watch(router.currentRoute, (newRoute) => {
  previousStage.value = currentStage.value;
  currentStage.value = newRoute.meta["stage"] as BookingStage;
  currentProgressStage.value = newRoute.meta["progressStage"] as BookingProgressStage;

  if ("guestNumber" in newRoute.params) {
    currentGuestNumber.value = newRoute.params["guestNumber"] as unknown as GuestNumber;
  }

  if ("serviceId" in newRoute.params) {
    currentServiceId.value = newRoute.params["serviceId"] as ServiceId;
  }
});

watch(isLoggedIn, async (newIsLoggedIn) => {
  showAuthPopup.value = false;

  if (newIsLoggedIn && bookingStore.isReservingBooking) {
    await Promise.all([bookingStore.reserveBooking(), userStore.fetchPaymentAccounts()]);

    router.push({
      path: `/locations/${bookingStore.locationId}/confirm`,
      query: route.query,
    });
  }
});

const handleAccountClick = () => {
  if (isLoggedIn.value) {
    return router.push({
      path: "/account",
      query: {
        ...route.query,
        returnTo: route.path,
      },
    });
  }

  // Don't do anything if we're already on the auth pages
  if (currentStage.value === "auth") {
    return;
  }

  showAuthPopup.value = true;
  authStore.analytics.track("Log In Shown", {});
};

const handleAuthCancelled = () => {
  showAuthPopup.value = false;
  bookingStore.setIsReservingBooking(false);
};

const handleMainMenuToggled = () => {
  mainMenuVisible.value = !mainMenuVisible.value;
};

const handleReserve = async () => {
  if (!authStore.isLoggedIn) {
    bookingStore.setIsReservingBooking(true);
    showAuthPopup.value = true;
    authStore.analytics.track("Log In Shown", {});
    return false;
  }

  await Promise.all([bookingStore.reserveBooking(), userStore.fetchPaymentAccounts()]);

  return true;
};

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Many route transitions to handle
const handleBackClick = () => {
  switch (currentStage.value) {
    case "numberOfGuests":
      return router.push({
        path: "/",
        query: route.query,
      });
    case "guests": {
      if (bookingStore.numberOfGuests >= 3) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/group-packages`,
          query: route.query,
        });
      }

      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    }
    case "services": {
      if (bookingStore.numberOfGuests >= 2) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests`,
          query: route.query,
        });
      }

      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    }
    case "addOns":
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services`,
        query: route.query,
      });
    case "needsRemoval":
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/add-ons`,
        query: route.query,
      });
    case "removals":
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/needs-removal`,
        query: route.query,
      });
    case "dateTime": {
      // Handle the case where the dateTime page has been reached with no current guest number or service id
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        const lastGuestNumber = bookingStore.numberOfGuests;
        const firstServiceId = bookingStore.servicesByGuest[lastGuestNumber - 1]?.keys().next().value;
        const firstService = bookingStore.servicesByGuest[lastGuestNumber - 1]?.values().next().value;

        if (!(firstServiceId && firstService)) {
          return router.back();
        }

        if (firstService?.needsRemoval) {
          return router.push({
            path: `/locations/${bookingStore.locationId}/guests/${lastGuestNumber}/services/${firstServiceId}/removals`,
            query: route.query,
          });
        }

        return router.push({
          path: `/locations/${bookingStore.locationId}/guests/${lastGuestNumber}/services/${firstServiceId}/needs-removal`,
          query: route.query,
        });
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (service?.needsRemoval) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/removals`,
          query: route.query,
        });
      }

      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/needs-removal`,
        query: route.query,
      });
    }
    case "groupPackages":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    default:
      return router.back();
  }
};

const navigationNextText = computed(() => {
  switch (currentStage.value) {
    case "dateTime":
      return t("navigationBar.reserve");
    case "confirm":
      return t("navigationBar.confirm");
    case "reschedule":
      return t("navigationBar.reschedule");
    default:
      return t("navigationBar.next");
  }
});

// Disable back button if we're fetching services on any of these pages.
// Routing to these pages requires services to be fetched first e.g. .../services/:serviceId/add-ons
const backDisabled = computed(() => {
  switch (currentStage.value) {
    case "location":
      return !servicesByLocation.value;
    case "numberOfGuests":
      return !servicesByLocation.value;
    case "guests":
      return !servicesByLocation.value;
    case "groupPackages":
      return !servicesByLocation.value;
    case "services":
      return !servicesByLocation.value;
    case "addOns":
      return !servicesByLocation.value;
    case "needsRemoval":
      return !servicesByLocation.value;
    case "removals":
      return !servicesByLocation.value;
    default:
      return false;
  }
});

// Disable next button if we're fetching services on any of these pages.
// Routing to these pages requires services to be fetched first e.g. .../services/:serviceId/add-ons
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: There are many stages to handle
const nextDisabled = computed(() => {
  switch (currentStage.value) {
    case "location":
      return !(locationId.value && servicesByLocation.value);
    case "numberOfGuests":
      return bookingStore.numberOfGuests === 0 || !servicesByLocation.value;
    case "guests":
      return !(bookingStore.hasPopulatedAllGuests && servicesByLocation.value);
    case "groupPackages":
      return !(bookingStore.hasPopulatedAllGuests && servicesByLocation.value);
    case "services": {
      if (!currentGuestNumber.value) {
        return true;
      }

      return bookingStore.servicesByGuest[currentGuestNumber.value - 1].size === 0 || !servicesByLocation.value;
    }
    case "addOns":
      return !servicesByLocation.value;
    case "needsRemoval": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return true;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return true;
      }

      return service.needsRemoval === null || !servicesByLocation.value;
    }
    case "removals": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return true;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return true;
      }

      return service.removalIds.size === 0 || !servicesByLocation.value;
    }
    case "dateTime":
      return bookingStore.bookingDateTimeUtc === null;
    case "confirm":
      return !(
        (userStore.paymentAccountChosen || "ccTreatwellICalBooking" in route.query) &&
        bookingStore.termsAndConditionsAccepted
      );
    case "reschedule":
      return !(bookingRescheduleStore.rescheduledDateTimeUtc && bookingRescheduleStore.termsAndConditionsAccepted);
    default:
      return true;
  }
});

const handlePopupDidYouKnow = async () => {
  bookingStore.setDidYouKnowPopupVisible(true);
  const displayEndDateTimeUtc = DateTime.now().plus({ seconds: 8 });

  await new Promise<void>((resolve) => {
    const timer = setInterval(() => {
      // Clear interval if the user has navigated away from the confirm page while the popup is visible
      if (
        route.path !== `/locations/${bookingStore.locationId}/confirm` ||
        (displayEndDateTimeUtc.diffNow(["minutes", "seconds"]).seconds <= 0 && !isConfirmingBooking.value)
      ) {
        clearInterval(timer);
        resolve();
      }
    }, 1000);
  });

  bookingStore.setDidYouKnowPopupVisible(false);
};

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Lot's of next button cases to cover
const handleNextClick = async () => {
  switch (currentStage.value) {
    case "location":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    case "numberOfGuests": {
      if (bookingStore.numberOfGuests <= 2) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests`,
          query: route.query,
        });
      }
      return router.push({
        path: `/locations/${bookingStore.locationId}/group-packages`,
        query: route.query,
      });
    }
    case "groupPackages": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
        query: route.query,
      });
    }
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
        query: route.query,
      });
    case "services": {
      if (!currentGuestNumber.value) {
        return;
      }

      const firstServiceId = bookingStore.servicesByGuest[currentGuestNumber.value - 1].keys().next().value;
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${firstServiceId}/add-ons`,
        query: route.query,
      });
    }
    case "addOns": {
      if (bookingStore.isGroupPackageChosen) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests`,
          query: route.query,
        });
      }
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/needs-removal`,
        query: route.query,
      });
    }
    case "needsRemoval": {
      if (!(currentGuestNumber.value && currentServiceId.value)) {
        return;
      }

      const service = bookingStore.servicesByGuest[currentGuestNumber.value - 1]?.get(currentServiceId.value);

      if (!service) {
        return;
      }

      if (service.needsRemoval) {
        return router.push({
          path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${currentServiceId.value}/removals`,
          query: route.query,
        });
      }

      return navigateToNextServiceOrDateTime();
    }
    case "removals": {
      return navigateToNextServiceOrDateTime();
    }
    case "dateTime": {
      const reserveSuccess = await handleReserve();

      if (!reserveSuccess) {
        return;
      }

      return router.push({
        path: `/locations/${bookingStore.locationId}/confirm`,
        query: route.query,
      });
    }
    case "confirm": {
      await Promise.all([bookingStore.confirmBooking(), handlePopupDidYouKnow()]);

      // Don't route to complete page if the user has navigated away from the confim page after they
      // have clicked the confirm booking button
      if (route.path !== `/locations/${bookingStore.locationId}/confirm`) {
        return;
      }

      return router.push({
        path: `/locations/${bookingStore.locationId}/complete`,
        query: route.query,
      });
    }
    case "reschedule": {
      await bookingRescheduleStore.confirmBookingReschedule();
      return router.push({
        path: "/account/bookings",
        query: {
          ...route.query,
          rescheduleSuccess: "true",
        },
      });
    }
    default:
      return;
  }
};

const handleStageEditClicked = (stage: BookingProgressStage) => {
  switch (stage) {
    case "location":
      return router.push({
        path: "/",
        query: route.query,
      });
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    case "services": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
        query: route.query,
      });
    }
    case "dateTime":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
        query: route.query,
      });
    default:
      return;
  }
};

const navigateToNextServiceOrDateTime = () => {
  if (!(currentGuestNumber.value && currentServiceId.value)) {
    return;
  }

  const servicesForCurrentGuest = bookingStore.servicesByGuest[currentGuestNumber.value - 1];

  if (!servicesForCurrentGuest) {
    return;
  }

  const serviceIdsForCurrentGuest = Array.from(servicesForCurrentGuest.keys());
  const currentServiceIdx = serviceIdsForCurrentGuest.indexOf(currentServiceId.value);

  // If there are more services after this to work through
  const nextServiceId = serviceIdsForCurrentGuest[currentServiceIdx + 1];
  if (nextServiceId) {
    return router.push({
      path: `/locations/${bookingStore.locationId}/guests/${currentGuestNumber.value}/services/${nextServiceId}/add-ons`,
      query: route.query,
    });
  }

  if (bookingStore.hasMultipleGuests) {
    return router.push({
      path: `/locations/${bookingStore.locationId}/guests`,
      query: route.query,
    });
  }

  return router.push({
    path: `/locations/${bookingStore.locationId}/date-time`,
    query: route.query,
  });
};
</script>

<template>
  <div class="flex flex-col items-center">
    <Transition name="menu-transition">
      <MainMenu v-if="mainMenuVisible"
        :labelsAndHrefValues="mainMenuItems"
        @cancelled="handleMainMenuToggled" />
    </Transition>
    <header v-if="!isNotFound && !['account', 'reschedule'].includes(currentStage)" class="w-full max-w-[1140px] flex">
      <Header class="w-full" :userFirstName="authStore.userFirstName" @account="handleAccountClick" @main-menu="handleMainMenuToggled" :disabled="isRescheduling || isPreparingForConfirmStage || isPreparingForCompleteStage" />
    </header>
    <nav v-if="!isNotFound && !['account', 'auth', 'reschedule'].includes(currentStage)" class="w-full flex justify-center bg-booking-progress-background">
      <div class="w-full max-w-[1140px] flex p-5">
        <BookingProgress @stage-edit-clicked="handleStageEditClicked" :currentStage="currentProgressStage" :disabled="isRescheduling || isPreparingForConfirmStage || isPreparingForCompleteStage" />
      </div>
    </nav>
    <div class="flex flex-col gap-10 items-center w-full max-w-[1140px]">
      <main class="w-full max-w-[1140px] flex flex-col justify-center gap-5">
        <RouterView v-slot="{ Component, route }">
          <Transition :name="sectionTransitionName" mode="out-in">
            <div class="mb-36" :key="route.fullPath">
              <PopupAuth v-if="!isLoggedIn && showAuthPopup" :location-id="locationId" @cancelled="handleAuthCancelled" />
              <component :is="Component" />
            </div>
          </Transition>
        </RouterView>
        <Transition name="navigation-bar-transition">
          <div v-if="!isNotFound && !['location', 'complete', 'info', 'account', 'auth'].includes(currentStage)" class="w-full fixed bottom-0 left-0 bg-navigation-background">
            <div class="w-full flex justify-center">
              <NavigationBar
                :back-visible="currentStage !== 'confirm'"
                class="w-full max-w-[1140px]"
                :backText="t('navigationBar.back')"
                :nextText="navigationNextText"
                :backDisabled="backDisabled"
                :nextDisabled="nextDisabled"
                :is-submitting="isRescheduling || isPreparingForConfirmStage || isPreparingForCompleteStage"
                @backClick="handleBackClick"
                @nextClick="handleNextClick" />
            </div>
          </div>
        </Transition>
      </main>
    </div>
  </div>
</template>
