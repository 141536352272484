<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import BookingSummarySkeleton from "@/src/components/BookingSummarySkeleton.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import NavigationTop from "@/src/components/NavigationTop.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import { bookingToBookingSummary, bookingsToBookingSummaries } from "@/src/lib/summaryHelpers";
import {
  type BookingWithFullInfo,
  TownhouseApiBookingCancelPaymentsAddedToInvoiceError,
  TownhouseApiError,
} from "@/src/lib/townhouseApiClient";
import PopupComplete from "@/src/popups/PopupComplete.vue";
import PopupConfirm from "@/src/popups/PopupConfirm.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useUserStore } from "@/src/stores/userStore";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

defineProps<{
  rescheduleSuccess: boolean | undefined;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const userStore = useUserStore();

const bookingBeingCancelled = ref<BookingWithFullInfo | null>(null);
const cancellingBookingComplete = ref<boolean>(false);
const { userFutureBookings, cancelBookingFailure } = storeToRefs(userStore);
const isLoading = computed(() => userFutureBookings.value === null);

const cancellingBookingSummary = computed(() =>
  bookingBeingCancelled.value ? bookingToBookingSummary(bookingBeingCancelled.value) : null,
);

const handleBack = () => {
  router.push({
    path: "/account",
    query: route.query,
  });
};

const handleReschedule = (booking: BookingWithFullInfo) => {
  if (booking.canBeRescheduled) {
    router.push({
      path: `/account/bookings/${booking.id}/reschedule`,
      query: route.query,
    });
  }
};

const handleCancel = (booking: BookingWithFullInfo) => {
  bookingBeingCancelled.value = booking;

  authStore.analytics.track("Booking Cancellation Started", {
    bookingId: booking.id,
  });
};

const handleCancelYes = async () => {
  // This shouldn't be null, but we have to prove it to Typescript
  if (bookingBeingCancelled.value) {
    try {
      await userStore.cancelBooking(bookingBeingCancelled.value.id);
      bookingBeingCancelled.value = null;
    } catch (e) {
      if (e instanceof TownhouseApiError) {
        return;
      }

      throw e;
    }
  }

  cancellingBookingComplete.value = true;
};

const handleCancelNo = () => {
  // This shouldn't be null, but we have to prove it to Typescript
  if (bookingBeingCancelled.value) {
    authStore.analytics.track("Booking Cancellation Cancelled", {
      bookingId: bookingBeingCancelled.value.id,
    });
  }

  bookingBeingCancelled.value = null;
};

const handleCancelSuccessPopupCancelled = () => {
  userStore.fetchUserFutureBookings();
  cancellingBookingComplete.value = false;
};

const handleRescheduleSuccessPopupCancelled = async () => {
  await userStore.fetchUserFutureBookings();
  router.replace({
    path: "/account/bookings",
    query: {
      ...route.query,
      rescheduleSuccess: undefined,
    },
  });
};
</script>

<template>
  <PopupConfirm
    v-if="cancellingBookingSummary"
    submit-mode
    :prompt="t('account.bookings.cancelConfirm.prompt')"
    @confirmed="handleCancelYes"
    @cancelled="handleCancelNo"
    :error="cancelBookingFailure ? cancelBookingFailure instanceof TownhouseApiBookingCancelPaymentsAddedToInvoiceError ? t('account.bookings.paymentsAddedToInvoiceError') : t('account.bookings.cancelError') : undefined"
    :hide-customer-care-message="cancelBookingFailure instanceof TownhouseApiBookingCancelPaymentsAddedToInvoiceError"
    >
    <div class="w-full border-2 p-10 border-bookings-border">
      <BookingSummary
        hide-services

        :edit-mode="false"
        :locationName="cancellingBookingSummary.locationName"
        :address-one="cancellingBookingSummary.addressOne"
        :address-two="cancellingBookingSummary.addressTwo || undefined"
        :city="cancellingBookingSummary.city"
        :state="cancellingBookingSummary.state || undefined"
        :postal-code="cancellingBookingSummary.postalCode"
        :services="cancellingBookingSummary.serviceSummariesByGuest"
        :booking-date-time-utc="cancellingBookingSummary.bookingDateTimeUtc"
      />
    </div>
    <TextHeader3 class="text-center">{{ t('account.bookings.cancelConfirm.message') }}</TextHeader3>
    <TextBody3 v-if="bookingBeingCancelled?.hasCancellationCharge" class="text-center">{{ t('account.bookings.cancelConfirm.cancellationCharge') }}</TextBody3>
  </PopupConfirm>

  <PopupComplete
    v-if="cancellingBookingComplete"
    @cancelled="handleCancelSuccessPopupCancelled"
    :title="t('account.bookings.cancelComplete.title')"
    :message="t('account.bookings.cancelComplete.message')"
    :confirm="t('account.bookings.cancelComplete.confirm')" />

  <PopupComplete
    v-if="rescheduleSuccess"
    @cancelled="handleRescheduleSuccessPopupCancelled"
    :title="t('account.bookings.rescheduleComplete.title')"
    :message="t('account.bookings.rescheduleComplete.message')"
    :confirm="t('account.bookings.rescheduleComplete.confirm')" />

  <div class="w-full max-w-[1140px] flex flex-col justify-center items-center relative">
    <TextHeader1 class="p-10">{{ t("account.bookings.label") }}</TextHeader1>
    <NavigationTop @back="handleBack" />
    <div v-if="!isLoading && userFutureBookings && userFutureBookings.length > 0" class="w-full p-10 flex flex-col items-center gap-5">
      <div v-for="[booking, bookingSummary] in _.zip(userFutureBookings, bookingsToBookingSummaries(userFutureBookings))" class="w-full max-w-[600px]">
        <div v-if="booking && bookingSummary" class="mb-5 border-2 p-10 border-bookings-border">
          <BookingSummary
            :edit-mode="false"
            :locationName="bookingSummary.locationName"
            :address-one="bookingSummary.addressOne"
            :address-two="bookingSummary.addressTwo || undefined"
            :city="bookingSummary.city"
            :state="bookingSummary.state || undefined"
            :postal-code="bookingSummary.postalCode"
            :services="bookingSummary.serviceSummariesByGuest"
            :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
          />
          <!-- TODO: Hide when it's too late to reschedule or cancel -->
          <div class="flex gap-5 mt-10">
            <ButtonInScreenTertiary :disabled="!booking.canBeRescheduled" @click="handleReschedule(booking)">{{ t('account.bookings.reschedule.button') }}</ButtonInScreenTertiary>
            <ButtonInScreenTertiary @click="handleCancel(booking)">{{ t('account.bookings.cancel') }}</ButtonInScreenTertiary>
          </div>
          <div v-if="!booking.canBeRescheduled" class="mt-10">
            <TextBody3>{{ t('account.bookings.cannotReschedule') }}</TextBody3>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!isLoading && (!userFutureBookings || userFutureBookings.length === 0)" class="w-full">
      <SectionDivider class="w-full my-10" />
      <div class="w-full p-10 flex flex-col items-center gap-5">
        <TextBody1>{{ t("account.bookings.none") }}</TextBody1>
        <ButtonPrimary @click="router.push({ path: '/', query: { ... route.query, returnTo: undefined }});" class="mt-20 max-w-96">Book Now</ButtonPrimary>
      </div>
    </div>
    <div v-else class="w-full p-10 flex flex-col items-center gap-5">
      <div class="w-full max-w-[600px]">
        <div class="mb-5 border-2 p-10 border-bookings-border">
          <BookingSummarySkeleton :edit-mode="false" />
        </div>
      </div>
    </div>
  </div>
</template>
