<script setup lang="ts">
import SectionWarning from "@/src/components/SectionWarning.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import { useBookingStore } from "@/src/stores/bookingStore";
import { storeToRefs } from "pinia";

const bookingStore = useBookingStore();
const { locationInfo } = storeToRefs(bookingStore);
</script>

<template>
  <div v-if="locationInfo?.bannerHtml" class="flex m-5 my-10">
    <SectionWarning class="w-full p-10 border-1 border-warning">
      <TextBody3 class="text-center" v-html="locationInfo.bannerHtml"></TextBody3>
    </SectionWarning>
  </div>
</template>
