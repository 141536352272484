import type { Branded } from "@/src/lib/branded";
import {
  type CountryCallingCode,
  type CountryCode,
  type ZenotiCountryId,
  countryCodeToZenotiPhoneNumberLengthRange,
  countryCodesToCountryCallingCodeMap,
  countryCodesToZenotiCountryIdMap,
} from "@/src/lib/countryCode";

export type PhoneNumberNational = Branded<string, "PhoneNumberNational">;
export class PhoneNumberMalformedError extends Error {}
export class PhoneNumberInvalidError extends Error {}
export class PhoneNumberNotSupportedError extends Error {}

// Validate that the phone number is made up of only numbers
const nationalNumberRegex = /^[0-9]*$/;

// These are common characters used when writing phone numbers e.g.
// (211) 222-3333 which is a common format for US phone numbers
const nationalNumberPermittedCharactersRegex = /[\(\)\- ]/g;

export class PhoneNumber {
  readonly countryCode: CountryCode;
  readonly countryCallingCode: CountryCallingCode;
  readonly nationalNumber: PhoneNumberNational;
  readonly zenotiCountryId: ZenotiCountryId;

  constructor(rawnationalNumber: string, countryCode: CountryCode) {
    const nationalNumber = rawnationalNumber.replaceAll(nationalNumberPermittedCharactersRegex, "");

    if (!nationalNumberRegex.test(nationalNumber)) {
      throw new PhoneNumberMalformedError(`The phone number '${rawnationalNumber}' is not in the right format`);
    }

    const zenotiCountryId = countryCodesToZenotiCountryIdMap[countryCode];
    const countryPhoneNumberLengthRange = countryCodeToZenotiPhoneNumberLengthRange[countryCode];

    if (!(zenotiCountryId && countryPhoneNumberLengthRange)) {
      throw new PhoneNumberNotSupportedError(`The country code '${countryCode}' is not supported`);
    }

    const [minimumLength, maximumLength] = countryPhoneNumberLengthRange;

    if (nationalNumber.length < minimumLength || nationalNumber.length > maximumLength) {
      throw new PhoneNumberInvalidError(
        `The phone number '${rawnationalNumber}' is not a valid phone number for country code '${countryCode}'. It must be between ${minimumLength} and ${maximumLength} digits long.`,
      );
    }

    this.countryCode = countryCode;
    this.countryCallingCode = countryCodesToCountryCallingCodeMap[countryCode];
    this.nationalNumber = nationalNumber as PhoneNumberNational;
    this.zenotiCountryId = zenotiCountryId;
  }

  toString(): string {
    return `+${this.countryCallingCode}${this.nationalNumber}`;
  }
}
