import { giftCardUrlFromOrigin, zenotiOriginFromOrigin } from "@/src/config/env";
import type { BookingProgressStage, BookingStage } from "@/src/config/stages";
import type { BookingId, LocationId, ServiceId } from "@/src/lib/townhouseApiClient";
import AccountBookingReschedulePage from "@/src/pages/AccountBookingReschedulePage.vue";
import AccountBookingsPage from "@/src/pages/AccountBookingsPage.vue";
import AccountDetailsPage from "@/src/pages/AccountDetailsPage.vue";
import AccountPage from "@/src/pages/AccountPage.vue";
import AccountPaymentAccountsPage from "@/src/pages/AccountPaymentAccountsPage.vue";
import AccountRewardsPage from "@/src/pages/AccountRewardsPage.vue";
import AddOnsPage from "@/src/pages/AddOnsPage.vue";
import AuthCreateAccountPage from "@/src/pages/AuthCreateAccountPage.vue";
import AuthLoginPage from "@/src/pages/AuthLoginPage.vue";
import AuthResetPasswordPage from "@/src/pages/AuthResetPasswordPage.vue";
import CompletePage from "@/src/pages/CompletePage.vue";
import ConfirmPage from "@/src/pages/ConfirmPage.vue";
import DateTimePage from "@/src/pages/DateTimePage.vue";
import EmptyPage from "@/src/pages/EmptyPage.vue";
import GroupBookingsPage from "@/src/pages/GroupPackagesPage.vue";
import GuestsPage from "@/src/pages/GuestsPage.vue";
import LocationsPage from "@/src/pages/LocationsPage.vue";
import NeedsRemovalPage from "@/src/pages/NeedsRemovalPage.vue";
import NotFoundPage from "@/src/pages/NotFoundPage.vue";
import NumberOfGuestsPage from "@/src/pages/NumberOfGuestsPage.vue";
import RemovalsPage from "@/src/pages/RemovalsPage.vue";
import ServicesPage from "@/src/pages/ServicesPage.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
// biome-ignore lint/style/noNamespaceImport: Sentry has to be imported like this
import * as Sentry from "@sentry/vue";
import _ from "lodash";
import { type RouteRecordRaw, type RouterHistory, createRouter, createWebHistory } from "vue-router";

const routes: RouteRecordRaw[] = [
  // The following routes are redirects for old URLs from the Zenoti booking flow
  // Do not remove any of these until you are ABSOLUTELY sure you are confident they won't break a real user
  {
    // In some emails from Zenoti, this is the link given to sign-in and manage your booking
    path: "/webstoreNew/user/signIn",
    component: EmptyPage,
    beforeEnter: (to) => {
      return {
        path: "/account",
        query: to.query,
        replace: true,
      };
    },
  },
  {
    // This is the most common link for booking at a specific center
    path: "/webstoreNew/services/:centerId?",
    component: EmptyPage,
    beforeEnter: async (to) => {
      const authStore = useAuthStore();
      try {
        const targetUrl = await authStore.fetchExperimentNewBookingFlow();

        if (targetUrl) {
          window.location.replace(targetUrl);
        }
      } catch (_) {
        return {
          path: "/",
          query: to.query,
          replace: true,
        };
      }
    },
  },
  {
    // Referrals, we don't support it anymore so we just redirect to root
    path: "/webstoreNew/referral",
    component: EmptyPage,
    beforeEnter: (to) => {
      return {
        path: "/",
        query: to.query,
        replace: true,
      };
    },
  },
  {
    // Packages, we don't support them so we just redirect to root
    path: "/webstoreNew/sales/seriespackage",
    component: EmptyPage,
    beforeEnter: (to) => {
      return {
        path: "/",
        query: to.query,
        replace: true,
      };
    },
  },
  {
    // Gift cards, this should still be done on Zenoti for now
    path: "/webstoreNew/giftcards/:centerId?",
    component: EmptyPage,
    beforeEnter: () => {
      window.location.replace(`${giftCardUrlFromOrigin()}${window.location.search}`);
    },
  },
  {
    // An older style of URL for booking at a center
    path: "/webstoreNew/:centerId?",
    component: NotFoundPage,
    beforeEnter: async (to) => {
      const authStore = useAuthStore();

      if (!to.params["centerId"]) {
        return {
          path: "/",
          query: to.query,
          replace: true,
        };
      }

      try {
        const targetUrl = await authStore.fetchExperimentNewBookingFlow();

        if (targetUrl) {
          window.location.replace(targetUrl);
        }
      } catch (_) {
        return {
          path: "/",
          query: to.query,
          replace: true,
        };
      }
    },
  },
  {
    path: "/Feedback",
    component: EmptyPage,
    beforeEnter: (to) => {
      window.location.replace(`${zenotiOriginFromOrigin()}${to.fullPath}`);
    },
  },
  // The following routes our own own booking flow
  {
    path: "/auth",
    beforeEnter: (to) => {
      const authStore = useAuthStore();

      if (authStore.isLoggedIn) {
        if (to.query && typeof to.query["returnTo"] === "string") {
          return {
            path: to.query["returnTo"],
            query: {
              ...to.query,
              returnTo: undefined,
            },
          };
        }

        return {
          path: "/",
          query: to.query,
        };
      }
    },
    meta: {
      stage: "auth" as BookingStage,
    },
    children: [
      {
        path: "login",
        component: AuthLoginPage,
      },
      {
        path: "create-account",
        component: AuthCreateAccountPage,
      },
      {
        path: "reset-password",
        component: AuthResetPasswordPage,
      },
    ],
  },
  {
    path: "/",
    beforeEnter: async () => {
      const authStore = useAuthStore();
      const { locationId, numberOfGuests, isPrBooking } = useBookingStore();

      if (locationId) {
        Sentry.setTag("locationId", locationId);
      }

      if (numberOfGuests) {
        Sentry.setTag("numberOfGuests", numberOfGuests);
      }

      Sentry.setTag("isPrBooking", isPrBooking);

      // Set this tag as early as possible so that any errors are labelled with it
      Sentry.setTag("browserId", authStore.browserId);

      await authStore.verifySession();

      Sentry.setUser(authStore.userId ? { id: authStore.userId } : null);
    },
    children: [
      {
        path: "",
        beforeEnter: (to) => {
          const bookingStore = useBookingStore();

          // Add a query param if users have navigated to the locations page while their booking is confirming or the did you know popup is visible
          if (bookingStore.isPreparingForCompleteStage) {
            to.query["bookingConfirmationInterrupted"] = "true";
          }

          if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
            bookingStore.$reset();
          }

          const isPrBooking = Boolean(to.query["press"]);

          if (bookingStore.isPrBooking !== isPrBooking) {
            bookingStore.$reset();
            bookingStore.setIsPrBooking(isPrBooking);
          }
        },
        component: LocationsPage,
        props: (route) => ({
          press: Boolean(route.query["press"]),
          bookingConfirmationInterrupted: Boolean(route.query["bookingConfirmationInterrupted"]),
        }),
        meta: {
          stage: "location" as BookingStage,
          progressStage: "location" as BookingProgressStage,
        },
      },
      {
        path: "account",
        beforeEnter: (to) => {
          const authStore = useAuthStore();
          const userStore = useUserStore();

          if (!authStore.isLoggedIn) {
            return {
              path: "/auth/login",
              query: {
                ...to.query,
                returnTo: to.path,
              },
            };
          }

          userStore.fetchUser();
        },
        meta: {
          stage: "account" as BookingStage,
        },
        children: [
          {
            path: "",
            component: AccountPage,
          },
          {
            path: "details",
            component: AccountDetailsPage,
          },
          {
            path: "bookings",
            children: [
              {
                path: "",
                beforeEnter: () => {
                  const userStore = useUserStore();
                  userStore.fetchUserFutureBookings();
                },
                props: (route) => ({
                  rescheduleSuccess: Boolean(route.query["rescheduleSuccess"]),
                }),
                component: AccountBookingsPage,
              },
              {
                path: ":bookingId/reschedule",
                meta: {
                  stage: "reschedule" as BookingStage,
                },
                props: (route) => ({
                  bookingId: route.params["bookingId"] as BookingId,
                }),
                beforeEnter: (route) => {
                  const userStore = useUserStore();
                  userStore.fetchUserBooking(route.params["bookingId"] as BookingId);
                },
                component: AccountBookingReschedulePage,
              },
            ],
          },
          {
            path: "payment_accounts",
            beforeEnter: () => {
              const userStore = useUserStore();

              userStore.fetchPaymentAccounts();
            },
            component: AccountPaymentAccountsPage,
          },
          {
            path: "loyalty_points",
            beforeEnter: () => {
              const userStore = useUserStore();
              userStore.fetchUserLoyaltyPoints();
            },
            component: AccountRewardsPage,
          },
          {
            path: ":pathMatch(.*)*",
            redirect: "/not-found",
          },
        ],
      },
      {
        path: "locations/:locationId",
        beforeEnter: async (to) => {
          const bookingStore = useBookingStore();

          if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
            return { path: "/", query: to.query };
          }

          const isPrBooking = Boolean(to.query["press"]);

          if (bookingStore.isPrBooking !== isPrBooking) {
            bookingStore.$reset();
            bookingStore.setIsPrBooking(isPrBooking);
            return { path: "/", query: to.query };
          }

          bookingStore.setLocation(to.params["locationId"] as LocationId, isPrBooking);

          // We have to await because we need to check if the location has bookings enabled
          // so that users cannot attempt to make appointments
          try {
            await bookingStore.fetchLocationInfo();
          } catch (_) {
            return { path: "/not-found", query: to.query };
          }

          bookingStore.fetchServices();
        },
        children: [
          {
            path: "",
            component: NumberOfGuestsPage,
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "numberOfGuests" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "guests",
            component: GuestsPage,
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }

              if (bookingStore.numberOfGuests === 1) {
                return {
                  path: `/locations/${to.params["locationId"]}/guests/1/services`,
                  query: to.query,
                };
              }

              if (bookingStore.numberOfGuests < 2) {
                return {
                  path: `/locations/${to.params["locationId"]}`,
                  query: to.query,
                };
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "guests" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "group-packages",
            component: GroupBookingsPage,
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }

              if (bookingStore.numberOfGuests < 3 || bookingStore.numberOfGuests > 6) {
                return `/locations/${to.params["locationId"]}`;
              }
            },
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "groupPackages" as BookingStage,
              progressStage: "guests" as BookingProgressStage,
            },
          },
          {
            path: "guests/:guestNumber",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }

              const guestNumber = Number.parseInt(to.params["guestNumber"].toString(), 10);
              if (
                !(bookingStore.hasSelectedNumberOfGuests && _.inRange(guestNumber, 0, bookingStore.numberOfGuests + 1))
              ) {
                return { path: "/", query: to.query };
              }
            },
            children: [
              {
                path: "services",
                beforeEnter: (to) => {
                  const bookingStore = useBookingStore();

                  if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                    return { path: "/", query: to.query };
                  }

                  const isPrBooking = Boolean(to.query["press"]);

                  if (bookingStore.isPrBooking !== isPrBooking) {
                    bookingStore.$reset();
                    bookingStore.setIsPrBooking(isPrBooking);
                    return { path: "/", query: to.query };
                  }

                  if (bookingStore.isGroupPackageChosen && !to.fullPath.includes("add-ons")) {
                    return `/locations/${to.params["locationId"]}/guests`;
                  }
                },
                component: ServicesPage,
                props: (route) => ({
                  locationId: route.params["locationId"] as LocationId,
                  guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                  press: Boolean(route.query["press"]),
                }),
                meta: {
                  stage: "services" as BookingStage,
                  progressStage: "services" as BookingProgressStage,
                },
              },
              {
                path: "services/:serviceId",
                beforeEnter: (to) => {
                  const bookingStore = useBookingStore();

                  if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                    return { path: "/", query: to.query };
                  }

                  const isPrBooking = Boolean(to.query["press"]);

                  if (bookingStore.isPrBooking !== isPrBooking) {
                    bookingStore.$reset();
                    bookingStore.setIsPrBooking(isPrBooking);
                    return { path: "/", query: to.query };
                  }

                  if (bookingStore.isGroupPackageChosen && !to.fullPath.includes("add-ons")) {
                    return `/locations/${to.params["locationId"]}/guests`;
                  }

                  const guestNumber = Number.parseInt(to.params["guestNumber"].toString(), 10);
                  if (!bookingStore.servicesByGuest[guestNumber - 1]?.get(to.params["serviceId"] as ServiceId)) {
                    return {
                      path: `/locations/${to.params["locationId"]}/guests/1/services`,
                      query: to.query,
                    };
                  }
                },
                children: [
                  {
                    path: "add-ons",
                    component: AddOnsPage,
                    beforeEnter: (to) => {
                      const bookingStore = useBookingStore();

                      if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                        return { path: "/", query: to.query };
                      }

                      const isPrBooking = Boolean(to.query["press"]);

                      if (bookingStore.isPrBooking !== isPrBooking) {
                        bookingStore.$reset();
                        bookingStore.setIsPrBooking(isPrBooking);
                        return { path: "/", query: to.query };
                      }
                    },
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                      press: Boolean(route.query["press"]),
                    }),
                    meta: {
                      stage: "addOns" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                  {
                    path: "needs-removal",
                    component: NeedsRemovalPage,
                    beforeEnter: (to) => {
                      const bookingStore = useBookingStore();

                      if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                        return { path: "/", query: to.query };
                      }

                      const isPrBooking = Boolean(to.query["press"]);

                      if (bookingStore.isPrBooking !== isPrBooking) {
                        bookingStore.$reset();
                        bookingStore.setIsPrBooking(isPrBooking);
                        return { path: "/", query: to.query };
                      }
                    },
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                      press: Boolean(route.query["press"]),
                    }),
                    meta: {
                      stage: "needsRemoval" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                  {
                    path: "removals",
                    component: RemovalsPage,
                    beforeEnter: (to) => {
                      const bookingStore = useBookingStore();

                      if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                        return { path: "/", query: to.query };
                      }

                      const isPrBooking = Boolean(to.query["press"]);

                      if (bookingStore.isPrBooking !== isPrBooking) {
                        bookingStore.$reset();
                        bookingStore.setIsPrBooking(isPrBooking);
                        return { path: "/", query: to.query };
                      }
                    },
                    props: (route) => ({
                      locationId: route.params["locationId"] as LocationId,
                      guestNumber: Number.parseInt(route.params["guestNumber"].toString(), 10) as GuestNumber,
                      serviceId: route.params["serviceId"] as ServiceId,
                      press: Boolean(route.query["press"]),
                    }),
                    meta: {
                      stage: "removals" as BookingStage,
                      progressStage: "services" as BookingProgressStage,
                    },
                  },
                  {
                    path: ":pathMatch(.*)*",
                    redirect: "/not-found",
                  },
                ],
              },
              {
                path: ":pathMatch(.*)*",
                redirect: "/not-found",
              },
            ],
          },
          {
            path: "date-time",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }

              if (!bookingStore.hasPopulatedAllGuests) {
                if (bookingStore.hasMultipleGuests) {
                  return { path: `/locations/${to.params["locationId"]}/guests`, query: to.query };
                }
                return { path: `/locations/${to.params["locationId"]}/guests/1/services`, query: to.query };
              }
            },
            component: DateTimePage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "dateTime" as BookingStage,
              progressStage: "dateTime" as BookingProgressStage,
            },
          },
          {
            path: "confirm",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }

              const isPrBooking = Boolean(to.query["press"]);

              if (bookingStore.isPrBooking !== isPrBooking) {
                bookingStore.$reset();
                bookingStore.setIsPrBooking(isPrBooking);
                return { path: "/", query: to.query };
              }

              if (!bookingStore.bookingReservationId || bookingStore.isReservationExpired) {
                return { path: `/locations/${to.params["locationId"]}/date-time`, query: to.query };
              }

              bookingStore.fetchTotalPrice();
            },
            component: ConfirmPage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "confirm" as BookingStage,
              progressStage: "confirm" as BookingProgressStage,
            },
          },
          {
            path: "complete",
            beforeEnter: (to) => {
              const bookingStore = useBookingStore();

              if (!bookingStore.bookingComplete || bookingStore.isPreparingForCompleteStage) {
                return { path: "/", query: to.query };
              }
            },
            component: CompletePage,
            props: (route) => ({
              locationId: route.params["locationId"] as LocationId,
              press: Boolean(route.query["press"]),
            }),
            meta: {
              stage: "complete" as BookingStage,
              progressStage: "complete" as BookingProgressStage,
            },
          },
          {
            path: ":pathMatch(.*)*",
            redirect: "/not-found",
          },
        ],
      },
      {
        path: ":pathMatch(.*)*",
        redirect: "/not-found",
      },
    ],
  },
  {
    path: "/not-found",
    component: NotFoundPage,
    meta: {
      notFound: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundPage,
    meta: {
      notFound: true,
    },
  },
];

export const generateRouter = (history: RouterHistory = createWebHistory()) => {
  return createRouter({
    history,
    routes,
    scrollBehavior() {
      // always scroll to top
      return { top: 0 };
    },
  });
};
