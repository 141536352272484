import type { CountryCode } from "@/src/lib/countryCode";
import { PhoneNumber } from "@/src/lib/phoneNumber";
import { DateTime } from "luxon";
import type { Ref } from "vue";
import { z } from "zod";

// This regex enforces the current Zenoti password requirements which are:
//
// * A least 8 chars
// * At least one lower-case character
// * At least one upper-case character
// * At least one number
// * At least one symbol in a specific set of symbols
const zenotiPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#%^&*()_+=]).{8,}$/;

export const validateName = (params: { name: string; errorRef: Ref<string | undefined>; errorMessage: string }) => {
  if (!params.name.trim()) {
    params.errorRef.value = params.errorMessage;
    return;
  }

  params.errorRef.value = undefined;
};

export const validateEmail = (params: {
  email: string;
  errorRef: Ref<string | undefined>;
  errorMessage: string;
}) => {
  const parsedEmail = z.string().email().safeParse(params.email);
  params.errorRef.value = parsedEmail.success ? undefined : params.errorMessage;
};

export const validateAndFormatPhone = (params: {
  phoneNumber: string;
  countryCode: CountryCode;
  errorRef: Ref<string | undefined>;
  errorMessage: string;
}): PhoneNumber | null => {
  try {
    if (params.phoneNumber) {
      const phoneNumber = new PhoneNumber(params.phoneNumber, params.countryCode);
      params.errorRef.value = undefined;
      return phoneNumber;
    }

    params.errorRef.value = params.errorMessage;
    return null;
  } catch {
    params.errorRef.value = params.errorMessage;
    return null;
  }
};

export const validateAndFormatDateOfBirth = (params: {
  dateOfBirth: { day: string; month: string; year: string };
  errorRefs: {
    dateErrorRef: Ref<string | undefined>;
    errorInDayRef: Ref<boolean>;
    errorInMonthRef: Ref<boolean>;
    errorInYearRef: Ref<boolean>;
  };
  errorMessages: { invalidError: string; belowAgeError: string };
}): DateTime | null => {
  const parsedDay = Number.parseInt(params.dateOfBirth.day, 10) || undefined;
  const parsedMonth = Number.parseInt(params.dateOfBirth.month, 10) || undefined;
  const parsedYear = Number.parseInt(params.dateOfBirth.year, 10) || undefined;
  const dateOfBirthParsed = DateTime.fromObject({ day: parsedDay, month: parsedMonth, year: parsedYear });

  if (!(parsedDay && parsedMonth && parsedYear)) {
    params.errorRefs.dateErrorRef.value = params.errorMessages.invalidError;
    params.errorRefs.errorInDayRef.value = !parsedDay;
    params.errorRefs.errorInMonthRef.value = !parsedMonth;
    params.errorRefs.errorInYearRef.value = !parsedYear;
    return null;
  }

  if (!dateOfBirthParsed.isValid) {
    params.errorRefs.dateErrorRef.value = params.errorMessages.invalidError;
    params.errorRefs.errorInDayRef.value = true;
    params.errorRefs.errorInMonthRef.value = true;
    params.errorRefs.errorInYearRef.value = true;
    return null;
  }

  if (DateTime.now().minus({ years: 16 }) < dateOfBirthParsed) {
    params.errorRefs.dateErrorRef.value = params.errorMessages.belowAgeError;
    params.errorRefs.errorInDayRef.value = true;
    params.errorRefs.errorInMonthRef.value = true;
    params.errorRefs.errorInYearRef.value = true;
    return null;
  }

  params.errorRefs.dateErrorRef.value = undefined;
  params.errorRefs.errorInDayRef.value = false;
  params.errorRefs.errorInMonthRef.value = false;
  params.errorRefs.errorInYearRef.value = false;
  return dateOfBirthParsed;
};

export const validatePassword = (params: {
  password: string | null;
  errorRef: Ref<string | undefined>;
  errorMessage: string;
}) => {
  if (!params.password?.match(zenotiPasswordRegex)) {
    params.errorRef.value = params.errorMessage;
    return;
  }

  params.errorRef.value = undefined;
};
