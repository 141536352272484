<script setup lang="ts">
import ButtonBinary from "@/src/components/ButtonBinary.vue";
import ClosePopup from "@/src/components/ClosePopup.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import { onUpdated, ref, watch } from "vue";

const { error, hideCustomerCareMessage } = defineProps<{
  prompt: string;
  error?: string;
  hideCustomerCareMessage?: boolean;
}>();

const emits = defineEmits<{
  cancelled: [];
  confirmed: [];
}>();

const confirmationChoice = ref<boolean | null>(null);

watch(confirmationChoice, (newValue: boolean | null) => {
  if (newValue === null) {
    return;
  }

  if (newValue) {
    emits("confirmed");
  } else {
    emits("cancelled");
  }
});

// Need to reset otherwise the button can still appear as selected and not be clickable
onUpdated(() => {
  if (error) {
    confirmationChoice.value = null;
  }
});
</script>

<template>
  <div role="dialog" aria-modal="true" @click="$emit('cancelled')" class="fixed inset-0 z-20 w-screen h-screen bg-popup-background p-10 flex justify-center items-start">
    <div @click.stop class="w-[600px] max-h-[calc(100dvh-2.5rem)] overflow-auto bg-white relative">
      <div class="w-full flex flex-col justify-center items-center">
        <ClosePopup @click="$emit('cancelled')" />
        <div class="p-10 w-full flex flex-col gap-10">
          <TextHeader1 class="mx-10 text-center">{{ prompt }}</TextHeader1>
          <SectionError v-if="error" :error-message="error" :hide-customer-care-message="hideCustomerCareMessage" role="alert" hide-border />
          <slot />
          <div>
            <ButtonBinary
              :submit-mode="true"
              labelOne="Yes"
              :valueOne="true"
              labelTwo="No"
              :valueTwo="false"
              v-model="confirmationChoice">
            </ButtonBinary>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
