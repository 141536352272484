<script setup lang="ts">
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import Icon from "@/src/components/Icon.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import type { LocationId } from "@/src/lib/townhouseApiClient";
import PopupSevenPlusGuests from "@/src/popups/PopupSevenPlusGuests.vue";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const bookingStore = useBookingStore();

const showPopupSevenPlus = ref<boolean>(false);

const props = defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const { numberOfGuests } = storeToRefs(bookingStore);

const fullLabelValues = [
  { value: 1, label: t("selectNumberOfGuests.label1") },
  { value: 2, label: t("selectNumberOfGuests.label2") },
  { value: 3, label: t("selectNumberOfGuests.label3") },
  { value: 4, label: t("selectNumberOfGuests.label4") },
  { value: 5, label: t("selectNumberOfGuests.label5") },
  { value: 6, label: t("selectNumberOfGuests.label6") },
];

const labelsAndValues = computed(() => (props.press ? [fullLabelValues[0]] : fullLabelValues));

const handleNumberOfGuestsChanged = (guestNumber: GuestNumber) => {
  bookingStore.setNumberOfGuests(guestNumber);
};

// We have to do this in a watch, not directly in the handler above, as the next won't be clickable until the page has
// rerendered the button in the non-disabled state
watch(numberOfGuests, () => {
  // FIXME: Swap it for a proper broken out navigation state machine in TH-1747
  document.getElementById("navigation-next")?.click();
});

const handleSevenPlusClicked = () => {
  showPopupSevenPlus.value = true;
};

const handleSevenPlusCancelled = () => {
  showPopupSevenPlus.value = false;
};
</script>

<template>
  <PopupSevenPlusGuests v-if="showPopupSevenPlus" @cancelled="handleSevenPlusCancelled" />
  <LocationBanner />
  <h2 class="flex justify-center my-5">
    <TextHeader1 class="text-center">{{ t('selectNumberOfGuests.header') }}</TextHeader1>
  </h2>
  <div class="px-5">
    <div role="radiogroup" class="w-full max-w-[700px] mx-auto flex flex-wrap gap-5 justify-center">
      <div v-for="({label, value}) in labelsAndValues"
           :key="value"
           class="w-[calc(33.333%-0.833rem)] max-w-[200px] shrink-0 grow-0">
        <ButtonInScreenPrimary
        class="block w-full p-5 aspect-5/4"
        :selected="numberOfGuests === value"
        @click="handleNumberOfGuestsChanged(value as GuestNumber)"
        role="radio"
        >
          <TextBody1>
            {{ label }}
          </TextBody1>
        </ButtonInScreenPrimary>
      </div>
    </div>
    <div v-if="!press" class="flex justify-center mt-10">
      <ButtonInScreenTertiary class="mx-auto min-w-[300px] max-w-[500px] mb-10" @click="handleSevenPlusClicked">
        <div class="flex justify-between items-center px-5">
          <div>
            <TextBody1>{{ t("selectNumberOfGuests.label7Plus") }}</TextBody1>
          </div>
          <Icon :icon="faAngleRight" size="lg"/>
        </div>
      </ButtonInScreenTertiary>
    </div>
  </div>
</template>
