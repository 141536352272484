<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import ButtonSecondary from "@/src/components/ButtonSecondary.vue";

const { isSubmitting } = defineProps<{
  backVisible: boolean;
  backText: string;
  nextText: string;
  backDisabled: boolean;
  nextDisabled: boolean;
  isSubmitting: boolean;
}>();

const emits = defineEmits<{
  backClick: [];
  nextClick: [];
}>();

const handleNextClick = () => {
  if (!isSubmitting) {
    emits("nextClick");
  }
};
</script>

<template>
  <div class="w-full flex bg-navigation-background p-5 md:justify-end">
    <ButtonSecondary @click="$emit('backClick')" class="basis-0 grow-0 px-20 whitespace-nowrap" :class="{ hidden: !backVisible }" :disabled="backDisabled">
      {{ backText }}
    </ButtonSecondary>
    <span class="w-full md:w-10"></span>
    <!-- FIXME: An ID is added here to allow screens to trigger navigation programmatically, but this is not clean -->
    <!-- Swap it for a proper broken out navigation state machine in TH-1747 -->
    <ButtonPrimary id="navigation-next" :submitting="isSubmitting" @click="handleNextClick" class="basis-0 grow-0 px-20 whitespace-nowrap" :disabled="nextDisabled">
      {{ nextText }}
    </ButtonPrimary>
  </div>
</template>
