<script setup lang="ts">
import BookingSummary from "@/src/components/BookingSummary.vue";
import ButtonInScreenPrimary from "@/src/components/ButtonInScreenPrimary.vue";
import ButtonInScreenSecondary from "@/src/components/ButtonInScreenSecondary.vue";
import ButtonInScreenTertiary from "@/src/components/ButtonInScreenTertiary.vue";
import CheckboxTermsAndConditions from "@/src/components/CheckboxTermsAndConditions.vue";
import Icon from "@/src/components/Icon.vue";
import InputText from "@/src/components/InputText.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionDivider from "@/src/components/SectionDivider.vue";
import SectionError from "@/src/components/SectionError.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody1Skeleton from "@/src/components/TextBody1Skeleton.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader3 from "@/src/components/TextHeader3.vue";
import TextLink from "@/src/components/TextLink.vue";
import type { BookingProgressStage } from "@/src/config/stages";
import {
  type LocationId,
  type PaymentAccount,
  TownhouseApiBookingReservationPromoCodeFailedToApplyError,
  TownhouseApiError,
} from "@/src/lib/townhouseApiClient";
import PopupAddPaymentAccount from "@/src/popups/PopupAddPaymentAccount.vue";
import PopupDidYouKnow from "@/src/popups/PopupDidYouKnow.vue";
import PopupMedicalOrAccessNotes from "@/src/popups/PopupMedicalOrAccessNotes.vue";
import PopupSelectPaymentAccount from "@/src/popups/PopupSelectPaymentAccount.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { useBookingStore } from "@/src/stores/bookingStore";
import { useUserStore } from "@/src/stores/userStore";
import { faCcAmex, faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { type IconDefinition, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { storeToRefs } from "pinia";
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const { t, n } = useI18n();
const authStore = useAuthStore();
const bookingStore = useBookingStore();
const userStore = useUserStore();
const route = useRoute();
const router = useRouter();

const props = defineProps<{
  locationId: LocationId;
  press: boolean | undefined;
}>();

const {
  expiryDateTimeUtc,
  termsAndConditionsAccepted,
  medicalOrAccessNotes,
  didYouKnowPopupVisible,
  didYouKnowImageSrc,
  confirmBookingFailure,
  isConfirmingBooking,
  fetchTotalPriceFailure,
  totalPrice,
  isFetchingTotalPrice,
  promoCode,
  promoCodeApplied,
  applyPromoCodeFailure,
  isApplyingPromoCode,
} = storeToRefs(bookingStore);
const { paymentAccountChosen } = storeToRefs(userStore);

const expiresIn = ref<string>();
const selectingPaymentAccount = ref(false);
const addingPaymentAccount = ref(false);
const showMedicalOrAccessNotesPopup = ref(false);

const pageHeader = useTemplateRef("pageHeader");

const isPreparingForCompleteStage = computed(() => {
  return isConfirmingBooking.value || didYouKnowPopupVisible.value;
});

// Reference non-reactively so that the navigating away from this page, causing the
// booking store to mutate, doesn't throw any errors in an attempt to re-render
const bookingSummary = bookingStore.createBookingSummary();

const handleMedicalOrAccessNotesCancelled = () => {
  showMedicalOrAccessNotesPopup.value = false;
};

const handleMedicalOrAccessNotesSave = (medicalOrAccessNotes: string | null) => {
  bookingStore.setMedicalOrAccessNotesInfo(medicalOrAccessNotes);
};

const handleSelectPaymentAccount = () => {
  selectingPaymentAccount.value = true;

  authStore.analytics.track("Select A Payment Account Shown", {});
};

const handlePaymentAccountAddClickedInSelectPopup = () => {
  selectingPaymentAccount.value = false;
  addingPaymentAccount.value = true;
};

const handleAddPaymentAccountStart = () => {
  addingPaymentAccount.value = true;
};

const handleAddPaymentAccountCancelled = () => {
  addingPaymentAccount.value = false;
};

const handleAddPaymentAccountSuccess = async () => {
  addingPaymentAccount.value = false;
  await userStore.refetchPaymentAccounts();
};

const updateExpiresIn = () => {
  if (!expiryDateTimeUtc.value) {
    return;
  }

  const durationTillExpiry = expiryDateTimeUtc.value.diffNow(["minutes", "seconds", "milliseconds"]);

  if (durationTillExpiry.minutes <= 0 && durationTillExpiry.seconds <= 0) {
    authStore.analytics.track("Booking Expired", {
      expiredAtDateTimeUtc: expiryDateTimeUtc.value.toString(),
    });

    bookingStore.setReservationExpired(true);

    return router.push({
      path: `/locations/${props.locationId}/date-time`,
      query: route.query,
    });
  }

  expiresIn.value = `${durationTillExpiry.minutes}:${durationTillExpiry.seconds.toString().padStart(2, "0")}`;
};

watch(confirmBookingFailure, (newConfirmBookingFailure) => {
  if (newConfirmBookingFailure) {
    pageHeader.value?.scrollIntoView({ behavior: "smooth" });
  }
});

watch(termsAndConditionsAccepted, (newValue) => {
  authStore.analytics.track(newValue ? "Terms and Conditions Accepted" : "Terms and Conditions Unaccepted", {});
});

onMounted(() => {
  // Initialize the countdown
  updateExpiresIn();

  // Set up an interval to update the remaining time every second
  const interval = setInterval(updateExpiresIn, 1000);

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });

  // Prefetch the image as early as possible to avoid layout shift
  bookingStore.fetchDidYouKnowImage();
});

const handleStageEditClicked = (stage: BookingProgressStage) => {
  switch (stage) {
    case "location":
      return router.push({
        path: "/",
        query: route.query,
      });
    case "guests":
      return router.push({
        path: `/locations/${bookingStore.locationId}`,
        query: route.query,
      });
    case "services": {
      return router.push({
        path: `/locations/${bookingStore.locationId}/guests`,
        query: route.query,
      });
    }
    case "dateTime":
      return router.push({
        path: `/locations/${bookingStore.locationId}/date-time`,
        query: route.query,
      });
    default:
      return;
  }
};

const handleApplyPromoCode = async () => {
  if (!promoCode.value) {
    return;
  }

  try {
    await bookingStore.applyPromoCode(promoCode.value);
    await bookingStore.refetchTotalPrice();
  } catch (e) {
    if (e instanceof TownhouseApiError) {
      return;
    }

    throw e;
  }
};

const handleTermsAndConditionsLinkClick = () => {
  if (!isPreparingForCompleteStage.value) {
    authStore.analytics.track("Terms and Conditions Link Clicked", {});
  }
};

const paymentCardIconByPaymentAccountCardType = (cardType: PaymentAccount["cardType"]): IconDefinition => {
  switch (cardType) {
    case "amex":
      return faCcAmex;
    case "visa":
      return faCcVisa;
    case "mastercard":
      return faCcMastercard;
    case "other":
      return faCreditCard;
  }
};
</script>

<template>
  <PopupMedicalOrAccessNotes v-if="showMedicalOrAccessNotesPopup"
    :text="medicalOrAccessNotes"
    @cancelled="handleMedicalOrAccessNotesCancelled"
    @save="handleMedicalOrAccessNotesSave"/>
  <PopupSelectPaymentAccount v-if="selectingPaymentAccount"
    @cancelled="selectingPaymentAccount = false"
    @paymentAccountChosenNextClick="selectingPaymentAccount = false"
    @paymentAccountAddClicked="handlePaymentAccountAddClickedInSelectPopup" />
  <PopupAddPaymentAccount v-if="addingPaymentAccount"
    @cancelled="handleAddPaymentAccountCancelled"
    @success="handleAddPaymentAccountSuccess" />
  <PopupDidYouKnow v-if="didYouKnowPopupVisible && didYouKnowImageSrc" :image-src="didYouKnowImageSrc" />
  <LocationBanner />
  <h2 class="flex justify-center text-center my-10" ref="pageHeader">
    <TextHeader1 class="text-center" :class="{ 'text-text-disabled': isPreparingForCompleteStage }">{{ t('confirm.header') }}</TextHeader1>
  </h2>
  <SectionDivider />
  <!-- Requires a z-10 to be above some of the buttons with loaders -->
  <div class="p-5 flex justify-center sticky z-10 top-0">
    <div class="border p-5 border-confirm-expiry-border bg-white" :class="{ 'text-warning-disabled': isPreparingForCompleteStage, 'text-warning': !isPreparingForCompleteStage }">
      <TextBody1>
        {{ t('confirm.expiresIn') }}
        <!-- We can't use a monospace font but we don't want the layout to jump around, so fix-width the chars to prevent movement -->
        <span v-for="part in expiresIn?.split('')" class="inline-block text-center" :class="{
          'w-4': part !== ':',
        }">{{ part }}</span>
      </TextBody1>
    </div>
  </div>
  <div class="flex justify-center">
    <SectionError v-if="confirmBookingFailure" :error-message="t('confirm.confirmBookingError')" class="max-w-[600px]" />
  </div>
  <div class="flex flex-col lg:flex-row gap-5 mx-5">
    <div class="w-full lg:w-1/2">
      <div class="m-5 lg:m-0 lg:border lg:border-confirm-page-section-border lg:p-10">
        <BookingSummary
          @stage-edit-clicked="handleStageEditClicked"
          edit-mode
          :location-name="bookingSummary.locationName"
          :address-one="bookingSummary.addressOne"
          :address-two="bookingSummary.addressTwo || undefined"
          :city="bookingSummary.city"
          :state="bookingSummary.state || undefined"
          :postal-code="bookingSummary.postalCode"
          :services="bookingSummary.serviceSummariesByGuest"
          :booking-date-time-utc="bookingSummary.bookingDateTimeUtc"
          :disabled="isPreparingForCompleteStage"
        />
      </div>
      <SectionDivider class="block mb-5 lg:hidden" />
    </div>
    <div class="w-full lg:w-1/2">
      <div class="lg:border lg:border-confirm-page-section-border lg:p-10">
        <div class="mx-5 mb-10" :class="{ 'text-text-disabled': isPreparingForCompleteStage }">
          <div class="flex justify-between items-end mb-10">
            <InputText class="w-full max-w-[600px] mr-20" v-model="promoCode" :label="t('confirm.promo.inputLabel')" :disabled="promoCodeApplied" :error="applyPromoCodeFailure ? applyPromoCodeFailure instanceof TownhouseApiBookingReservationPromoCodeFailedToApplyError ? t('confirm.promo.invalid') : t('confirm.promo.error') : undefined" />
            <div class="w-[155px]" :class="{ 'mb-10': applyPromoCodeFailure }">
              <ButtonInScreenSecondary class="border-2" :class="{ 'border-button-disabled': promoCodeApplied, 'border-button-in-screen-secondary': !promoCodeApplied  }" @click="handleApplyPromoCode" :disabled="isApplyingPromoCode || promoCodeApplied" :submitting="isApplyingPromoCode">{{ t('confirm.promo.apply') }}</ButtonInScreenSecondary>
            </div>
          </div>
          <TextHeader3>{{ t("confirm.price.header") }}</TextHeader3>
          <div v-if="totalPrice" class="flex flex-col w-full mb-10">
            <div class="flex justify-between items-center">
              <TextBody1 v-if="totalPrice.discountCents > 0">{{ t("confirm.price.subtotalLabel") }}</TextBody1>
              <TextBody1 v-if="totalPrice.discountCents > 0">{{ n(totalPrice.subtotalCents / 100, 'currency') }}</TextBody1>
            </div>
            <div class="flex justify-between items-center">
              <TextBody1 v-if="totalPrice.discountCents > 0">{{ t("confirm.price.discountLabel") }}</TextBody1>
              <TextBody1 v-if="totalPrice.discountCents > 0">–{{ n(totalPrice.discountCents / 100, 'currency') }}</TextBody1>
            </div>
            <div class="flex justify-between items-center">
              <TextBody1>{{ t("confirm.price.totalLabel") }}</TextBody1>
              <TextBody1>{{ n(totalPrice.totalCents / 100, 'currency') }}</TextBody1>
            </div>
          </div>
          <div v-else-if="isFetchingTotalPrice" class="flex justify-between items-center mb-10">
            <TextBody1>{{ t("confirm.price.totalLabel") }}</TextBody1>
            <div class="w-[155px]">
              <TextBody1Skeleton />
            </div>
          </div>
          <SectionError v-if="fetchTotalPriceFailure" :error-message="t('confirm.price.error')" :try-again-handler="() => bookingStore.fetchTotalPrice()" class="max-w-[600px]" />
        </div>
        <SectionDivider />
        <div class="flex justify-center mx-5 my-10">
          <ButtonInScreenTertiary :disabled="isPreparingForCompleteStage" @click="showMedicalOrAccessNotesPopup = !showMedicalOrAccessNotesPopup" class="max-w-[600px]">
            <div class="flex justify-between items-center">
              <div>
                <Icon class="mr-5" :icon="faSquarePlus" size="lg" />
                <TextBody1>{{ t("confirm.medicalInfo") }}</TextBody1>
              </div>
              <Icon :icon="faAngleRight" size="lg" />
            </div>
          </ButtonInScreenTertiary>
        </div>
        <SectionDivider />
        <div class="flex flex-col justify-center mx-5 my-10">
          <TextHeader3 class="block" :class="{ 'text-text-disabled': isPreparingForCompleteStage }">{{ t("confirm.payment.header") }}</TextHeader3>
          <TextBody3 class="block mb-5" :class="{ 'text-text-disabled': isPreparingForCompleteStage }">{{ t("confirm.payment.label") }}</TextBody3>
          <ButtonInScreenPrimary v-if="paymentAccountChosen" @click="handleSelectPaymentAccount" class="mx-auto max-w-[600px]" :disabled="isPreparingForCompleteStage">
            <div class="flex justify-between items-center">
              <div>
                <Icon class="mr-5" :icon="paymentCardIconByPaymentAccountCardType(paymentAccountChosen.cardType)" size="lg"/>
                <TextBody1>{{ t("confirm.payment.card") }} {{ paymentAccountChosen.lastFour }}</TextBody1>
              </div>
              <TextLink :disabled="isPreparingForCompleteStage">
                <TextBody1 :class="{ 'text-text-disabled': isPreparingForCompleteStage }">{{ t("confirm.payment.change") }}</TextBody1>
              </TextLink>
            </div>
          </ButtonInScreenPrimary>
          <ButtonInScreenPrimary v-else class="mx-auto max-w-[600px]" @click="handleAddPaymentAccountStart" :disabled="isPreparingForCompleteStage">
            <div class="flex justify-between items-center">
              <div>
                <Icon class="mr-5" :icon="faCreditCard" size="lg"/>
                <TextBody1>{{ t("confirm.payment.addCard") }}</TextBody1>
              </div>
              <Icon :icon="faAngleRight" size="lg"/>
            </div>
          </ButtonInScreenPrimary>
        </div>
        <SectionDivider />
        <div class="flex items-start mx-5 mt-10">
          <CheckboxTermsAndConditions :disabled="isPreparingForCompleteStage" v-model="termsAndConditionsAccepted" :terms-and-conditions-link-click-handler="handleTermsAndConditionsLinkClick" />
        </div>
      </div>
    </div>
  </div>
</template>
