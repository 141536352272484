export const localEnGb = {
  title: {
    // '|' is a reserved character in i18n, so we use literal interpolation
    // See https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
    default: "Online Booking {'|'} Townhouse",
    location: "Online Booking {'|'} Townhouse",
    numberOfGuests: "Number of Guests {'|'} Online Booking {'|'} Townhouse",
    groupPackages: "Choose Your Group Package {'|'} Online Booking {'|'} Townhouse",
    guests: "Your Guests {'|'} Online Booking {'|'} Townhouse",
    services: "Choose Your Services {'|'} Online Booking {'|'} Townhouse",
    addOns: "Select Your Add-Ons {'|'} Online Booking {'|'} Townhouse",
    needsRemoval: "Do You Need Removal? {'|'} Online Booking {'|'} Townhouse",
    removals: "Select Your Removal {'|'} Online Booking {'|'} Townhouse",
    dateTime: "Choose Your Date / Time {'|'} Online Booking {'|'} Townhouse",
    confirm: "Confirm Your Booking {'|'} Online Booking {'|'} Townhouse",
    complete: "Booking Confirmed {'|'} Online Booking {'|'} Townhouse",
    account: "Your Account {'|'} Online Booking {'|'} Townhouse",
    reschedule: "Reschedule Your Booking {'|'} Online Booking {'|'} Townhouse",
  },
  account: {
    header: "{firstName}{possessive} Account",
    fallbackHeader: "Your Account",
    details: {
      label: "Account",
      header: "Account Details",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      mobile: "Mobile",
      dateOfBirth: "Date of Birth",
      phone: "Mobile",
      country: "Country",
      referral: "Referral Code",
      password: "Password",
      marketing: {
        description: "Yes please – sign me up to exclusive offers, birthday treats and collaboration freebies!",
        email: "Email",
        sms: "SMS",
      },
      update: "Update",
      success: "Your account has been updated.",
      formError: "Failed to update your account details.",
    },
    bookings: {
      label: "Upcoming Appointments",
      none: "You have no upcoming appointments",
      reschedule: {
        button: "Reschedule",
        label: "Reschedule Appointment",
        availableTimesError:
          "We couldn’t display appointment times for rescheduling because something went wrong on our side.",
        confirmBookingRescheduleError:
          "We couldn’t reschedule your appointment because something went wrong on our side.",
        disabled: {
          title: "Your appointment cannot be rescheduled",
          message:
            "We're currently unable to reschedule your appointment as our systems are being upgraded. Please contact customercare{'@'}townhouse.co.uk who can reschedule your appointment for you.",
          confirm: "Close",
        },
      },
      rescheduleComplete: {
        title: "Your appointment has been rescheduled",
        message:
          "We will send you an email with your updated date and time, and the changes will reflect here shortly.",
        confirm: "Close",
      },
      cancel: "Cancel",
      cancelConfirm: {
        prompt: "Cancel Your Appointment",
        message: "Are you sure you want to cancel your appointment?",
        cancellationCharge:
          "(You will be charged the full amount of your booking as you are cancelling within 12 hours of the start time.)",
      },
      cancelComplete: {
        title: "Your appointment has been cancelled",
        message:
          "We will send you an email confirming your appointment has been cancelled, and the changes will reflect here shortly.",
        confirm: "Close",
      },
      cancelError: "We couldn’t cancel your upcoming appointment because something went wrong on our side.",
      paymentsAddedToInvoiceError:
        // '@' is a reserved character in i18n, so we use literal interpolation
        // See https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
        "We couldn’t cancel your upcoming appointment because your payment has already been processed. Please contact customercare{'@'}townhouse.co.uk if you require assistance.",
      cannotReschedule:
        // '@' is a reserved character in i18n, so we use literal interpolation
        // See https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
        "(Your appointment can no longer be rescheduled as it is too close to the start time. Please contact customercare{'@'}townhouse.co.uk if you require assistance.)",
    },
    rewards: {
      label: "Townhouse Rewards",
    },
    giftCards: {
      label: "Gift Cards",
    },
    paymentAccounts: {
      label: "Payment Details",
      card: "Card ending in {cardLast4}",
      delete: "Delete",
      confirmDelete: "Are you sure you want to remove the payment card ending in {cardLast4}?",
      deleteError: "We couldn’t delete your payment details because something went wrong on our side.",
      deleteErrorHasOpenBookings: "We couldn’t delete your last payment card because you have upcoming appointments.",
      none: "You have no saved payment cards",
    },
    logOut: {
      label: "Log Out",
    },
  },
  bookingSummary: {
    guests: {
      one: "Just Me!",
    },
    headers: {
      maniPedi: "Mani Pedi",
      location: "Location",
      guests: "Guests",
      services: "Services",
      dateTime: "Date",
      edit: "Edit",
    },
  },
  popup: {
    auth: {
      confirmStop: {
        prompt: "Are you sure you want to stop creating your account?",
      },
    },
    didYouKnow: {
      header: "Confirming Your Booking…",
    },
    mainMenu: {
      faq: "Faq",
      help: "Help",
      termsAndConditions: "Terms and conditions",
      privacyPolicy: "Privacy policy",
    },
    medicalOrAccessNotes: {
      header: "Add medical info / access requirements",
      label: "Medical Info / Access Needs",
      description:
        "Please add any details of any allergies, medical conditions or contra-indications that we should be aware of, or any special access requirements.",
      save: "Save",
      error: "Please enter some details about any medical information or access requirements before saving.",
    },
    selectPaymentAccount: {
      addCard: "Add a new card",
      header: "Select a card to use",
      card: "Card ending in",
      next: "Next",
    },
    selectEmployee: {
      header: "Choose Your Nail Artist",
      any: "Any Nail Artist",
      next: "Select Nail Artist",
      availableEmployeesError: "We couldn’t display any nail artists because something went wrong on our side.",
    },
  },
  serviceCard: {
    learnMore: "Learn More",
  },
  progress: {
    edit: "Edit",
    stages: {
      location: "Location",
      guests: "Guests",
      services: "Services",
      dateTime: "Time",
      confirm: "Confirm",
    },
  },
  navigationBar: {
    next: "Next",
    back: "Back",
    reserve: "Reserve",
    confirm: "Confirm Booking",
    reschedule: "Confirm",
  },
  locations: {
    header: "Choose A Location To Book",
    warning:
      "Your appointment may not have been confirmed. Please check your emails for a confirmation message before attempting to book again. If you need help with your appointment, please {contactSupportLink}",
  },
  numberOfGuests: {
    header: "For How Many People?",
    one: "Just Me",
    many: "Two or More",
  },
  selectNumberOfGuests: {
    header: "How Many People Are Coming?",
    label1: "1",
    label2: "2",
    label3: "3",
    label4: "4",
    label5: "5",
    label6: "6",
    label7Plus: "7+ Guests",
    sevenPlus: {
      title: "Group Packages",
      body: "Looking to book for a group of 7 or more? Our events team would be happy to assist you. Hen parties, baby showers, milestone birthdays and more, Townhouse will have you celebrating in style with luxury treatments from the moment you step into our salons.",
      findOutMore: "Find Out More",
      groupPackagesPage: "https://townhouse.co.uk/group-packages/?utm_source=booking-flow",
    },
  },
  groupPackages: {
    header: "Choose Your Group Package for all Guests",
    individualServices: "Or choose services individually",
  },
  guests: {
    header: "Your Guests",
  },
  guestsMultipleServices: {
    noServices: "No services selected",
    addServices: "Add services",
    edit: "Edit",
    copy: "Copy to all guests",
  },
  guestsGroupPackageServices: {
    chooseAddOns: "Choose Add-Ons",
    edit: "Edit Add-Ons",
    copy: "Copy to all guests",
    includesRemoval: "(Includes gel removal)",
  },
  services: {
    header: "Choose Your Service",
    maniPedi: {
      explainer:
        "Treat yourself to the ultimate duo! Choose one manicure and one pedicure.\nWe’ll pamper you with both at the same time.",
      manicureHeader: "Mani-Pedi — Manicures",
      pedicureHeader: "Mani-Pedi — Pedicures",
    },
  },
  addOns: {
    headerManicure: "Select Any Add-Ons For Your Manicure (Optional)",
    headerPedicure: "Select Any Add-Ons For Your Pedicure (Optional)",
  },
  needsRemoval: {
    headerManicure: "Do You Need Removal For Your Manicure?",
    headerPedicure: "Do You Need Removal For Your Pedicure?",
  },
  notFound: {
    header: "Page Not Found",
    descriptionOne: "Please check the address you have entered is correct.",
    descriptionTwo: "Click the button below to go back to our salon locations page.",
    backButton: "Go Back To Our Locations",
  },
  removals: {
    headerManicure: "What Type Of Removal Do You Need?",
    headerPedicure: "What Type Of Removal Do You Need?",
  },
  rewards: {
    howItWorks: "How it works",
    descriptionOfPoints: "Earn 1 Townhouse Reward point for every £ you spend at Townhouse!",
    descriptionOfRedemption: "Redeem 500 points for £5 credit on any Townhouse product or service",
    balance: "Balance",
    currentBalance: "Current Balance",
    points: "{balance} points",
    redemptionValue: "Redemption value",
    howToRedeem: "How to redeem",
    redemptionInstructions: "Ask your nail artist to pay with reward points at the time of your appointment",
  },
  dateTime: {
    reservationExpired: {
      title: "Your reservation has expired",
      message: "Please choose your date and time and reserve again.",
      confirm: "OK",
    },
    nailArtist: {
      any: "With Any Nail Artist",
      chosen: "With {name}",
    },
    paymentDetailsError: "We couldn’t retrieve your payment details because something went wrong on our side.",
    reserveBookingError: "We couldn’t reserve your appointment because something went wrong on our side.",
    reserveBookingTimeNoLongerAvailableError:
      "We couldn’t reserve your appointment because the time you selected is no longer available. Choose a different time below.",
    availableDatesError: "We couldn’t display appointment dates and times because something went wrong on our side.",
    availableTimesError: "We couldn’t display appointment times because something went wrong on our side.",
    header: "Choose Your Appointment Time",
    allTimes: "All Times",
    showMoreTimes: "Show More Times",
    firstDateNotToday: "We're showing you times for {date} which is our next available day.",
    noTimesLeft: "There are no appointment times remaining on this day",
    timeFormat: "H:mm",
    fetchingAvailableTimes: "Finding available times...",
  },
  confirm: {
    header: "Confirm Your Appointment Details",
    expiresIn: "Your reservation will expire in",
    promo: {
      inputLabel: "Have a promo code?",
      apply: "apply",
      invalid: "Enter a valid code",
      error: "Failed to apply, try again",
    },
    price: {
      header: "Total",
      subtotalLabel: "Subtotal",
      discountLabel: "Discount",
      totalLabel: "Payable at location",
      error: "We couldn’t calculate the total price for your booking because something went wrong on our side.",
    },
    medicalInfo: "Add medical info / access needs",
    payment: {
      header: "Secure your booking with",
      label: "(Your card will not be charged until after your appointment)",
      addCard: "Add a new card",
      card: "Card ending in",
      change: "Change",
    },
    terms: {
      mainText:
        "Tick to confirm agreement with our {0} and to confirm you have added all details of relevant allergies or medical information. We require card details to hold your booking - you will not be charged. You are free to cancel/change your appointment up to 12 hours before with no charge. Cancellations/changes with less than 12 hours notice and no shows are charged in full using the card on file.",
      linkText: "Terms and Conditions",
    },
    confirmBooking: "Confirm booking",
    confirmBookingError: "We couldn’t confirm your appointment reservation because something went wrong on our side.",
  },
  complete: {
    header: "Your Reservation Is Confirmed",
    subHeader: "Thanks for booking with Townhouse!",
    total: {
      header: "Total",
      label: "Payable at location",
    },
    bookAgain: "Book Another Treatment",
    seeProducts: "See our Products",
  },
  createAccount: {
    header: "Create Account",
    submit: "Create Account",
    alreadyHaveAccount: "Already have an account? Log in",
    label: {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phone: "Phone",
      country: "Country",
      dateOfBirth: "Date of Birth",
      password: "Password",
      marketingOptIns: "Yes please — sign me up to exclusive offers, birthday treats and collaboration freebies!",
      marketingEmailOptIn: "Email",
      marketingSmsOptIn: "SMS",
    },
    placeholder: {
      password: "Try letters, numbers + symbols",
    },
    error: {
      all: "Failed to create an account. Please check that the email or phone number you used has not already been used previously and try again.",
      firstNameInvalid: "Please enter your first name",
      lastNameInvalid: "Please enter your last name",
      emailInvalid: "Please enter a valid email address",
      phoneInvalid: "Please enter a valid mobile phone number",
      dateOfBirthInvalid: "Please enter a valid day, month and year of birth",
      dateOfBirthUnder16: "You must be 16 or older to create an account",
      passwordInvalid:
        "Please enter a password of 8 characters or more with at least one lowercase letter, uppercase letter, number and symbol",
    },
  },
  login: {
    header: "Log In",
    submit: "Log In",
    forgottenPassword: "Forgot your password?",
    createAccount: "Create a new account",
    errored: "The email or password you entered was not correct. Please try again.",
    emailInvalid: "Please enter a valid email address",
    passwordInvalid: "Please enter a valid password",
  },
  resetPassword: {
    header: "Reset Password",
    email: {
      label: "Email",
      description: "Please enter your email address and we will send you a verification code.",
      invalid: "Please enter a valid email address",
    },
    verify: {
      label: "Verification Code",
      description: "We have sent a verification code to {email}, if an account exists with that email.",
      invalid: "Please enter a verification code",
    },
    password: {
      label: "New Password",
      invalid:
        "Please enter a password of 8 characters or more with at least one lowercase letter, uppercase letter, number and symbol",
    },
    success: {
      description: "Your password has been changed.",
    },
    button: {
      verificationCode: "Send Verification Code",
      updatePassword: "Update Password",
      login: "Go to login",
    },
    error: {
      all: "Failed to update password. Please check that the details you have entered are valid.",
      invalidVerificationCode:
        "Failed to update password. Please check that the verification code you have entered is correct.",
      accountLocked:
        "Failed to update password. Your account has been locked after too many failed attempts. Please try again later.",
    },
  },
  createPaymentAccount: {
    header: "Add A New Card",
    message: "Your card will be saved for future appointments.",
    error: {
      unableToStartUnknown: "Something went wrong trying to start adding a new payment card.",
      unableToStartRateLimited: "You've made too many changes to your payment cards in a short time.",
      addressOrNameInvalid: "The name or address does not match your payment card. Check the details you entered.",
      cardDetailsInvalid:
        "Your card details are incorrect, or your bank did not approve saving this card. Check the details you entered or contact your bank.",
      cardAlreadyAdded: "The card you entered is already added to your account. Please try entering a different card. ",
      unknown: "Something went wrong while adding your card.",
    },
  },
  input: {
    optionalLabel: "(Optional)",
  },
  button: {
    back: "Back",
  },
  calendar: {
    dayName: {
      sunday: "Sun",
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
    },
  },
  general: {
    townhouse: "Townhouse",
    mainWebsite: "https://townhouse.co.uk?utm_source=booking-flow",
    faq: "https://townhouse.co.uk/faq?utm_source=booking-flow",
    help: "https://townhouse.co.uk/faq?utm_source=booking-flow",
    termsAndConditions: "https://townhouse.co.uk/terms-and-conditions?utm_source=booking-flow",
    privacyPolicy: "https://townhouse.co.uk/privacy-policy?utm_source=booking-flow",
    products: "https://shop.townhouse.co.uk?utm_source=booking-flow",
    guestLabel: "Guest {guestNumber}",
    button: {
      tryAgain: "Try Again",
    },
    pageError: {
      error: "We couldn’t display this page because something went wrong on our side.",
      tryAgainBelow: "Press the button below to try again.",
      tryAgainLater: "Try again later.",
      // '@' is a reserved character in i18n, so we use literal interpolation
      // See https://vue-i18n.intlify.dev/guide/essentials/syntax#special-characters
      contactSupportMessage: "If the problem continues, ",
      contactSupportLinkText: "contact us.",
      contactSupportLink: "https://townhouse.co.uk/contact-us?utm_source=booking-flow",
      errorCode: "The error was code '{browserId}'.",
    },
  },
};
