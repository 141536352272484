<script setup lang="ts">
import ButtonPrimary from "@/src/components/ButtonPrimary.vue";
import TextBody1 from "@/src/components/TextBody1.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextBodyError from "@/src/components/TextBodyError.vue";
import TextLink from "@/src/components/TextLink.vue";
import { useAuthStore } from "@/src/stores/authStore";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

defineProps<{
  errorMessage: string;
  tryAgainHandler?: () => void;
  hideBorder?: boolean;
  hideCustomerCareMessage?: boolean;
}>();

const authStore = useAuthStore();
const { browserId } = storeToRefs(authStore);
</script>

<template>
  <div class="flex flex-col gap-5 p-10" :class="{ 'md:border': !hideBorder, 'md:border-error': !hideBorder, 'md:m-5': !hideBorder }">
    <TextBodyError>
      <TextBody1>
        {{ errorMessage }}
        {{ hideCustomerCareMessage ? '' : tryAgainHandler ? t('general.pageError.tryAgainBelow') : t('general.pageError.tryAgainLater') }}
      </TextBody1>
    </TextBodyError>
    <div v-if="!hideCustomerCareMessage" class="flex flex-col gap-5">
      <ButtonPrimary v-if="tryAgainHandler" @click.once="tryAgainHandler">{{ t("general.button.tryAgain") }}</ButtonPrimary>
      <TextBodyError>
        <TextBody3>
          {{ t('general.pageError.contactSupportMessage') }}
          <TextLink>
            <a :href="t('general.pageError.contactSupportLink')" target="_blank">{{ t('general.pageError.contactSupportLinkText') }}</a>
          </TextLink>
          {{ t('general.pageError.errorCode', { browserId }) }}
        </TextBody3>
      </TextBodyError>
    </div>
  </div>
</template>
