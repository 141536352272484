import type { BookingId, LocalIsoDate } from "@/src/lib/townhouseApiClient";
import { useAuthStore } from "@/src/stores/authStore";
import type { DateTime } from "luxon";
import { defineStore } from "pinia";

export type BookingRescheduleState = {
  availableDateTimesUtc: DateTime[] | null;
  bookingId: BookingId | null;
  rescheduledDateLocal: LocalIsoDate | null;
  rescheduledDateTimeUtc: DateTime | null;
  termsAndConditionsAccepted: boolean;
  isRescheduling: boolean;
  confirmBookingRescheduleFailure: Omit<Error, "cause"> | null;
  fetchAvailableTimesFailure: Omit<Error, "cause"> | null;
};

export const isBookingRescheduleState = (state: unknown): state is BookingRescheduleState => {
  return typeof state === "object" && state !== null && "rescheduledDateLocal" in state;
};

export class BookingRescheduleStoreIncompleteError extends Error {}
export class BookingRescheduleStoreInvalidBookingDateTimeError extends Error {}

export const useBookingRescheduleStore = defineStore("bookingReschedule", {
  state: (): BookingRescheduleState => {
    return {
      availableDateTimesUtc: null,
      bookingId: null,
      rescheduledDateLocal: null,
      rescheduledDateTimeUtc: null,
      termsAndConditionsAccepted: false,
      isRescheduling: false,
      confirmBookingRescheduleFailure: null,
      fetchAvailableTimesFailure: null,
    };
  },
  actions: {
    setBookingId(bookingId: BookingId) {
      this.$patch({
        bookingId,
        rescheduledDateLocal: null,
        rescheduledDateTimeUtc: null,
        availableDateTimesUtc: null,
      });
    },

    setRescheduledDateLocal(rescheduledDateLocal: LocalIsoDate) {
      if (!this.bookingId) {
        throw new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling process as booking to reschedule is not selected",
        );
      }

      this.$patch({
        rescheduledDateLocal,
        rescheduledDateTimeUtc: null,
        availableDateTimesUtc: null,
      });

      const authStore = useAuthStore();
      authStore.analytics.track("Booking Reschedule Date Chosen", {
        bookingId: this.bookingId,
        dateUtc: rescheduledDateLocal,
      });
    },

    async fetchAvailableTimes(): Promise<void> {
      this.$patch({
        fetchAvailableTimesFailure: null,
      });

      const authStore = useAuthStore();

      if (!authStore.userId) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling process as user is not authenticated",
        );

        this.$patch({
          fetchAvailableTimesFailure: error,
        });

        throw error;
      }

      if (this.availableDateTimesUtc && this.availableDateTimesUtc.length > 0) {
        return;
      }

      if (!this.bookingId) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling process as booking to reschedule is not selected",
        );

        this.$patch({
          fetchAvailableTimesFailure: error,
        });

        throw error;
      }

      if (!this.rescheduledDateLocal) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling process as rescheduled booking date is not selected",
        );

        this.$patch({
          fetchAvailableTimesFailure: error,
        });

        throw error;
      }

      try {
        const { availableDateTimesUtc } = await authStore.townhouseApiClient.bookingCreateReschedule({
          userId: authStore.userId,
          bookingId: this.bookingId,
          rescheduledDateLocal: this.rescheduledDateLocal,
        });

        this.$patch({
          availableDateTimesUtc,
          rescheduledDateTimeUtc: null,
        });

        if (availableDateTimesUtc.length > 0) {
          authStore.analytics.track("Booking Reschedule Times Shown", {
            bookingId: this.bookingId,
            dateUtc: this.rescheduledDateLocal.toString(),
          });
        } else {
          authStore.analytics.track("Booking Reschedule No Times Available", {
            bookingId: this.bookingId,
            dateUtc: this.rescheduledDateLocal.toString(),
          });
        }
      } catch (e) {
        this.$patch({
          fetchAvailableTimesFailure: e instanceof Error ? e : null,
        });

        throw e;
      }
    },

    async refetchAvailableTimes(): Promise<void> {
      this.$patch({
        availableDateTimesUtc: null,
        rescheduledDateTimeUtc: null,
      });

      await this.fetchAvailableTimes();
    },

    setRescheduledDateTimeUtc(rescheduledDateTimeUtc: DateTime) {
      if (!this.bookingId) {
        throw new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling process as booking to reschedule is not selected",
        );
      }

      if (!this.availableDateTimesUtc?.includes(rescheduledDateTimeUtc)) {
        throw new BookingRescheduleStoreInvalidBookingDateTimeError(
          "Unable to set rescheduled booking date / time to any value that wasn't in the available times",
        );
      }

      this.$patch({
        rescheduledDateTimeUtc,
      });

      const authStore = useAuthStore();
      authStore.analytics.track("Booking Reschedule Time Chosen", {
        bookingId: this.bookingId,
        dateTimeUtc: rescheduledDateTimeUtc.toString(),
      });
    },

    async confirmBookingReschedule(): Promise<void> {
      this.$patch({
        isRescheduling: true,
        confirmBookingRescheduleFailure: null,
      });

      const authStore = useAuthStore();

      if (!authStore.userId) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling confirmation process as user is not authenticated",
        );

        this.$patch({
          isRescheduling: false,
          confirmBookingRescheduleFailure: error,
        });

        throw error;
      }

      if (!this.bookingId) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling confirmation process as booking to reschedule is not selected",
        );

        this.$patch({
          isRescheduling: false,
          confirmBookingRescheduleFailure: error,
        });

        throw error;
      }

      if (!this.rescheduledDateTimeUtc) {
        const error = new BookingRescheduleStoreIncompleteError(
          "Unable to start booking rescheduling confirmation process as rescheduled booking date / time is not selected",
        );

        this.$patch({
          isRescheduling: false,
          confirmBookingRescheduleFailure: error,
        });

        throw error;
      }

      try {
        await authStore.townhouseApiClient.bookingCreateConfirmReschedule({
          userId: authStore.userId,
          bookingId: this.bookingId,
          rescheduledDateTimeUtc: this.rescheduledDateTimeUtc,
        });

        authStore.analytics.track("Booking Rescheduled Successfully", {
          bookingId: this.bookingId,
          dateTimeUtc: this.rescheduledDateTimeUtc.toString(),
        });
      } catch (e) {
        this.$patch({
          isRescheduling: false,
          confirmBookingRescheduleFailure: e instanceof Error ? e : null,
        });

        authStore.analytics.track("Booking Reschedule Failed", {
          bookingId: this.bookingId,
          dateTimeUtc: this.rescheduledDateTimeUtc.toString(),
        });

        throw e;
      }

      this.$reset();
    },
  },
});
