<script setup lang="ts">
import Icon from "@/src/components/Icon.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import type { ButtonHTMLAttributes } from "vue";

const { buttonType = "button", disabled } = defineProps<{
  disabled?: boolean;
  submitting?: boolean;
  buttonType?: ButtonHTMLAttributes["type"];
}>();

const emits = defineEmits<{
  click: [];
}>();

const handleClick = () => {
  if (!disabled) {
    emits("click");
  }
};
</script>

<template>
  <button
  :class="`relative block w-full p-5 ${disabled ? 'bg-button-disabled cursor-not-allowed' : 'bg-button-primary cursor-pointer hover:bg-button-primary-hover'}`"
  @click.stop="handleClick"
  role="button"
  :disabled="disabled"
  :type="buttonType"
  >
    <TextHeader2 :class="`${disabled ? 'text-button-text-disabled' : ''}`">
      <slot />
    </TextHeader2>
    <div v-if="submitting" class="absolute flex items-center justify-center inset-0 w-full bg-inherit">
        <Icon :icon="faCircleNotch" size="xl" class="animate-spin"/>
    </div>
  </button>
</template>
