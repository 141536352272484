<script setup lang="ts">
import ButtonToggleGroup from "@/src/components/ButtonToggleGroup.vue";
import ButtonToggleGroupSkeleton from "@/src/components/ButtonToggleGroupSkeleton.vue";
import Icon from "@/src/components/Icon.vue";
import LocationBanner from "@/src/components/LocationBanner.vue";
import SectionError from "@/src/components/SectionError.vue";
import ServiceCard from "@/src/components/ServiceCard.vue";
import ServiceCardSkeleton from "@/src/components/ServiceCardSkeleton.vue";
import TextBody3 from "@/src/components/TextBody3.vue";
import TextHeader1 from "@/src/components/TextHeader1.vue";
import TextHeader2 from "@/src/components/TextHeader2.vue";
import type { LocationId, ServiceId, ServicesGetByLocationResponseItem } from "@/src/lib/townhouseApiClient";
import PopupServiceInfo from "@/src/popups/PopupServiceInfo.vue";
import { type GuestNumber, useBookingStore } from "@/src/stores/bookingStore";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";

type DisplayServiceType = "manicure" | "pedicure" | "maniPedi";

const { t } = useI18n();
const bookingStore = useBookingStore();

const props = defineProps<{
  locationId: LocationId;
  guestNumber: GuestNumber;
  press: boolean | undefined;
}>();
const { servicesByLocation, servicesByGuest, fetchServicesFailure } = storeToRefs(bookingStore);
const serviceForPopupServiceInfo = ref<Pick<
  ServicesGetByLocationResponseItem,
  "name" | "longDescription" | "priceCents" | "typicalDurationMins"
> | null>(null);

const getSelectedDisplayServiceType = (): DisplayServiceType => {
  const firstChosenServiceId = servicesByGuest.value[props.guestNumber - 1]?.keys().next().value;
  const firstChosenService = firstChosenServiceId ? servicesByLocation.value?.get(firstChosenServiceId) : null;

  switch (firstChosenService?.serviceType) {
    case "manicure":
      return "manicure";
    case "pedicure":
      return "pedicure";
    case "maniPediManicure":
      return "maniPedi";
    case "maniPediPedicure":
      return "maniPedi";
    default:
      return "manicure";
  }
};

const selectedDisplayServiceType = ref<DisplayServiceType>(getSelectedDisplayServiceType());

const services = computed(() => {
  if (selectedDisplayServiceType.value === "maniPedi") {
    return null;
  }

  return bookingStore.servicesByLocationGroupedByType[selectedDisplayServiceType.value];
});

const maniPediManicureServices = computed(() => {
  return bookingStore.servicesByLocationGroupedByType["maniPediManicure"];
});
const maniPediPedicureServices = computed(() => {
  return bookingStore.servicesByLocationGroupedByType["maniPediPedicure"];
});

const handleDisplayServiceTypeChange = (newValue: string | null | undefined) => {
  if (newValue) {
    selectedDisplayServiceType.value = newValue as DisplayServiceType;
  }
};

const handleServiceToggled = (serviceId: ServiceId, newValue: boolean | undefined) => {
  if (newValue === undefined) {
    return;
  }

  selectedManiPediManicure.value = null;
  selectedManiPediPedicure.value = null;
  bookingStore.removeAllServicesFromGuest(props.guestNumber);

  if (!newValue) {
    return;
  }

  bookingStore.addServiceToGuest(props.guestNumber, serviceId);
};

const selectedManiPediManicure = ref<ServiceId | null>(null);
const selectedManiPediPedicure = ref<ServiceId | null>(null);

const handleManiPediManicureServiceToggled = (serviceId: ServiceId, newValue: boolean | undefined) => {
  if (newValue === undefined) {
    return;
  }

  bookingStore.removeAllServicesFromGuest(props.guestNumber);

  if (!newValue) {
    selectedManiPediManicure.value = null;
    return;
  }

  selectedManiPediManicure.value = serviceId;
  if (selectedManiPediPedicure.value) {
    bookingStore.addParallelServicesToGuest(props.guestNumber, serviceId, selectedManiPediPedicure.value);
  }
};

const handleManiPediPedicureServiceToggled = (serviceId: ServiceId, newValue: boolean | undefined) => {
  if (newValue === undefined) {
    return;
  }

  bookingStore.removeAllServicesFromGuest(props.guestNumber);

  if (!newValue) {
    selectedManiPediPedicure.value = null;
    return;
  }

  selectedManiPediPedicure.value = serviceId;
  if (selectedManiPediManicure.value) {
    bookingStore.addParallelServicesToGuest(props.guestNumber, selectedManiPediManicure.value, serviceId);
  }
};

const guestHasService = (serviceId: ServiceId): boolean => {
  return Boolean(servicesByGuest.value[props.guestNumber - 1]?.has(serviceId));
};

const handleLearnMoreClicked = (service: ServicesGetByLocationResponseItem) => {
  serviceForPopupServiceInfo.value = {
    name: service.name,
    longDescription: service.longDescription,
    priceCents: service.priceCents,
    typicalDurationMins: service.typicalDurationMins,
  };
};

const handleTryAgain = () => {
  window.location.reload();
};

// This whole section of code is for hiding and showing the little "jump to mani-pedi pedicures" banner that shows up
// fixed at the bottom.
//
// It works by using an intersection observer to be notified when the pedicure list is visible on the screen; the banner
// is hidden when the list is visible, and shown when the list is not visible.

const maniPediPedicureSection = useTemplateRef("maniPediPedicureSection");
const showJumpToManiPediPedicure = ref<boolean>(false);

const handleClickJumpToManiPediPedicure = () => {
  maniPediPedicureSection.value?.scrollIntoView({ behavior: "smooth" });
};

const onIntersect = (entries: IntersectionObserverEntry[]) => {
  // Check if the target element is intersecting (i.e., visible)
  showJumpToManiPediPedicure.value = !entries[0].isIntersecting;
};

let observer: IntersectionObserver | null;

onMounted(() => {
  // The -123px is calculated as (height of anchor link + height of nav var * -1).
  observer = new IntersectionObserver(onIntersect, { rootMargin: "0px 0px -123px 0px" });
  if (maniPediPedicureSection.value) {
    observer.observe(maniPediPedicureSection.value);
  }
});

watch(maniPediPedicureSection, (newManiPediPedicureSection) => {
  if (!observer) {
    return;
  }

  if (newManiPediPedicureSection) {
    observer.observe(newManiPediPedicureSection);
  }
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});
</script>

<template>
  <LocationBanner />
  <h2 class="flex flex-col justify-center my-5">
    <TextHeader1 v-if="bookingStore.hasMultipleGuests" class="text-center">{{ t('general.guestLabel', { guestNumber }) }}: </TextHeader1>
    <TextHeader1 class="text-center">{{ t('services.header') }}</TextHeader1>
  </h2>
  <div v-if="servicesByLocation !== null">
    <PopupServiceInfo v-if="serviceForPopupServiceInfo" @cancelled="serviceForPopupServiceInfo = null" :serviceName="serviceForPopupServiceInfo.name" :description="serviceForPopupServiceInfo.longDescription" :priceCents="serviceForPopupServiceInfo.priceCents" :durationMins="serviceForPopupServiceInfo.typicalDurationMins" />
    <ButtonToggleGroup class="mb-5" :labelsAndValues="[
      { label: 'Mani', value: 'manicure' },
      { label: 'Pedi', value: 'pedicure' },
      { label: 'Mani-Pedi', value: 'maniPedi' }
    ]" :modelValue="selectedDisplayServiceType" @update:modelValue="handleDisplayServiceTypeChange">
    </ButtonToggleGroup>
    <div v-if="selectedDisplayServiceType !== 'maniPedi'" class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
      <div v-for="service in services" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCard @learnMore="() => handleLearnMoreClicked(service)" :modelValue="guestHasService(service.id)"
          @update:modelValue="(newValue: boolean | undefined) => handleServiceToggled(service.id, newValue)"
          :header="service.name"
          :bodyText="service.shortDescription"
          :duration="service.typicalDurationMins"
          :priceCents="service.priceCents" />
      </div>
    </div>
    <div v-else class="relative flex flex-col gap-5">
      <div class="flex justify-center text-center px-5">
        <TextBody3 class="whitespace-pre-line sm:whitespace-normal">{{ t('services.maniPedi.explainer') }}</TextBody3>
      </div>
      <div class="flex justify-center mt-10">
        <TextHeader2>{{ t('services.maniPedi.manicureHeader') }}</TextHeader2>
      </div>
      <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
        <div v-for="service in maniPediManicureServices" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
          <ServiceCard @learnMore="() => handleLearnMoreClicked(service)" :modelValue="guestHasService(service.id) || selectedManiPediManicure === service.id"
            @update:modelValue="(newValue: boolean | undefined) => handleManiPediManicureServiceToggled(service.id, newValue)"
            :header="service.name"
            :bodyText="service.shortDescription"
            :duration="service.typicalDurationMins"
            :priceCents="service.priceCents" />
        </div>
      </div>
      <div ref="maniPediPedicureSection" class="flex flex-col gap-5 mt-10">
        <div class="flex justify-center">
          <TextHeader2>{{ t('services.maniPedi.pedicureHeader') }}</TextHeader2>
        </div>
        <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
          <div v-for="service in maniPediPedicureServices" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
            <ServiceCard @learnMore="() => handleLearnMoreClicked(service)" :modelValue="guestHasService(service.id) || selectedManiPediPedicure === service.id"
              @update:modelValue="(newValue: boolean | undefined) => handleManiPediPedicureServiceToggled(service.id, newValue)"
              :header="service.name"
              :bodyText="service.shortDescription"
              :duration="service.typicalDurationMins"
              :priceCents="service.priceCents" />
          </div>
        </div>
      </div>
      <div v-if="showJumpToManiPediPedicure" class="fixed left-0 bottom-[74px] bg-white w-full p-5 flex justify-center items-center gap-5 cursor-pointer" @click="handleClickJumpToManiPediPedicure">
        <TextHeader2>{{ t('services.maniPedi.pedicureHeader') }}</TextHeader2>
        <Icon :icon="faAngleDown" size="2x" />
      </div>
    </div>
  </div>
  <div v-else-if="fetchServicesFailure" class="w-full flex justify-center">
    <div class="max-w-[600px]">
      <SectionError :error-message="t('general.pageError.error')" :try-again-handler="handleTryAgain" />
    </div>
  </div>
  <div v-else>
    <ButtonToggleGroupSkeleton :button-count="3" class="mb-5"/>
    <div class="flex flex-col md:flex-row md:flex-wrap justify-between gap-5 px-5">
      <div v-for="_ in _.times(10)" class="w-full md:w-[calc(50%-0.625rem)] shrink-0 grow-0">
        <ServiceCardSkeleton />
      </div>
    </div>
  </div>
</template>
